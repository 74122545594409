import { z } from "zod";

export const fulfillmentOrderSchema = {
  getFulfillmentOrdersByOrder: {
    input: z.object({
      orderId: z.string(),
    }),
    output: z.any(),
  },
};
