import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { TimelineEventZodSchema } from "./timeline";

//Because of circular dependencies I had to move it to its own file
export const ITrackableZodSchema = z.object({
  _id: zExt.objectId(),
  timeline: z.array(TimelineEventZodSchema),
  trackers: z
    .array(
      z.object({
        _tracker: z.any(),
        fulfillmentID: z.string(),
      }),
    )
    .optional(),
  trackingTimeline: z.array(z.any()),
  trackingAnalytics: z.object({
    email: z.array(z.any()), //TODO: type this
    page: z.array(
      z.object({
        url: z.string(),
        eventType: z.enum(["ad", "upsell"]),
        image: z.string().nullish(),
        createdAt: z.date(),
      }),
    ),
  }),
  trackingTextsSent: z
    .array(
      z.object({
        sid: z.string(),
        mms: z.boolean(),
        sentAt: z.date(),
      }),
    )
    .nullish(),
  trackingEmailsSent: z
    .array(
      z.object({
        emailId: z.string(),
        status: z.string().optional(),
        sentAt: z.date(),
        s3URL: z.string().optional(),
        trigger: z.string().optional(),
      }),
    )
    .optional(),
  trackingBillingStatus: z.enum(["billed", "free"]).nullish(),
  discount: z
    .object({
      id: z.string().nullish(),
      description: z.string().nullish(),
      code: z.string().nullish(),
      expirationDateTime: z.string().nullish(),
    })
    .optional(),
});
