export type ArrayMerge<A extends unknown[]> = UnionToIntersection<A[number]>;

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type NullishPartial<T> = {
  [P in keyof T]?: T[P] | null;
};

export type Optional<T> = T | undefined | null;

export type UnionToIntersection<U> = (
  U extends any ? (k: U) => never : never
) extends (k: infer I) => never
  ? I
  : never;

export function isString(v: unknown): v is string {
  return typeof v === "string";
}

export function isDefined<T>(v: T | undefined | null): v is T {
  return v !== undefined && v !== null;
}

export function assertNever(value: never, errorMessage?: string): never {
  throw new Error(errorMessage ?? `Unexpected value: ${value}`);
}

export function isObject(v: unknown): v is Record<string, unknown> {
  return typeof v === "object" && v !== null;
}

export type Tuple<T, TLength extends number> = [T, ...T[]] & {
  length: TLength;
};

/**
 * Lets the compiler know that the value is unused but we are ok with that.
 */
export function sinkValue<T>(value: T): void {
  // noop
}

type BuildRangeTuple<
  Current extends [...number[]],
  Count extends number,
> = Current["length"] extends Count
  ? Current
  : BuildRangeTuple<[number, ...Current], Count>;

type RangeTuple<Count extends number> = BuildRangeTuple<[], Count>;

type BuildRange<
  Current extends number,
  End extends number,
  Accu extends [...number[]],
> = Accu["length"] extends End
  ? Current
  : BuildRange<Current | Accu["length"], End, [number, ...Accu]>;

export type NumberRange<
  StartInclusive extends number,
  EndExclusive extends number,
> = BuildRange<StartInclusive, EndExclusive, RangeTuple<StartInclusive>>;

/**
 * Manifest, for covariant type
 */
export class Type<T> {
  _type!: T;

  wrap(value: T): any {
    return new TypedValue(this, value);
  }

  unwrap(typedValue: any): T {
    if (!(typedValue instanceof TypedValue)) {
      throw new Error("Expected typed value");
    }
    if (typedValue.type !== this) {
      throw new Error("Type mismatch");
    }
    return typedValue.value;
  }
}

export class TypedValue<T> {
  constructor(
    readonly type: Type<T>,
    readonly value: T,
  ) {}
}
