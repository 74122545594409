export interface Bijection<A, B> {
  read(value: A): B;
  write(value: B): A;
}

export function bijectionCompose<A, B, C>(
  first: Bijection<A, B>,
  second: Bijection<B, C>,
): Bijection<A, C> {
  return {
    read(a) {
      return second.read(first.read(a));
    },
    write(c) {
      return first.write(second.write(c));
    },
  };
}

export function bijectionRef<A, B>(
  ref: () => Bijection<A, B>,
): Bijection<A, B> {
  return {
    read(a) {
      return ref().read(a);
    },
    write(b) {
      return ref().write(b);
    },
  };
}

export function bijectionInvert<A, B>(
  bijection: Bijection<A, B>,
): Bijection<B, A> {
  return {
    read(a) {
      return bijection.write(a);
    },
    write(b) {
      return bijection.read(b);
    },
  };
}
