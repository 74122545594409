import { z } from "zod";

export type Enableable<T> = {
  enabled: boolean;
} & T;

const hexCodeValidator = z.string();
export type HexCode = `${string}`;

export const fontWeights = ["regular", "semibold", "bold"] as const;
export type FontWeight = (typeof fontWeights)[number];

const fontStyleDefinitionValidator = z.object({
  fontSizePx: z.number(),
  fontWeight: z.enum(fontWeights),
});
export interface FontStyleDefinition {
  fontSizePx: number;
  fontWeight: FontWeight;
}

export const buttonHierarchies = ["primary", "secondary", "tertiary"] as const;
export type ButtonHierarchy = (typeof buttonHierarchies)[number];

const borderStrokeStyleDefinitionValidator = z.object({
  color: hexCodeValidator,
  weightPx: z.number(),
});
export interface BorderStrokeStyleDefinition {
  color: HexCode;
  weightPx: number;
}

const borderStyleDefinitionValidator = z.object({
  cornerRadiusPx: z.number(),
  stroke: borderStrokeStyleDefinitionValidator,
});
export interface BorderStyleDefinition {
  cornerRadiusPx: number;
  stroke: BorderStrokeStyleDefinition;
}

export const fontHierarchyKeys = ["h1", "h2", "h3", "body", "subtext"] as const;
export type FontHierarchyKey = (typeof fontHierarchyKeys)[number];

const elementStyleDefinitionValidator = z.object({
  backgroundColor: hexCodeValidator.optional(),
  textColor: hexCodeValidator,
  border: borderStyleDefinitionValidator,
  paddingPx: z.number(),
  fontReference: z.enum(fontHierarchyKeys),
});
interface ElementStyleDefinition {
  backgroundColor?: HexCode;
  textColor: HexCode;
  border: BorderStyleDefinition;
  paddingPx: number;
  fontReference: keyof BrandKit["font"]["hierarchy"];
}
const buttonStyleDefinitionValidator = elementStyleDefinitionValidator;
export interface ButtonStyleDefinition extends ElementStyleDefinition {}

const inputStyleDefinitionValidator = elementStyleDefinitionValidator.extend({
  errorColor: hexCodeValidator,
});

export interface InputStyleDefinition extends ElementStyleDefinition {
  errorColor: HexCode;
}

const buttonOptionsValidator = z.object({
  primary: buttonStyleDefinitionValidator,
  secondary: buttonStyleDefinitionValidator,
  tertiary: buttonStyleDefinitionValidator,
});
export interface ButtonOptions {
  primary: ButtonStyleDefinition;
  secondary: ButtonStyleDefinition;
  tertiary: ButtonStyleDefinition;
}

export type Override<T extends ElementStyleDefinition> = Omit<
  T,
  "fontReference"
> & {
  font: FontStyleDefinition & { fontFamily: FontFamily };
};

export type ButtonOptionsOverride = {
  [K in keyof ButtonOptions]: Override<ButtonStyleDefinition>;
};

// TODO: custom fonts (email fonts should be unrelated, since they have unique constraints)
import { FontFamily } from "./email-builder";
export { FontFamily };

export const brandKitValidator = z.object({
  colors: z.object({
    background: z.string(),
    accent: z.string(),
  }),
  font: z.object({
    fontFamily: z.nativeEnum(FontFamily),
    hierarchy: z.object({
      h1: fontStyleDefinitionValidator,
      h2: fontStyleDefinitionValidator,
      h3: fontStyleDefinitionValidator,
      body: fontStyleDefinitionValidator,
      subtext: fontStyleDefinitionValidator,
    }),
  }),
  inputs: inputStyleDefinitionValidator,
  buttons: buttonOptionsValidator,
  images: z.object({
    logoUrl: z.string(),
    faviconUrl: z.string(),
    bannerUrl: z.string(),
    logoBannerUrl: z.string(),
  }),
});

export interface BrandKit {
  colors: {
    background: HexCode;
    accent: HexCode;
  };
  font: {
    fontFamily: FontFamily;
    hierarchy: Record<FontHierarchyKey, FontStyleDefinition>;
  };
  inputs: InputStyleDefinition;
  buttons: ButtonOptions;
  /**
   * Instead of optional fields, empty strings are used to indicate no image.
   * (Makes it easier to work with the Inspector component)
   */
  images: {
    logoUrl: string;
    faviconUrl: string;
    bannerUrl: string;
    logoBannerUrl: string;
  };
}

export const defaultTertiaryButton: ButtonStyleDefinition = {
  backgroundColor: undefined,
  textColor: "#000000",
  border: {
    cornerRadiusPx: 8,
    stroke: { color: "#000000", weightPx: 0 },
  },
  paddingPx: 8,
  fontReference: "body",
};

export const defaultBrandKit: BrandKit = {
  colors: {
    background: "#ffffff",
    accent: "#000000",
  },
  font: {
    fontFamily: "Arial",
    hierarchy: {
      h1: { fontSizePx: 48, fontWeight: "bold" },
      h2: { fontSizePx: 36, fontWeight: "semibold" },
      h3: { fontSizePx: 30, fontWeight: "regular" },
      body: { fontSizePx: 16, fontWeight: "regular" },
      subtext: { fontSizePx: 14, fontWeight: "regular" },
    },
  },
  inputs: {
    backgroundColor: "#ffffff",
    errorColor: "#ff0000",
    textColor: "#000000",
    border: {
      cornerRadiusPx: 8,
      stroke: { color: "#000000", weightPx: 1 },
    },
    paddingPx: 8,
    fontReference: "body",
  },
  buttons: {
    primary: {
      backgroundColor: "#000000",
      textColor: "#ffffff",
      border: {
        cornerRadiusPx: 8,
        stroke: { color: "#000000", weightPx: 1 },
      },
      paddingPx: 8,
      fontReference: "body",
    },
    secondary: {
      backgroundColor: "#ffffff",
      textColor: "#000000",
      border: {
        cornerRadiusPx: 8,
        stroke: { color: "#000000", weightPx: 1 },
      },
      paddingPx: 8,
      fontReference: "body",
    },
    tertiary: defaultTertiaryButton,
  },
  images: {
    logoUrl: "",
    faviconUrl: "",
    bannerUrl: "",
    logoBannerUrl: "",
  },
};
