// extracted by mini-css-extract-plugin
export var centered = "_redotech-redo-web-src-modal-centered";
export var flexible = "_redotech-redo-web-src-modal-flexible";
export var footer = "_redotech-redo-web-src-modal-footer";
export var footerBorder = "_redotech-redo-web-src-modal-footer-border";
export var header = "_redotech-redo-web-src-modal-header";
export var headerBorder = "_redotech-redo-web-src-modal-header-border";
export var headerDefault = "_redotech-redo-web-src-modal-header-default";
export var headerMedium = "_redotech-redo-web-src-modal-header-medium";
export var headerPadding = "_redotech-redo-web-src-modal-header-padding";
export var iconAndTitle = "_redotech-redo-web-src-modal-icon-and-title";
export var large = "_redotech-redo-web-src-modal-large";
export var largePadding = "_redotech-redo-web-src-modal-largePadding";
export var medium = "_redotech-redo-web-src-modal-medium";
export var mediumPadding = "_redotech-redo-web-src-modal-mediumPadding";
export var modal = "_redotech-redo-web-src-modal-modal";
export var modalContainer = "_redotech-redo-web-src-modal-modal-container";
export var noPadding = "_redotech-redo-web-src-modal-no-padding";
export var skinny = "_redotech-redo-web-src-modal-skinny";
export var small = "_redotech-redo-web-src-modal-small";
export var smallPadding = "_redotech-redo-web-src-modal-smallPadding";
export var subtitle = "_redotech-redo-web-src-modal-subtitle";
export var title = "_redotech-redo-web-src-modal-title";