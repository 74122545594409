/**
 * @file
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-11 RFC 9110 11 HTTP Authentication}
 */

import {
  FieldName,
  FieldType,
  FieldValueFormat,
  listFieldFormat,
  singleFieldFormat,
  stringFieldValueFormat,
} from "./fields";
import {
  Token,
  optionalWhitespaceRead,
  quotableRead,
  quotableWrite,
  tokenRead,
  tokenWrite,
} from "./syntax";

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-11.4 RFC 9110 Section 11.4 Credentials}
 */
export interface Credentials {
  scheme: string;
  info: string | Map<string, string> | undefined;
}

export const credentialsFieldValueFormat: FieldValueFormat<Credentials> = {
  read(fieldValue: string) {
    const result = credentialsRead(fieldValue, 0);
    if (result.end !== fieldValue.length) {
      throw new Error(
        `Unexpected character at ${result.end}: ${fieldValue[result.end]}`,
      );
    }
    return result.value;
  },
  write(fieldValue: Credentials) {
    return credentialsWrite(fieldValue);
  },
};

/**
 * Authentication-Info
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-11.6.3 RFC 9110 11.6.3 Authentication-Info}
 */
export const AuthenticationInfoField = new FieldType(
  new FieldName("Authentication-Info"),
  listFieldFormat(stringFieldValueFormat),
);

/**
 * Authorization
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-11.6.2 RFC 9110 11.6.2 Authorization}
 */
export const AuthorizationField = new FieldType(
  new FieldName("Authorization"),
  singleFieldFormat(credentialsFieldValueFormat),
);

export const LoopApiAuthorizationField = new FieldType(
  new FieldName("X-Authorization"),
  singleFieldFormat(stringFieldValueFormat),
);

export const LoopAuthorizationField = new FieldType(
  new FieldName("X-Loop-Signature"),
  singleFieldFormat(stringFieldValueFormat),
);

/**
 * Proxy-Authenticate
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-11.7.1 RFC 9110 11.7.1 Proxy-Authenticate}
 */
export const ProxyAuthenticateField = new FieldType(
  new FieldName("Proxy-Authenticate"),
  listFieldFormat(stringFieldValueFormat),
);

/**
 * Proxy-Authentication-Info
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-11.7.3 RFC 9110 11.7.3 Proxy-Authentication-Info}
 */
export const ProxyAuthenticationInfoField = new FieldType(
  new FieldName("Proxy-Authentication-Info"),
  listFieldFormat(stringFieldValueFormat),
);

/**
 * Proxy-Authorization
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-11.7.2 RFC 9110 11.7.2 Proxy-Authorization}
 */
export const ProxyAuthorizationField = new FieldType(
  new FieldName("Proxy-Authorization"),
  singleFieldFormat(credentialsFieldValueFormat),
);

/**
 * WWW-Authenticate
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-11.6.1 RFC 9110 11.6.1 WWW-Authenticate}
 */
export const WwwAuthenticateField = new FieldType(
  new FieldName("WWW-Authenticate"),
  listFieldFormat(stringFieldValueFormat),
);

interface AuthParam {
  key: string;
  value: string;
}

function authParamRead(
  string: string,
  start: number,
): { end: number; value: AuthParam } {
  const tokenResult = tokenRead(string, start);
  start = tokenResult.end;
  start = optionalWhitespaceRead(string, start);
  if (string[start] !== "=") {
    throw new Error(`Expected = at ${start}`);
  }
  start++;
  start = optionalWhitespaceRead(string, start);
  const valueResult = quotableRead(string, start);
  start = valueResult.end;
  return {
    end: start,
    value: { key: tokenResult.value.toLowerCase(), value: valueResult.value },
  };
}

function authParamWrite(param: AuthParam) {
  return `${tokenWrite(param.key)}=${quotableWrite(param.value)}`;
}

function credentialsRead(
  string: string,
  start: number,
): { end: number; value: Credentials } {
  const schemeResult = tokenRead(string, start);
  start = schemeResult.end;
  if (string[start] !== " ") {
    return {
      end: start,
      value: { scheme: schemeResult.value, info: undefined },
    };
  }
  start++;
  const tokenResult = token68Read(string, start);
  if (tokenResult.end === string.length) {
    start = tokenResult.end;
    return {
      end: start,
      value: { scheme: schemeResult.value, info: tokenResult.value },
    };
  }
  const params = new Map<string, string>();
  while (true) {
    const paramResult = authParamRead(string, start);
    start = paramResult.end;
    if (params.has(paramResult.value.key)) {
      throw new Error(`Duplicate auth param: ${paramResult.value.key}`);
    }
    params.set(paramResult.value.key, paramResult.value.value);
    start = optionalWhitespaceRead(string, start);
    if (string[start] !== ",") {
      break;
    }
    start++;
    start = optionalWhitespaceRead(string, start);
  }
  return { end: start, value: { scheme: schemeResult.value, info: params } };
}

function credentialsWrite(credentials: Credentials): string {
  let string = tokenWrite(credentials.scheme);
  if (typeof credentials.info === "string") {
    string += ` ${token68Write(credentials.info)}`;
  } else if (credentials.info instanceof Map) {
    string += " ";
    string += [...credentials.info]
      .map(([key, value]) => authParamWrite({ key, value }))
      .join(", ");
  }
  return string;
}

const token68Regex = /[A-Za-z0-9-._~+/]+=*/y;

function token68Read(
  string: string,
  start: number,
): { end: number; value: Token } {
  token68Regex.lastIndex = start;
  const match = token68Regex.exec(string);
  if (!match) {
    throw new Error(`Expected token68 at ${start}`);
  }
  return { end: token68Regex.lastIndex, value: match[0] };
}

function token68Write(value: Token): string {
  token68Regex.lastIndex = 0;
  token68Regex.exec(value);
  if (token68Regex.lastIndex !== value.length) {
    throw new Error(
      `Invalid token68 character: ${value[token68Regex.lastIndex]}`,
    );
  }
  return value;
}
