/**
 * @file
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-8 RFC 9110 8 Representation Data and Metadata}
 */

import { LanguageTag } from "@redotech/language";
import {
  FieldName,
  FieldType,
  FieldValueFormat,
  dateFieldValueFormat,
  integerFieldValueFormat,
  listFieldFormat,
  mediaFieldValueFormat,
  singleFieldFormat,
  stringFieldValueFormat,
  uriFieldValueFormat,
} from "./fields";
/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-8.3 RFC 9110 8.3 Content-Type}
 */
export const ContentTypeField = new FieldType(
  new FieldName("Content-Type"),
  singleFieldFormat(mediaFieldValueFormat),
);

export class ContentCoding {
  constructor(readonly token: string) {}
}

const codingFieldValueFormat: FieldValueFormat<ContentCoding> = {
  read(fieldValue: string): ContentCoding {
    return new ContentCoding(fieldValue);
  },
  write(value: ContentCoding) {
    return value.token;
  },
};

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-8.4 RFC 9110 8.4 Content-Encoding}
 */
export const ContentEncodingField = new FieldType(
  new FieldName("Content-Encoding"),
  singleFieldFormat(codingFieldValueFormat),
);

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-8.4.1.1 RFC 9110 8.4.1.1 Compress Coding}
 */
export const CompressCoding = new ContentCoding("compress");

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-8.4.1.1 RFC 9110 8.4.1.2 Deflate Coding}
 */
export const DeflateCoding = new ContentCoding("deflate");

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-8.4.1.1 RFC 9110 8.4.1.3 Gzip Coding}
 */
export const GzipCoding = new ContentCoding("gzip");

const languageTagFieldValueFormat: FieldValueFormat<LanguageTag> = {
  read(fieldValue: string) {
    return LanguageTag.parse(fieldValue);
  },
  write(value: LanguageTag) {
    return value.toString();
  },
};

/**
 * Content-Language
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-8.5 RFC 9110 8.5 Content-Language}
 */
export const ContentLanguageField = new FieldType(
  new FieldName("Content-Language"),
  listFieldFormat(languageTagFieldValueFormat),
);

/**
 * Content-Length
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-8.6 RFC 9110 8.6 Content-Length}
 */
export const ContentLengthField = new FieldType(
  new FieldName("Content-Length"),
  singleFieldFormat(integerFieldValueFormat),
);

/**
 * Content-Location
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-8.7 RFC 9110 8.7 Content-Location}
 */
export const ContentLocation = new FieldType(
  new FieldName("Content-Location"),
  singleFieldFormat(uriFieldValueFormat),
);

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-8.8.3 RFC 9110 8.8.3 ETag}
 */
export const EtagField = new FieldType(
  new FieldName("Etag"),
  singleFieldFormat(stringFieldValueFormat),
);

/**
 * Last-Modified
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-8.8.2 RFC 9110 8.8.2 Last-Modified}
 */
export const LastModified = new FieldType(
  new FieldName("Last-Modified"),
  singleFieldFormat(dateFieldValueFormat),
);
