export async function sleep(
  duration: Temporal.Duration,
  signal?: AbortSignal,
): Promise<void> {
  signal?.throwIfAborted();
  await new Promise<void>((resolve, reject) => {
    const timeout = setTimeout(() => {
      resolve();
      signal?.removeEventListener("abort", abort);
    }, duration.total("milliseconds"));
    const abort = () => {
      clearTimeout(timeout);
      reject(signal!.reason);
    };
    signal?.addEventListener("abort", abort);
  });
}
