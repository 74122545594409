import { Shipment, ShipmentZodSchema } from "./shipment";

import { z } from "zod";

export enum ReturnMethodType {
  PICKUP = "Pickup",
  GREEN = "Green",
  DROP_OFF = "Drop Off",
  SHIPMENT = "Shipment",
  ERROR = "Error",
}

export const PickupReturnMethodZodSchema = z.object({
  type: z.literal(ReturnMethodType.PICKUP),
  pickupRequest: z.any(),
  pickupConfirmation: z.any(),
});

export const GreenReturnMethodZodSchema = z.object({
  type: z.literal(ReturnMethodType.GREEN),
});

export const DropOffReturnMethodZodSchema = z.object({
  type: z.literal(ReturnMethodType.DROP_OFF),
});

export const ShipmentReturnMethodZodSchema = z.object({
  type: z.literal("Shipment"),
  shipment: ShipmentZodSchema.nullish(),
});

export const ErrorReturnMethodZodSchema = z.object({
  type: z.literal(ReturnMethodType.ERROR),
  error: z.string(),
});
export const ReturnMethodZodSchema = z.discriminatedUnion("type", [
  PickupReturnMethodZodSchema,
  GreenReturnMethodZodSchema,
  DropOffReturnMethodZodSchema,
  ShipmentReturnMethodZodSchema,
  ErrorReturnMethodZodSchema,
]);

export type ReturnMethod =
  | PickupReturnMethod
  | GreenReturnMethod
  | DropOffReturnMethod
  | ShipmentReturnMethod
  | ErrorReturnMethod;

export interface PickupReturnMethod {
  type: ReturnMethodType.PICKUP;
  pickupRequest: object;
  pickupConfirmation: object;
}

export interface GreenReturnMethod {
  type: ReturnMethodType.GREEN;
}

export interface DropOffReturnMethod {
  type: ReturnMethodType.DROP_OFF;
}

export interface ShipmentReturnMethod {
  type: ReturnMethodType.SHIPMENT;
  shipment: Shipment;
}

export interface ErrorReturnMethod {
  type: ReturnMethodType.ERROR;
  error: string;
}
