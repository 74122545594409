import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { Customer } from "@redotech/redo-model/customer";
import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCustomerInfo } from "../api";
import { UserContext } from "./user";

export const CustomerContext = createContext<Customer | undefined>(undefined);
export const ReloadCustomerContext = createContext<(() => void) | undefined>(
  undefined,
);

export function CustomerContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [customerTrigger, setCustomerTrigger] = useState(Symbol());

  const customerLoad = useLoad(async () => {
    if (!user?.customerId) {
      return undefined;
    }
    try {
      const response = await getCustomerInfo(user.customerId);
      const fetchedCustomer: Customer = response.data.customer;
      if (fetchedCustomer) {
        return fetchedCustomer;
      }
    } catch (err) {
      // If we fail to get the customer info, we'll just redirect to the returns portal
      // We don't want the customer portal to be accessible without this customer context
      console.log(err);
      navigate(`/returns-portal`);
    }
    return undefined;
  }, [user, customerTrigger]);

  const reloadCustomer = useHandler(() => setCustomerTrigger(Symbol()));

  return (
    <CustomerContext.Provider value={customerLoad.value}>
      <ReloadCustomerContext.Provider value={reloadCustomer}>
        {children}
      </ReloadCustomerContext.Provider>
    </CustomerContext.Provider>
  );
}
