import type { SchemaType } from "./advanced-flow/schemas/schemas";
import type { Category } from "./advanced-flow/triggers";
import type {
  Schema,
  SchemaInstance,
} from "./advanced-flow/type-system/schema";
import type { Team, UpsellProductSection, UpsellProductSource } from "./team";
import { UtmParameters } from "./utm";
export { widthPixelsToPercentage } from "./email-builder/email-sizing";

// Josh - I have no idea what any of this is doing.
// I extracted this from the customer return generation process
// and inferred the types from the context.
export interface CustomerReturnCreatedAttachmentGenerationParameters {
  shipments: {
    postage_label?: string;
    form_label?: string;
    _shipment: {
      forms?: {
        form_type?: string;
        form_url?: string;
      }[];
    };
  }[];
  variables: {
    postage_label?: string;
    form_label?: string;
    commercialInvoice?: string;
  };
  pickup_details: boolean; // all we care about is truthiness, apparently
}

export enum EmailFormat {
  STATIC = "static",
  AMP = "amp",
  // Apple Mail should NOT be added here.
  // It should instead be rendered inside of static emails, conditionally rendered via @supports queries
}

export namespace ButtonSize {
  export const SMALL = 0.8;
  export const MEDIUM = 1;
  export const LARGE = 1.2;
  export const DEFAULT_HEIGHT = 40;
  export const DEFAULT_WIDTH = 200;
}

export namespace PaddingSize {
  export const SMALL = 8;
  export const MEDIUM = 16;
  export const LARGE = 32;
}

export type Padding = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export namespace TemplateType {
  export const TRANSACTIONAL = "transactional";
  export const MARKETING = "marketing";
  export const DEFAULT = "default";
}
export type TemplateType =
  | typeof TemplateType.TRANSACTIONAL
  | typeof TemplateType.MARKETING
  | typeof TemplateType.DEFAULT;

export const TEMPLATE_TYPES: TemplateType[] = [
  TemplateType.TRANSACTIONAL,
  TemplateType.MARKETING,
  TemplateType.DEFAULT,
];

export function templateTypeLabel(templateType: TemplateType): string {
  switch (templateType) {
    case TemplateType.TRANSACTIONAL:
      return "Transactional";
    case TemplateType.MARKETING:
      return "Marketing";
    case TemplateType.DEFAULT:
      return "Default";
  }
}

export namespace Alignment {
  export const LEFT = "left";
  export const CENTER = "center";
  export const RIGHT = "right";
}
export type Alignment =
  | typeof Alignment.LEFT
  | typeof Alignment.CENTER
  | typeof Alignment.RIGHT;

export namespace EmailHeaderType {
  export const IMAGE = "image";
  export const LOGO = "logo";
  export const TEXT = "text";
}
export type EmailHeaderType =
  | typeof EmailHeaderType.IMAGE
  | typeof EmailHeaderType.LOGO
  | typeof EmailHeaderType.TEXT;

export namespace Size {
  export const SMALL = "small";
  export const MEDIUM = "medium";
  export const LARGE = "large";
  export const CUSTOM = "custom";
}
export type Size =
  | typeof Size.SMALL
  | typeof Size.MEDIUM
  | typeof Size.LARGE
  | typeof Size.CUSTOM;

export type Address = {
  businessAddress: string;
  legalAddress: string;
  cityStateZip: string;
  country: string;
};

export namespace FontFamily {
  export const ARIAL = "Arial";
  export const COURIER_NEW = "Courier New";
  export const GEORGIA = "Georgia";
  export const LUCIDA_SANS_UNICODE = "Lucida Sans Unicode";
  export const TAHOMA = "Tahoma";
  export const TIMES_NEW_ROMAN = "Times New Roman";
  export const TREBUCHET_MS = "Trebuchet MS";
  export const VERDANA = "Verdana";
}
export type FontFamily =
  | typeof FontFamily.ARIAL
  | typeof FontFamily.COURIER_NEW
  | typeof FontFamily.GEORGIA
  | typeof FontFamily.LUCIDA_SANS_UNICODE
  | typeof FontFamily.TAHOMA
  | typeof FontFamily.TIMES_NEW_ROMAN
  | typeof FontFamily.TREBUCHET_MS
  | typeof FontFamily.VERDANA;

export const FONT_FAMILIES = [
  FontFamily.ARIAL,
  FontFamily.COURIER_NEW,
  FontFamily.GEORGIA,
  FontFamily.LUCIDA_SANS_UNICODE,
  FontFamily.TAHOMA,
  FontFamily.TIMES_NEW_ROMAN,
  FontFamily.TREBUCHET_MS,
  FontFamily.VERDANA,
] as const;

export namespace ButtonVariableLinkType {
  export const UNSUBSCRIBE_LINK = "Unsubscribe Link";
  export const SUBSCRIBE_LINK = "Subscribe Link";
  export const TRACK_PACKAGE_LINK = "Track Package Link";
}
export type ButtonVariableLinkType =
  | typeof ButtonVariableLinkType.UNSUBSCRIBE_LINK
  | typeof ButtonVariableLinkType.SUBSCRIBE_LINK
  | typeof ButtonVariableLinkType.TRACK_PACKAGE_LINK;

export const BUTTON_VARIABLE_LINK_TYPES = [
  ButtonVariableLinkType.UNSUBSCRIBE_LINK,
  ButtonVariableLinkType.SUBSCRIBE_LINK,
  ButtonVariableLinkType.TRACK_PACKAGE_LINK,
];

export enum EmailBlockType {
  TRACKABLE_SUMMARY = "order-summary",
  TRACKING_INFO = "tracking-info",
  BUTTON = "button",
  INTERACTIVE_CART = "interactive-cart",
  HEADER = "header",
  FOOTER = "footer",
  IMAGE = "image",
  TEXT = "text",
  SPACER = "spacer",
  LINE = "line",
  REVIEW_REQUEST = "review-request",
}

export namespace Section {
  interface BaseSection {
    schemaFieldName?: string;
    blockId?: string;
  }

  export interface Header extends BaseSection {
    type: EmailBlockType.HEADER;
    headerType: EmailHeaderType;
    layout: Alignment;
    sectionColor: string;
    padding: number;
    imageUrl: string;
    text: string;
    textColor: string;
    fontSize: number;
    fontFamily: FontFamily;
    logoHeight: number;
    imageHeight: number;
    imageScale?: string;
  }

  export interface Footer extends BaseSection {
    type: EmailBlockType.FOOTER;
    padding: Padding;
    horizontalPadding: Size;
    verticalPadding: Size;
    sectionColor: string;
    textColor: string;
    alignment: Alignment;
    fontSize?: number;
    fontFamily?: FontFamily;
  }

  export interface Text extends BaseSection {
    type: EmailBlockType.TEXT;
    padding: number;
    textColor: string;
    fontSize: number;
    fontFamily: FontFamily;
    sectionColor: string;
    linkColor: string;
    text: string;
  }

  export interface Button extends BaseSection {
    type: EmailBlockType.BUTTON;
    width: number;
    height: number;
    size: Size;
    alignment: Alignment;
    cornerRadius: number;
    buttonText: string;
    horizontalPadding: Size;
    verticalPadding: Size;
    padding: Padding;
    /** hardcoded button link as opposed to dynamic variable from schema */
    buttonLink?: string;
    fillColor: string;
    strokeColor: string;
    textColor: string;
    strokeWeight: number;
    fontFamily: FontFamily;
    fontSize: number;
    sectionColor: string;
    as?: "button" | "a";
    buttonType?: "button" | "submit" | "reset";
    fullWidth?: boolean;
  }

  export interface ReviewRequest extends BaseSection {
    type: EmailBlockType.REVIEW_REQUEST;
    padding: number;
    sectionColor: string;
    sectionHeader: string;
    imageCornerRadius: number;
    imageSize: Size;
    buttonHeight: number;
    buttonWidth: number;
    buttonColor: string;
    buttonTextColor: string;
    buttonSize: Size;
    buttonCornerRadius: number;
    textColor: string;
    fontFamily: FontFamily;
  }

  export interface Line extends BaseSection {
    type: EmailBlockType.LINE;
    color: string;
    padding: Padding;
    horizontalPadding: Size;
    verticalPadding: Size;
    sectionColor: string;
  }

  export interface Spacer extends BaseSection {
    type: EmailBlockType.SPACER;
    sectionColor: string;
    height: number;
  }

  export interface Image extends BaseSection {
    type: EmailBlockType.IMAGE;
    imageUrl: string;
    padding: Padding;
    horizontalPadding: Size;
    verticalPadding: Size;
    sectionColor: string;
    showCaption: boolean;
    caption?: string;
    altText?: string;
    clickthroughUrl?: string;
    aspectRatio?: number; // needed for amp images
  }

  export interface TrackingInfo extends BaseSection {
    type: EmailBlockType.TRACKING_INFO;
    padding: number;
    sectionColor: string;
    textColor: string;
    buttonVisible: boolean;
    buttonHeight: number;
    buttonWidth: number;
    buttonColor: string;
    buttonTextColor: string;
    buttonSize: Size;
    buttonCornerRadius: number;
    statusIndicatorColor: string;
    statusIndicatorInnerColor: string;
    fontFamily: FontFamily;
  }

  export interface TrackableSummary extends BaseSection {
    type: EmailBlockType.TRACKABLE_SUMMARY;
    showOrderInformation: boolean;
    showCostSummary: boolean;
    showPaymentInformation: boolean;
    showCustomerInformation: boolean;
    padding: number;
    sectionColor: string;
    textColor: string;
    fontFamily: FontFamily;
    imageSize: Size;
    imageCornerRadius: number;
  }

  export interface InteractiveCart extends BaseSection, UpsellProductSection {
    type: EmailBlockType.INTERACTIVE_CART;
    padding: number;
    sectionColor: string;
    textColor: string;
    fontFamily: FontFamily;
    imageCornerRadius: number;
    checkoutButton: Button;
    lineItemButtons: Button;
    numberOfProducts: number;
    imageSize: Size;
    source: UpsellProductSource;
    showPrice?: boolean;
    collectionId?: string;
    metafield?: {
      key: string;
      namespace: string;
    };
    columns?: number;
  }
}

export type Section =
  | Section.Header
  | Section.Footer
  | Section.Text
  | Section.Button
  | Section.Line
  | Section.Spacer
  | Section.Image
  | Section.TrackingInfo
  | Section.TrackableSummary
  | Section.InteractiveCart
  | Section.ReviewRequest;

export interface SharedProps {
  format: EmailFormat;
  team: Team;
  template: Omit<
    IEmailTemplate,
    "schemaType" | "category" | "sections" | "team"
  >;
  schemaInstance: SchemaInstance<Schema>;
  isBuilder: boolean;
  blockId?: string;
  utm: UtmParameters;
}

export interface IEmailTemplate {
  _id: string;
  name: string;
  templateType: TemplateType;
  subject: string;
  emailPreview?: string | null;
  sections: Section[];
  emailBackgroundColor: string;
  contentBackgroundColor: string;
  linkColor?: string | null;
  address: Address;
  team: any;
  schemaType: SchemaType;
  category: Category;
}

export const getButtonSize = (
  size: Size,
  customWidth: number,
  customHeight: number,
) => {
  switch (size) {
    case Size.SMALL:
      return {
        width: ButtonSize.DEFAULT_WIDTH,
        height: ButtonSize.DEFAULT_HEIGHT * ButtonSize.SMALL,
      };
    case Size.MEDIUM:
      return {
        width: ButtonSize.DEFAULT_WIDTH,
        height: ButtonSize.DEFAULT_HEIGHT * ButtonSize.MEDIUM,
      };
    case Size.LARGE:
      return {
        width: ButtonSize.DEFAULT_WIDTH,
        height: ButtonSize.DEFAULT_HEIGHT * ButtonSize.LARGE,
      };
    case Size.CUSTOM:
      return {
        width: customWidth,
        height: customHeight,
      };
  }
};

export const getPadding = (
  horizontalPadding: Size,
  verticalPadding: Size,
  customPadding: Padding,
) => {
  let top = customPadding.top,
    right = customPadding.right,
    bottom = customPadding.bottom,
    left = customPadding.left;
  switch (horizontalPadding) {
    case Size.SMALL:
      left = right = PaddingSize.SMALL;
      break;
    case Size.MEDIUM:
      left = right = PaddingSize.MEDIUM;
      break;
    case Size.LARGE:
      left = right = PaddingSize.LARGE;
      break;
  }
  switch (verticalPadding) {
    case Size.SMALL:
      top = bottom = PaddingSize.SMALL;
      break;
    case Size.MEDIUM:
      top = bottom = PaddingSize.MEDIUM;
      break;
    case Size.LARGE:
      top = bottom = PaddingSize.LARGE;
      break;
  }
  return {
    top,
    right,
    bottom,
    left,
  };
};

export class EmailUtmParameters extends UtmParameters {
  constructor(campaign?: string) {
    super({
      source: "redo",
      medium: "email",
      campaign,
    });
  }

  override getCampaign(): string | undefined {
    return super.getCampaign();
  }

  override getSource(): string {
    return super.getSource() ?? "";
  }

  override getMedium(): string {
    return super.getMedium() ?? "";
  }
}
