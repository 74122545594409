import { useEffect, useState } from "react";
import { useWindowSize } from "usehooks-ts";

export function useDevicePixelRatio() {
  const [ratio, setRatio] = useState(devicePixelRatio);

  useEffect(() => {
    const media = matchMedia(`screen and (resolution: ${ratio}dppx)`);
    const update = () => setRatio(devicePixelRatio);
    media.addEventListener("change", update);
    return () => media.removeEventListener("change", update);
  }, [ratio]);

  return ratio;
}

export type ScreenType = typeof ScreenType.DESKTOP | typeof ScreenType.MOBILE;

export namespace ScreenType {
  export const DESKTOP = Symbol("desktop");
  export const MOBILE = Symbol("mobile");
}

export function getScreenType(size: {
  width: number;
  height: number;
}): ScreenType {
  if (size.width <= 600) {
    return ScreenType.MOBILE;
  }
  return ScreenType.DESKTOP;
}

export function useScreenType() {
  const size = useWindowSize();
  return getScreenType(size);
}
