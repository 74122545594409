import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ScreenType, useScreenType } from "@redotech/react-util/screen";
import { SetAuthContext } from "@redotech/redo-customer-portal-app/contexts/auth";
import { SettingsContext } from "@redotech/redo-customer-portal-app/contexts/settings";
import {
  SetUserContext,
  UserContext,
} from "@redotech/redo-customer-portal-app/contexts/user";
import { CurrencyContext } from "@redotech/redo-web/currency";
import ChevronDown from "@redotech/redo-web/icon-old/chevron-down.svg";
import LeftArrow from "@redotech/redo-web/icon-old/left-arrow.svg";
import * as link from "@redotech/redo-web/link.module.css";
import { sleep } from "@redotech/util/schedule";
import * as classNames from "classnames";
import { memo, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AdvancedExchangeContext } from "../contexts/AdvancedExchangeContext";
import { CurrentItemBeingReturnedContext } from "../contexts/CurrentItemBeingReturnedContext";
import { ExchangeTypeContext } from "../contexts/ExchangeTypeContext";
import { FlowStep, FlowStepContext } from "../contexts/FlowStepContext";
import { ItemsReadyForReturnContext } from "../contexts/ItemsReadyForReturnContext";
import { StorefrontCartContext } from "../contexts/StorefrontCartContext";
import { calculateReturnTotals } from "../utils/utils";
import * as navbarCss from "./navbar.module.css";
import { OrderContext } from "./order";
import * as page from "./page.module.css";

import { CustomerContext } from "@redotech/redo-customer-portal-app/contexts/CustomerContext";
import { allowCustomerAccounts } from "@redotech/redo-web/utils/customer-account-utils";
import { BackHandlerContext } from "../contexts/BackHandlerContext";

export const Navbar = memo(function Navbar({
  alwaysShow = false,
  className = "",
  showBackButton = true,
}: {
  alwaysShow: boolean;
  className?: string;
  showBackButton?: boolean;
}) {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const setUser = useContext(SetUserContext);
  const setAuth_ = useContext(SetAuthContext);
  const settings = useContext(SettingsContext);
  const { flowStep, setFlowStep, submitting } = useContext(FlowStepContext);
  const { setExchangeType, setExchangeGroup } = useContext(ExchangeTypeContext);
  const currentOrder = useContext(OrderContext);
  const { currentItemBeingReturned, setCurrentItemBeingReturned } = useContext(
    CurrentItemBeingReturnedContext,
  );
  const {
    itemsReadyForReturn,
    availableReturnOptions,
    setAvailableReturnOptions,
    setItemsReadyForReturn,
  } = useContext(ItemsReadyForReturnContext);
  const size = useScreenType();
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [storeCredit, setStoreCredit] = useState(0);
  const {
    cartVisible,
    setCartMenuOpen,
    setCartVisible,
    setSelectedSuggestedProduct,
  } = useContext(AdvancedExchangeContext);
  const { shopifyStorefrontCart } = useContext(StorefrontCartContext);
  const { formatCurrency } = useContext(CurrencyContext);
  const customerContext = useContext(CustomerContext);
  const { backHandler } = useContext(BackHandlerContext);

  useEffect(() => {
    if (cartVisible && currentOrder) {
      calculateTotals();
    }
  }, [shopifyStorefrontCart, cartVisible, currentOrder]);

  const calculateTotals = () => {
    const returnTotals = calculateReturnTotals(
      itemsReadyForReturn,
      currentOrder,
      settings,
      shopifyStorefrontCart,
    );
    setStoreCredit(
      returnTotals.totalStoreCredit + returnTotals.nonAccruableLeftover,
    );
  };

  const getInitials = (string: string) => {
    return string
      .split(" ", 2)
      .map((name) => name.slice(0, 1).toUpperCase())
      .join("");
  };

  if (!user && !alwaysShow) {
    return null;
  }
  return (
    <div className={classNames(className, page.navBarContent)}>
      <div
        className={page.backArrow}
        onClick={() => {
          if (backHandler?.()) {
            return;
          }
          if (
            flowStep === FlowStep.ITEMS &&
            window.location.pathname.includes("warranties")
          ) {
            setFlowStep(FlowStep.PRODUCT_SELECT);
            return;
          }
          if (flowStep === FlowStep.CONFIRMATION || submitting) {
            return;
          }
          setCartVisible(false);
          setCartMenuOpen(false);
          setExchangeType(undefined);
          setSelectedSuggestedProduct(undefined);
          setExchangeGroup(undefined);
          if (flowStep === FlowStep.ITEMS) {
            if (
              !!availableReturnOptions?.shopNow ||
              !!availableReturnOptions?.refund ||
              !!availableReturnOptions?.storeCredit
            ) {
              setAvailableReturnOptions({});
              setCurrentItemBeingReturned(undefined);
            } else if (currentItemBeingReturned) {
              // used for mobile
              setCurrentItemBeingReturned(undefined);
            }
            navigate(-1);
          } else if (flowStep === FlowStep.REVIEW) {
            // prevent the page from automatically going to the review page if the user manually goes back
            const numAvailableReturnOptions = Object.values(
              availableReturnOptions,
            ).filter((option: any) => option).length;
            if (
              itemsReadyForReturn.length === 1 &&
              numAvailableReturnOptions <= 1 &&
              currentOrder.returnableItems.length === 1
            ) {
              setItemsReadyForReturn([]);
            }
            setAvailableReturnOptions({});
            setFlowStep(FlowStep.ITEMS);
          }
        }}
      >
        {flowStep !== FlowStep.CONFIRMATION &&
          !submitting &&
          showBackButton && <StoreReturn />}
      </div>
      <div className={page.imageWrapper}>
        <a
          href={`https://${settings.storeUrl}`}
          rel="noreferrer"
          target="_blank"
        >
          <img
            className={page.navBarLogo}
            id="navbar-logo"
            src={settings.theme?.logo_image}
          />
        </a>
      </div>
      <div className={page.sideButtons}>
        {size === ScreenType.DESKTOP && (
          <div
            className={classNames(page.cartContainer, {
              [navbarCss.invisible]: !cartVisible,
            })}
            onClick={() => {
              if (cartVisible) {
                setCartMenuOpen(true);
              }
            }}
          >
            {storeCredit > 0 && (
              <div className={classNames(page.cartTotalText, page.textSuccess)}>
                {formatCurrency(storeCredit)}
              </div>
            )}
            <div className={page.cartSizeBubble}>
              {shopifyStorefrontCart?.lines?.edges?.length || 0}
            </div>
            <svg
              className={page.cart}
              fill="none"
              height="24"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10" cy="20.5" r="1" />
              <circle cx="18" cy="20.5" r="1" />
              <path d="M2.5 2.5h3l2.7 12.4a2 2 0 0 0 2 1.6h7.7a2 2 0 0 0 2-1.6l1.6-8.4H7.1" />
            </svg>
          </div>
        )}
        <div id="nav-bar-cart-icon-container" />{" "}
        {/* This is used to create a cart icon on the new portal advanced exchange page */}
        {!!user && (
          <div className={page.account}>
            {size === ScreenType.DESKTOP && (
              <div className={page.accountText}>
                <div className={page.nameText}>
                  {/*Show guest name if there is one*/}
                  {user.guestName || user.name}
                </div>
              </div>
            )}

            <div
              className={page.avatarContainer}
              onClick={() =>
                setAccountDropdownOpen(accountDropdownOpen ? false : true)
              }
            >
              <div className={page.avatar}>
                {getInitials(user.guestName || user.name)}
              </div>
              <div className={page.chevronDown}>
                <ChevronDown />
              </div>
            </div>

            {accountDropdownOpen && (
              <ClickAwayListener
                onClickAway={() => setAccountDropdownOpen(false)}
              >
                <div className={page.accountDropdown}>
                  {!user.guestEmail && (
                    <div className={page.accountMenuListItem}>
                      <Link to="/returns-portal/orders">My orders</Link>
                    </div>
                  )}
                  {allowCustomerAccounts(settings.customerAccounts) &&
                    !!customerContext &&
                    customerContext.email && (
                      <div className={page.accountMenuListItem}>
                        <Link to="/returns-portal/account">My account</Link>
                      </div>
                    )}
                  <div
                    className={page.accountMenuListItem}
                    onClick={async () => {
                      setAuth_(undefined);
                      setUser(undefined);
                      await sleep(new Temporal.Duration());
                      navigate("/returns-portal/login");
                    }}
                  >
                    Logout
                  </div>
                </div>
              </ClickAwayListener>
            )}
          </div>
        )}
      </div>
    </div>
  );
});

const StoreReturn = memo(function StoreReturn({
  className = "",
}: {
  className?: string;
}) {
  const screenSize = useScreenType();

  return (
    <a className={classNames(className, page.storeReturn, link.link)}>
      <LeftArrow />
      {screenSize === ScreenType.DESKTOP && "Back"}
    </a>
  );
});
