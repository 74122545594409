// extracted by mini-css-extract-plugin
export var box = "_redotech-redo-web-src-checkbox-box";
export var check = "_redotech-redo-web-src-checkbox-check";
export var checkboxButton = "_redotech-redo-web-src-checkbox-checkbox-button";
export var checkboxGroup = "_redotech-redo-web-src-checkbox-checkbox-group";
export var disabled = "_redotech-redo-web-src-checkbox-disabled";
export var ghost = "_redotech-redo-web-src-checkbox-ghost";
export var input = "_redotech-redo-web-src-checkbox-input";
export var label = "_redotech-redo-web-src-checkbox-label";
export var normal = "_redotech-redo-web-src-checkbox-normal";
export var uncheck = "_redotech-redo-web-src-checkbox-uncheck";