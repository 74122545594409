import { ProductInfo } from "@redotech/shopify-storefront";
import { createContext, useState } from "react";

const AdvancedExchangeContext = createContext({
  // All the products in which the products were selected for an advanced exchange
  cartMenuOpen: false,
  setCartMenuOpen: (cartMenuOpen: boolean) => {},
  cartVisible: false,
  setCartVisible: (cartVisible: boolean) => {},
  suggestedProducts: [] as ProductInfo[],
  setSuggestedProducts: (suggestedProducts: ProductInfo[]) => {},
  selectedSuggestedProduct: null,
  setSelectedSuggestedProduct: (selectedSuggestedProduct: any) => {},
});

const AdvancedExchangeContextProvider = ({ children }) => {
  const [cartMenuOpen, setCartMenuOpen] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [selectedSuggestedProduct, setSelectedSuggestedProduct] =
    useState(null);

  return (
    <AdvancedExchangeContext.Provider
      value={{
        cartMenuOpen,
        setCartMenuOpen,
        cartVisible,
        setCartVisible,
        suggestedProducts,
        setSuggestedProducts,
        selectedSuggestedProduct,
        setSelectedSuggestedProduct,
      }}
    >
      {children}
    </AdvancedExchangeContext.Provider>
  );
};

export { AdvancedExchangeContext, AdvancedExchangeContextProvider };
