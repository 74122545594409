import { RouteObject } from "react-router-dom";

export const returnRoutes: RouteObject[] = [
  {
    path: ":returnId/completeExchange",
    lazy: async () => {
      const { CompleteExchange } = await import("./complete-exchange");
      return { Component: CompleteExchange };
    },
  },
];
