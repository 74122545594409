import {
  AuthGuard,
  CustomerAccountAuthGuard,
} from "@redotech/redo-customer-portal-app/contexts/auth";
import { Navigate, Outlet, RouteObject } from "react-router-dom";

export const accountRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Home" },
    element: (
      <AuthGuard>
        <CustomerAccountAuthGuard>
          <Outlet />
        </CustomerAccountAuthGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        async lazy() {
          const { CustomerAccountTabs } = await import(
            "./customer-account-tabs"
          );
          return { Component: CustomerAccountTabs };
        },
        children: [
          {
            index: true,
            element: <Navigate replace to="home" />,
          },
          {
            path: "home",
            async lazy() {
              const { CustomerAccountHome } = await import(
                "./home/customer-account-home"
              );
              return { Component: CustomerAccountHome };
            },
          },
          {
            path: "orders",
            async lazy() {
              const { CustomerAccountOrders } = await import(
                "./orders/customer-account-orders"
              );
              return { Component: CustomerAccountOrders };
            },
          },
          {
            path: "lists",
            async lazy() {
              const { CustomerAccountLists } = await import(
                "./lists/customer-account-lists"
              );
              return { Component: CustomerAccountLists };
            },
          },
          {
            path: "warranties",
            async lazy() {
              const { CustomerAccountWarranties } = await import(
                "./warranties/customer-account-warranties"
              );
              return { Component: CustomerAccountWarranties };
            },
          },
          {
            path: "personal-info",
            async lazy() {
              const { CustomerAccountPersonalInfo } = await import(
                "./personal-info/customer-account-personal-info"
              );
              return { Component: CustomerAccountPersonalInfo };
            },
          },
        ],
      },
    ],
  },
];
