import { ExchangeGroup } from "@redotech/redo-model/team";
import { createContext, useState } from "react";

const ExchangeTypeContext = createContext<{
  exchangeType: string | undefined;
  setExchangeType: (exchangeType: string | undefined) => void;
  exchangeGroup: ExchangeGroup | undefined;
  setExchangeGroup: (selectedExchangeGroup: ExchangeGroup | undefined) => void;
}>({
  exchangeType: undefined,
  setExchangeType: (exchangeType: string | undefined) => {},
  exchangeGroup: undefined,
  setExchangeGroup: (selectedExchangeGroup: ExchangeGroup | undefined) => {},
});

const ExchangeTypeContextProvider = ({ children }) => {
  const [exchangeType, setExchangeType] = useState<string | undefined>(
    undefined,
  );
  const [exchangeGroup, setExchangeGroup] = useState<ExchangeGroup | undefined>(
    undefined,
  );

  return (
    <ExchangeTypeContext.Provider
      value={{
        exchangeType,
        setExchangeType,
        exchangeGroup,
        setExchangeGroup,
      }}
    >
      {children}
    </ExchangeTypeContext.Provider>
  );
};

export { ExchangeTypeContext, ExchangeTypeContextProvider };
