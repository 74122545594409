import { FontFamily, FontWeight } from "../brand-kit";

export enum FaqForgeElementType {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  RICH_TEXT = "rich-text",
  IMAGE = "image",
  VIDEO = "video",
  DIVIDER = "divider",
}

export enum FaqJustify {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export type BaseFaqForgeElement<T extends FaqForgeElementType> = {
  type: T;
};

interface BaseFaqRichTextElement<T extends FaqForgeElementType>
  extends BaseFaqForgeElement<T> {
  id: string;
  html: string;
  fontFamily: FontFamily;
  fontWeight: FontWeight;
  fontSizePx: number;
  textColor: string;
  linkColor: string;
}
export interface FaqRichTextElement
  extends BaseFaqRichTextElement<FaqForgeElementType.RICH_TEXT> {}
export interface FaqH1Element
  extends BaseFaqRichTextElement<FaqForgeElementType.H1> {}
export interface FaqH2Element
  extends BaseFaqRichTextElement<FaqForgeElementType.H2> {}
export interface FaqH3Element
  extends BaseFaqRichTextElement<FaqForgeElementType.H3> {}

export interface FaqImageElement
  extends BaseFaqForgeElement<FaqForgeElementType.IMAGE> {
  url: string;
  width?: number;
  justify: FaqJustify;
}

export interface FaqVideoElement
  extends BaseFaqForgeElement<FaqForgeElementType.VIDEO> {
  url: string;
  width: number;
  justify: FaqJustify;
}

export interface FaqDividerElement
  extends BaseFaqForgeElement<FaqForgeElementType.DIVIDER> {}

export type FaqForgeElement =
  | FaqH1Element
  | FaqH2Element
  | FaqH3Element
  | FaqRichTextElement
  | FaqImageElement
  | FaqVideoElement
  | FaqDividerElement;
