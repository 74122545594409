import { assertNever } from "@redotech/util/type";

enum FormatTimeType {
  AGO = "ago",
  UNTIL = "until",
}

/** TODO make this `formatTimeDifference` and then just pass in `Temporal.Now.zonedDateTimeISO()`. Gives us a free time diff printer as well.  */
const formatTimeDifferenceFromNow = (
  time: string | undefined,
  type: FormatTimeType,
): string => {
  if (!time) {
    return "";
  }

  const now = Temporal.Now.zonedDateTimeISO();
  const inputTime = Temporal.Instant.from(time).toZonedDateTimeISO(
    now.timeZoneId,
  );
  let duration;
  switch (type) {
    case FormatTimeType.AGO:
      duration = now.since(inputTime);
      break;
    case FormatTimeType.UNTIL:
      duration = now.until(inputTime);
      break;
    default:
      assertNever(type);
  }

  const minutes = Math.floor(duration.total({ unit: "minute" }));

  const today = now.toPlainDate();
  const inputDate = inputTime.toPlainDate();
  let daysDifference;
  switch (type) {
    case FormatTimeType.AGO:
      daysDifference = today.since(inputDate).total({ unit: "day" });
      break;
    case FormatTimeType.UNTIL:
      daysDifference = today.until(inputDate).total({ unit: "day" });
      break;
    default:
      assertNever(type);
  }

  // "Now" for times within a minute
  if (minutes < 1) {
    return "Now";
  }

  // "<Number of mintues>m" for times within 60 minutes
  if (minutes < 60) {
    return `${minutes}m`;
  }

  // "hh:mm AM/PM" for times within the current day
  if (daysDifference < 1) {
    return inputTime.toPlainTime().toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  // "Yesterday" or "Tomorrow" for times just a day apart
  if (daysDifference === 1) {
    switch (type) {
      case FormatTimeType.AGO:
        return "Yesterday";
      case FormatTimeType.UNTIL:
        return "Tomorrow";
      default:
        assertNever(type);
    }
  }

  // Day of the week for times within a week
  if (daysDifference < 7) {
    return inputDate.toLocaleString("en-US", { weekday: "long" });
  }

  // "<Month name>, dd, YYYY" for times further away than a week
  return getDateString(inputDate);
};

export const formatTimeAgo = (time: string | undefined): string => {
  return formatTimeDifferenceFromNow(time, FormatTimeType.AGO);
};

export const formatTimeUntil = (time: string | undefined): string => {
  return formatTimeDifferenceFromNow(time, FormatTimeType.UNTIL);
};

type DateLike = Date | Temporal.PlainDate;
type DateTimeLike = Date | Temporal.PlainDateTime | Temporal.Instant;

export function getDateString(date: DateLike): string {
  return date.toLocaleString(
    process.env.NODE_ENV === "test" || typeof navigator === "undefined" // not defined in node (server-side rendering)
      ? "en-US"
      : navigator.language,
    getPreferredDateTimeFormatOption(true, false),
  );
}

export function getDateTimeString(date: DateTimeLike): string {
  return date.toLocaleString(
    process.env.NODE_ENV === "test" || typeof navigator === "undefined" // not defined in node (server-side rendering)
      ? "en-US"
      : navigator.language,
    getPreferredDateTimeFormatOption(true, true),
  );
}

export function getPreferredDateTimeFormatOption(
  includeDate: boolean,
  includeTime: boolean,
): Intl.DateTimeFormatOptions {
  return {
    month: includeDate ? "short" : undefined,
    day: includeDate ? "numeric" : undefined,
    year: includeDate ? "numeric" : undefined,
    hour: includeTime ? "numeric" : undefined,
    minute: includeTime ? "numeric" : undefined,
    second: includeTime ? "numeric" : undefined,
    hour12: includeTime ? true : undefined,
  };
}
