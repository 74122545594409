import {
  AuthorizationField,
  ContentTypeField,
  credentialsFieldValueFormat,
} from "@redotech/http/semantics";
import { Json } from "@redotech/json/json";
import { MultipartType } from "@redotech/media-type/multipart";
import { Currency } from "@redotech/money/currencies";
import type { ExchangeRates } from "@redotech/money/exchange-rates";
import { bearerCredentialsFormat } from "@redotech/oauth2/request";
import { UpdateCustomerInfoData } from "@redotech/redo-model/customer";
import { ProductInfo } from "@redotech/redo-model/customer-event/customer-event-definition";
import { ICustomerProductList } from "@redotech/redo-model/customer-product-list/customer-product-list-definition";
import {
  customerProductListArrayJsonFormat,
  customerProductListJsonFormat,
} from "@redotech/redo-model/customer-product-list/customer-product-list-json-format";
import {
  getStoreCreditBalanceForCustomerSchema,
  GetStoreCreditTransactionResponseBody,
  getStoreCreditTransactionsSchema,
  redeemStoreCreditSchema,
} from "@redotech/redo-model/customer-wallet/customer-wallet-definition";
import { WarrantyRegistrationJson } from "@redotech/redo-model/customer-warranties/customer-warranties-json-format";
import {
  EmailConsent,
  MessagingConsent,
  SMSConsent,
} from "@redotech/redo-model/messaging-consent";
import { ReturnAddress } from "@redotech/redo-model/return";
import { Address, ReturnRequest } from "@redotech/redo-model/return-flow";
import { TrackableType } from "@redotech/redo-model/trackable";
import type { GetWidgetResponse } from "@redotech/redo-model/widget";
import {
  getLocalStorageJson,
  setLocalStorageJson,
} from "@redotech/redo-web/utils/local-storage-wrapper";
import { CUSTOMER_WIDGET_TOKEN_KEY } from "@redotech/redo-web/utils/shared-conf";
import type { old_ExchangeRates } from "@redotech/server/utils/open-exchange-rates";
import { LocationData } from "@redotech/server/utils/shopify/graphql/locations";
import type { AxiosInstance } from "axios";
import axios, { AxiosHeaders, AxiosResponse } from "axios";
import { REDO_API_URL } from "./config";

const CUSTOMER_PORTAL_TOKEN_KEY = "redo.portal_auth_token";

export function widgetClient(): AxiosInstance {
  const widgetId = getWidgetId();
  if (!widgetId) {
    throw new Error("Missing ?widget_id");
  }
  const baseURL = `${REDO_API_URL}/widgets/${widgetId}`;

  const headers = new AxiosHeaders();
  return axios.create({ baseURL, headers });
}

function client(): AxiosInstance {
  const headers = new AxiosHeaders();
  return axios.create({ baseURL: REDO_API_URL, headers });
}

const WIDGET_ID_REGEX = /^\/widget_id\/([^/]+).*/;

export function getWidgetId(): string | undefined {
  let widgetId =
    WIDGET_ID_REGEX.test(window.location.pathname) &&
    window.location.pathname.replace(WIDGET_ID_REGEX, "$1");
  if (!widgetId && widget_id !== "replace_me") {
    widgetId = widget_id;
  }
  return widgetId ? widgetId : undefined;
}

export async function getOrCreateCustomerWidgetAuthToken(): Promise<string> {
  await setCustomerWidgetAuthToken();
  return getLocalStorageJson(CUSTOMER_WIDGET_TOKEN_KEY);
}

export async function setCustomerWidgetAuthToken(): Promise<boolean> {
  const existingCustomerWidgetAuthToken = getLocalStorageJson(
    CUSTOMER_WIDGET_TOKEN_KEY,
  );
  if (existingCustomerWidgetAuthToken) {
    return false;
  }
  const customerAuthToken = localStorage.getItem(CUSTOMER_PORTAL_TOKEN_KEY);
  if (!customerAuthToken) {
    return false;
  }
  const { token } = await createCustomerWidgetAuth(customerAuthToken);
  setLocalStorageJson(CUSTOMER_WIDGET_TOKEN_KEY, token);
  return !!token;
}

export const authentication = () => {
  const token = localStorage.getItem(CUSTOMER_PORTAL_TOKEN_KEY);
  if (!token) {
    return {};
  }
  return {
    [String(AuthorizationField.name)]: credentialsFieldValueFormat.write(
      bearerCredentialsFormat.write(token),
    ),
  };
};

export const getCustomerInfo = (customerId: string) => {
  return widgetClient().get(`customer/${customerId}`, {
    headers: authentication(),
  });
};

export const putCustomerInfo = (customer: UpdateCustomerInfoData) => {
  return widgetClient().put(`customer/${customer._id}`, {
    headers: authentication(),
    customer,
  });
};

export const getOrders = (params: object) => {
  return widgetClient().get("orders", { headers: authentication(), params });
};

export const getFulfillmentOrdersByOrder = (shopifyOrderId: string) => {
  return widgetClient().get(`fulfillment-orders/${shopifyOrderId}`, {
    headers: authentication(),
  });
};

export const getOrderDiscountByOrderId = (
  id: string,
  trackingType?: TrackableType,
  returnTypes?: string[],
) => {
  const trackerParam = trackingType ? `/${trackingType}` : "";
  const isRefundParam = returnTypes ? `/${returnTypes.join(",")}` : "";
  return widgetClient().get(
    `orders/${id}/discount` + trackerParam + isRefundParam,
    {
      headers: authentication(),
    },
  );
};

export const createOrderDiscountByOrderId = (
  id: string,
  trackingType?: TrackableType,
  returnTypes?: string[],
) => {
  const params = [];

  if (trackingType) {
    params.push(`${trackingType}`);
  }

  if (returnTypes) {
    params.push(`${returnTypes.join(",")}`);
  }

  const queryString = params.length ? `/${params.join("/")}` : "";
  const url = `orders/${id}/discount${queryString}`;

  return widgetClient().post(url, {
    headers: authentication(),
  });
};

export const runAndReturnDiscountFlow = (
  id: string,
  trackingType?: TrackableType,
  returnTypes?: string[],
) => {
  const queryParams = new URLSearchParams();
  if (trackingType) {
    queryParams.append("trackingType", trackingType);
  }

  if (returnTypes) {
    queryParams.append("returnTypes", returnTypes.join(","));
  }

  const url = `orders/${id}/discountFlow?${queryParams.toString()}`;

  return widgetClient().get(url, {
    headers: authentication(),
  });
};

export const getOrderById = (id: string, includeUnfulfilled = false) => {
  return widgetClient().get(
    `orders/${id}${includeUnfulfilled ? "?includeUnfulfilled=true" : ""}`,
    { headers: authentication() },
  );
};

export const getReturn = (id: string) => {
  return widgetClient().get(`returns/${id}`, { headers: authentication() });
};

export const updateReturnNewOrderAddress = (id: string, address: Address) => {
  return widgetClient().put(`returns/${id}/newOrderAddress`, {
    headers: authentication(),
    params: {
      address: {
        name: address.name,
        address1: address.street1,
        address2: address.street2,
        city: address.city,
        province: address.state,
        zip: address.zip,
        country: address.country,
        phone: address.phone,
        email: address.email,
        country_code: address.country,
      },
    },
  });
};

export async function getExchangeRates(): Promise<ExchangeRates> {
  const response = await client().get<ExchangeRates>("money/exchange-rates", {
    headers: authentication(),
  });
  return response.data;
}

export async function getRedirectMapping(redirectKey: string) {
  const response = await client().get(`redirect/mapping/${redirectKey}`, {
    headers: authentication(),
  });
  return response.data;
}

export async function old_getCurrencyExchangeRates(
  baseCurrency?: string,
): Promise<AxiosResponse<old_ExchangeRates>> {
  return widgetClient().get<old_ExchangeRates>("currency-conversion-rates", {
    params: { baseCurrency },
    headers: authentication(),
  });
}

export const getCarrierLocations = (
  carrier: string,
  params: { zip: string; country?: string },
) => {
  return widgetClient().get(`/carriers/${carrier}/locations`, {
    params,
    headers: authentication(),
  });
};

export type CreateAuthRequest =
  | {
      type: "order";
      email: string;
      orderNumber: string;
      isGift: boolean;
      emailOrZip: string;
    }
  | { type: "orderless" }
  | { type: "email"; token: string }
  | { type: "order_tracking"; token: string }
  | { type: "products"; productIds: string[] };

export interface CreateAuthResponse {
  token: string;
  customer: { email: string; name: string; customerId: string };
  orderID: string;
}

export interface CreateSupportAuthResponse {
  token: string;
}

/**
 * POST /widgets/:id/customer-widget-auth
 */
export const createCustomerWidgetAuth = async (
  customerAuthToken: string,
): Promise<CreateAuthResponse> => {
  const body: Json = { customerAuthToken };
  const response = await widgetClient().post("customer-widget-auth", body);
  return response.data;
};

/**
 * POST /widgets/:id/customer-auth
 */
export const createAuth = async (
  request: CreateAuthRequest,
): Promise<CreateAuthResponse> => {
  let body: Json;
  switch (request.type) {
    case "orderless":
      body = {};
      break;
    case "email":
    case "order_tracking":
      body = { token: request.token };
      break;
    case "order":
      body = {
        email: request.email,
        orderNumber: request.orderNumber,
        isGift: request.isGift,
        emailOrZip: request.emailOrZip,
      };
      break;
    case "products":
      body = { productIds: request.productIds };
  }
  const response = await widgetClient().post("customer-auth", body, {
    params: { type: request.type },
  });
  return response.data;
};

export interface SendAuthEmailRequest {
  email: string;
  url: string;
}

export interface SendAuthEmailResponse {}

/**
 * POST /widgets/:id/customer-auth/email
 */
export const sendAuthEmail = async (
  request: SendAuthEmailRequest,
): Promise<SendAuthEmailResponse> => {
  const response = await widgetClient().post("customer-auth/email", request);
  return response.data;
};

let settingsPromise: Promise<AxiosResponse<any, any>> | null = null;

export async function getSettings(): Promise<AxiosResponse<GetWidgetResponse>> {
  if (!settingsPromise) {
    settingsPromise = widgetClient()
      .get("")
      .catch((e) => {
        settingsPromise = null;
        throw e;
      });
  }
  return settingsPromise;
}

export const getProduct = (id: string, variantId?: string) => {
  return widgetClient().get(`products/${id}`, {
    params: { variantId },
    headers: authentication(),
  });
};

export const getProductTaxes = (params: {
  products: {
    variantId: string;
    price: string;
  }[];
  shippingAddress: ReturnAddress;
  orderId: string;
}) => {
  return widgetClient().post("products/taxes", {
    headers: authentication(),
    params,
  });
};

export const getMessagingConsent = async (
  customerId: string,
): Promise<MessagingConsent> => {
  const res = await widgetClient().get(`customer-consent/${customerId}`, {
    headers: authentication(),
  });
  return res.data;
};

export const updateMessagingConsent = async (
  customerId: string,
  {
    smsMarketingConsent,
    emailMarketingConsent,
    smsTransactionalConsent,
    emailTransactionalConsent,
  }: {
    smsMarketingConsent?: SMSConsent;
    emailMarketingConsent?: EmailConsent;
    emailTransactionalConsent?: EmailConsent;
    smsTransactionalConsent?: SMSConsent;
  },
): Promise<MessagingConsent> => {
  const body = {
    smsMarketingConsent,
    emailMarketingConsent,
    emailTransactionalConsent,
    smsTransactionalConsent,
  };

  const res = await widgetClient().post(`customer-consent/${customerId}`, {
    headers: authentication(),
    body,
  });
  return res.data;
};

export const getSubscriptionUrls = async (
  customerId: string,
): Promise<{ subscribeUrl: string; unsubscribeUrl: string }> => {
  const res = await widgetClient().get(`subscription-urls/${customerId}`, {
    headers: authentication(),
  });
  return res.data;
};

export const getShopifyOrder = (shopifyOrderId: number) => {
  return widgetClient().get(`shopify/order/${shopifyOrderId}`, {
    headers: authentication(),
  });
};

export const getProducts = () => {
  return widgetClient().get("shopify/products", { headers: authentication() });
};

export const getProductsByVariantIds = (variantIds: string[]) => {
  return widgetClient().get(
    `shopify/products?variantIds=${variantIds.join()}`,
    {
      headers: authentication(),
    },
  );
};

export const getProductsByProductMetafield = (
  productIds: string[],
  metafieldKey: string,
) => {
  return widgetClient().get(
    `shopify/products?ids=${productIds.join()}&metafieldKey=${metafieldKey}`,
    {
      headers: authentication(),
    },
  );
};

export const getProductsByTags = (tags: string) => {
  return widgetClient().get(`shopify/products?tags=${tags}`, {
    headers: authentication(),
  });
};

export const getCollectionProducts = (collectionId: number) => {
  return widgetClient().get(`shopify/collections/${collectionId}/products`, {
    headers: authentication(),
  });
};

export const getCollectionProductsGraphql = (collectionId: number) => {
  return widgetClient().get(
    `shopify/collections/${collectionId}/products?graphql=true`,
    {
      headers: authentication(),
    },
  );
};

export const getLocations = (): Promise<{
  data: { locations: LocationData[] };
}> => {
  return widgetClient().get("shopify/locations", { headers: authentication() });
};

export const getRecommendations = (productId: number) => {
  return widgetClient().get(`shopify/recommendations/${productId}`, {
    headers: authentication(),
  });
};

export const searchProducts = (searchQuery: string) => {
  return widgetClient().get(`shopify/search/${searchQuery}`, {
    headers: authentication(),
  });
};

/**
 * PUT /shopify/update-order
 */
export const updateOrder = (params: {
  shopifyOrderId: number;
  data: any;
  lineItemEdits?: {
    lineItemTitle: string;
    lineItemVariantTitle: string;
    quantity: number;
  }[];
}) => {
  return widgetClient().put(`shopify/update-order/${params.shopifyOrderId}`, {
    headers: authentication(),
    params: {
      ...params,
      data: {
        order: {
          ...params.data,
          id: params.shopifyOrderId,
        },
      },
    },
  });
};

export const getShippingFee = (params: object) => {
  return widgetClient().put("returns/fee", {
    headers: authentication(),
    params,
  });
};

export const getAutomations = () => {
  return widgetClient().get("automations");
};

export const uploadImages = (params: object) => {
  return widgetClient().post("upload", params, {
    headers: {
      ...authentication(),
      [String(ContentTypeField.name)]: String(
        MultipartType.subtype("form-data"),
      ),
    },
  });
};

// TODO: share this with the customer-portal-app
(window as any).uploadImages = uploadImages;

export const submitReturn = (params: ReturnRequest) => {
  return widgetClient().post("returns/submit", {
    headers: authentication(),
    params,
  });
};

export const reschedulePickup = (returnId: string, params: object) => {
  return widgetClient().put(`returns/${returnId}/reschedulePickup`, {
    headers: authentication(),
    params,
  });
};

export const cancelPickup = (returnId: string) => {
  return widgetClient().delete(`returns/${returnId}/cancelPickup`, {
    headers: authentication(),
  });
};

export const createCart = (params: object) => {
  return widgetClient().post("cart/create", {
    headers: authentication(),
    params,
  });
};

export const getCart = (params: { storeUrl: string; cartId: string }) => {
  return widgetClient().post("cart/get", { headers: authentication(), params });
};

export async function addItemsToCart(params: {
  storeUrl: string;
  cartId: string;
  lines: {
    variantId: number | string;
    properties?: Record<string, any>;
    quantity?: number;
  }[];
}) {
  return widgetClient().post("cart/add", { headers: authentication(), params });
}

export async function removeItemsFromCart(params: {
  storeUrl: string;
  cartId: string;
  lineIds: string[];
}) {
  return widgetClient().post("cart/remove", {
    headers: authentication(),
    params,
  });
}

export const updateCartItemQuantity = (params: {
  storeUrl: string;
  itemId: string;
  quantity: number;
  cartId: string;
}) => {
  return widgetClient().post("cart/updateQuantity", {
    headers: authentication(),
    params,
  });
};

export const addDiscountCodesToCart = (params: object) => {
  return widgetClient().post("cart/discounts", {
    headers: authentication(),
    params,
  });
};

export const recreateExpiredDiscountCodes = (params: {
  codes: string[];
  storeUrl: string;
}) => {
  return widgetClient().post("recreate-discount-codes", {
    headers: authentication(),
    params,
  });
};

export const getCollectionsForProduct = (productId: string) => {
  return widgetClient().get(`/shopify/products/${productId}/collections`, {
    headers: authentication(),
  });
};

export const completeExchange = (token: string) => {
  return widgetClient().get(`/returns/completeExchange?token=${token}`);
};

export const verifyAddress = async (address: Address) => {
  const response = await widgetClient().post(
    "returns/address/verify",
    address,
    {
      headers: authentication(),
    },
  );
  return response.data;
};

export const getProductsBySkus = (skus: string[]) => {
  return widgetClient().get(`/products?skus=${skus.join(",")}`);
};

export const createAstraUserIntent = (params: {
  date_of_birth: string;
  state: string;
  phone: string;
  first_name: string;
  last_name: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  postal_code: string;
  accept_terms: boolean;
}) => {
  return widgetClient().post("/astra-user-intent", {
    headers: authentication(),
    params,
  });
};

export const addCard = (params: { email: string; phone: string }) => {
  return widgetClient().post("/astra-add-card", {
    headers: authentication(),
    params,
  });
};

export const isInstantRefundAllowedForReturn = (params: {
  country: string;
  isManualReview: boolean;
  isFlagged: boolean;
  orderId: string;
}) => {
  return widgetClient().post("/instant-refund/validation/return", {
    headers: authentication(),
    params,
  });
};

export const isInstantRefundAllowedForCustomer = (params: {
  country: string;
  isManualReview: boolean;
  isFlagged: boolean;
  orderId: string;
  astraUserId: string;
  amount: number;
}) => {
  return widgetClient().post("/instant-refund/validation/customer", {
    headers: authentication(),
    params,
  });
};

export const logPageClick = (params: {
  trackableId: string;
  trackableType: TrackableType;
  eventType: string;
  url: string;
  image?: string;
}) => {
  return widgetClient().post("/order-tracking/page-click", {
    headers: authentication(),
    params,
  });
};

export const logPageView = (params: {
  trackableId: string;
  trackableType: TrackableType;
  images: string[];
}) => {
  return widgetClient().post("/order-tracking/page-view", {
    headers: authentication(),
    params,
  });
};

export const getDeliveryEstimate = (params: {
  orderId: string;
  trackingCode: string | null;
}) => {
  return widgetClient().post("/order-tracking/delivery-estimate", {
    headers: authentication(),
    params,
  });
};

export const getProductEstimate = (params: {
  countryCode: string;
  provinceCode?: string;
  productId?: string;
}) => {
  return widgetClient().post("/order-tracking/product-estimate", {
    headers: authentication(),
    params,
  });
};

export const getItemsViewedAndInCart = (params: {
  team: string;
  email: string;
  date: string;
}) => {
  return widgetClient().post("/order-tracking/items-viewed-and-in-cart", {
    headers: authentication(),
    params,
  });
};

/**
 * GET /widget/:id/customers/top-products-of-interest
 */
export async function getTopProductsOfInterest({
  token,
  signal,
  limit,
}: {
  token: string;
  signal?: AbortSignal;
  limit?: number;
}) {
  const response = await widgetClient().get(
    "/customers/top-products-of-interest",
    {
      headers: authentication(),
      params: {
        token,
        limit,
      },
      signal,
    },
  );
  return response.data;
}

/**
 * GET /widget/:id/customers/viewed-products
 */
export async function getViewedProducts({
  token,
  signal,
  limit,
}: {
  token: string;
  signal?: AbortSignal;
  limit?: number;
}): Promise<ProductInfo[]> {
  const response = await widgetClient().get("/customers/viewed-products", {
    headers: authentication(),
    params: {
      token,
      limit,
    },
    signal,
  });
  return response.data;
}

/**
 * GET /widget/:id/customers/customer-product-list
 */
export async function getCustomerProductList({
  token,
  signal,
}: {
  token: string;
  signal?: AbortSignal;
}): Promise<ICustomerProductList | undefined> {
  const response = await widgetClient().get(
    "/customers/customer-product-list",
    {
      headers: authentication(),
      params: {
        token,
      },
      signal,
    },
  );

  try {
    return customerProductListJsonFormat.read(response.data);
  } catch (error) {
    return undefined;
  }
}

/**
 * GET /widget/:id/customers/customer-product-lists
 */
export async function getCustomerProductLists({
  customerId,
  signal,
}: {
  customerId: string;
  signal?: AbortSignal;
}): Promise<ICustomerProductList[] | undefined> {
  const response = await widgetClient().get(
    "/customers/customer-product-lists",
    {
      headers: authentication(),
      params: {
        customerId,
      },
      signal,
    },
  );

  try {
    return customerProductListArrayJsonFormat.read(response.data);
  } catch (error) {
    return undefined;
  }
}

/**
 * POST /widget/:id/customers/customer-product-list
 */
export async function createCustomerProductList({
  customerId,
  listName,
}: {
  customerId: string;
  listName: string;
}) {
  return await widgetClient().post(
    "/customers/customer-product-list",
    {
      customerId,
      listName,
    },
    { headers: authentication() },
  );
}

/**
 * POST /widget/:id/customers/customer-product-list-name
 */
export async function updateCustomerProductListName({
  listId,
  listName,
}: {
  listId: string;
  listName: string;
  signal?: AbortSignal;
}) {
  return await widgetClient().post(
    "/customers/customer-product-list-name",
    {
      listId,
      listName,
    },
    { headers: authentication() },
  );
}

export interface AddProductToCustomerProductListProductLocator {
  // the listId of the list that the product is currently on
  listId: string;
  productId: string;
  variantId?: string | undefined;
}

/**
 * POST /widget/:id/customers/customer-product-list/add-product-to-lists
 */
export async function addProductToCustomerProductLists({
  productToAdd,
  listIdsToAddTo,
}: {
  productToAdd: AddProductToCustomerProductListProductLocator;
  listIdsToAddTo: string[];
  signal?: AbortSignal;
}) {
  return await widgetClient().post(
    "/customers/customer-product-list/add-product-to-lists",
    {
      productLocator: productToAdd,
      listIdsToAddTo,
    },
    { headers: authentication() },
  );
}

/**
 * POST /widget/:id/customers/customer-product-list/remove-product-from-lists
 */
export async function removeProductFromCustomerProductLists({
  productToRemove,
  listIdsToRemoveFrom,
}: {
  productToRemove: {
    productId: string;
    variantId?: string | undefined;
  };
  listIdsToRemoveFrom: string[];
}) {
  return await widgetClient().post(
    "/customers/customer-product-list/remove-product-from-lists",
    {
      productToRemove,
      listIdsToRemoveFrom,
    },
    { headers: authentication() },
  );
}

/**
 * DELETE /widget/:id/customers/customer-product-list/:listId
 */
export async function deleteCustomerProductList({
  listId,
}: {
  listId: string;
}) {
  return await widgetClient().delete(
    `/customers/customer-product-list/${listId}`,
    { headers: authentication() },
  );
}

/**
 * GET /widget/:id/customers/customer-product-list/:listId/share-url
 */
export async function getListShareUrl({
  customerId,
  listId,
}: {
  customerId: string;
  listId: string;
}): Promise<string> {
  const response = await widgetClient().get(
    `/customers/customer-product-list/${listId}/share-url`,
    {
      headers: authentication(),
      params: {
        customerId,
      },
    },
  );

  return response.data;
}

/**
 * GET /widget/:id/customers/store-credit-transaction-history
 */
export async function getStoreCreditTransactionHistory({
  customerId,
  signal,
}: {
  customerId: string;
  signal?: AbortSignal;
}): Promise<GetStoreCreditTransactionResponseBody> {
  const response = await widgetClient().get(
    "/customers/store-credit-transaction-history",
    {
      headers: authentication(),
      params: {
        customerId,
      },
      signal,
    },
  );

  const parsedResponse = getStoreCreditTransactionsSchema.output.safeParse(
    response.data,
  );

  if (parsedResponse.success) {
    return parsedResponse.data;
  } else {
    throw new Error(
      "Unable to parse getStoreCreditTransactionHistory response",
    );
  }
}

/**
 * GET /widget/:id/customers/store-credit-balance
 */
export interface StoreCreditBalance {
  currency: Currency;
  balance: number;
}
export async function getStoreCreditBalance({
  customerId,
  signal,
}: {
  customerId: string;
  signal?: AbortSignal;
}): Promise<StoreCreditBalance> {
  const response = await widgetClient().get("/customers/store-credit-balance", {
    headers: authentication(),
    params: {
      customerId,
    },
    signal,
  });

  const parsedResponse =
    getStoreCreditBalanceForCustomerSchema.output.safeParse(response.data);

  if (parsedResponse.success) {
    // destructure and assert values are non-null so `strictNullChecks: false`
    // doesn't mark these fields as optional
    return {
      currency: parsedResponse.data.currency!,
      balance: parsedResponse.data.balance!,
    };
  } else {
    throw new Error("Unable to parse response");
  }
}

/**
 * POST /widget/:id/customers/redeem-store-credit
 */
export interface RedeemStoreCreditResponse {
  shopifyGiftCardCode: string;
}
export async function redeemStoreCredit({
  customerId,
  amount,
  currency,
}: {
  customerId: string;
  amount: number;
  currency: Currency;
}) {
  const response = await widgetClient().post(
    "/customers/redeem-store-credit",
    {
      customerId,
      amount,
      currency,
    },
    { headers: authentication() },
  );

  const parsedResponse = redeemStoreCreditSchema.output.safeParse(
    response.data,
  );

  if (parsedResponse.success) {
    // destructure and assert values are non-null so `strictNullChecks: false`
    // doesn't mark these fields as optional
    return {
      shopifyGiftCardCode: parsedResponse.data.shopifyGiftCardCode!,
    };
  } else {
    throw new Error("Failed to redeem store credit");
  }
}

/**
 * GET /widget/:id/customers/ad-block
 */
export async function getCustomerAdBlock() {
  try {
    const response = await widgetClient().get("/customers/ad-block", {
      headers: authentication(),
    });

    // Assuming the backend returns the AdBlock data directly
    // You might need to add validation or transformation here if necessary
    return response.data;
  } catch (error) {
    return null;
  }
}
/**
 * GET /widget/:id/customers/customer-warranties
 */
export async function getCustomerWarranties({
  customerId,
}: {
  customerId: string;
}): Promise<WarrantyRegistrationJson[]> {
  const response = await widgetClient().get("/customers/customer-warranties", {
    headers: authentication(),
    params: {
      customerId,
    },
  });

  return response.data;
}
