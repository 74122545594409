/**
 * @file
 * @todo Move to seperate package
 * @see {@link https://www.rfc-editor.org/rfc/rfc5646 RFC 5646 Tags for Identifying Languages}
 */

/**
 * Language tag
 * @see {@link https://www.rfc-editor.org/rfc/rfc5646#section-2.1 RFC 5646 2.1 Syntax}
 */
export class LanguageTag {
  constructor(
    readonly language: string,
    readonly script: string | undefined,
    readonly region: string | undefined,
  ) {}

  toString() {
    let result = this.language;
    if (this.script !== undefined) {
      result += `-${this.script}`;
    }
    if (this.region !== undefined) {
      result += `-${this.region}`;
    }
    return result;
  }

  static parse(string: string) {
    const parts = string.split("-");
    let i = 0;
    const language = parts[i++];
    let script: string | undefined, region: string | undefined;
    if (parts[i].length === 4) {
      script = parts[i++];
    }
    if (2 <= parts[i].length && parts[i].length <= 3) {
      region = parts[i++];
    }
    return new LanguageTag(language, script, region);
  }
}
