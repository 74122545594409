import { AuthProvider } from "@redotech/redo-customer-portal-app/contexts/auth";
import { CustomerContextProvider } from "@redotech/redo-customer-portal-app/contexts/CustomerContext";
import { FaqContextProvider } from "@redotech/redo-customer-portal-app/contexts/faq";
import { RpcClientContextProvider } from "@redotech/redo-customer-portal-app/contexts/RpcClientContext";
import { SettingsProvider } from "@redotech/redo-customer-portal-app/contexts/settings";
import { UserContextProvider } from "@redotech/redo-customer-portal-app/contexts/user";
import { memo } from "react";
import { Outlet } from "react-router-dom";
import { AdvancedExchangeContextProvider } from "./contexts/AdvancedExchangeContext";
import { BackHandlerContextProvider } from "./contexts/BackHandlerContext";
import { CurrentItemBeingReturnedContextProvider } from "./contexts/CurrentItemBeingReturnedContext";
import { DiscountNameContextProvider } from "./contexts/DiscountNameContext";
import { ExchangeTypeContextProvider } from "./contexts/ExchangeTypeContext";
import { FlowStepContextProvider } from "./contexts/FlowStepContext";
import { InStoreReturnContextProvider } from "./contexts/InStoreReturnContext";
import { ItemsReadyForReturnContextProvider } from "./contexts/ItemsReadyForReturnContext";
import { PickupContextProvider } from "./contexts/PickupContext";
import { SettlementContextProvider } from "./contexts/SettlementContext";
import { ShippingContextProvider } from "./contexts/ShippingContext";
import { StoreCreditContextProvider } from "./contexts/StoreCreditContext";

export const Root = memo(function Root() {
  return (
    <SettingsProvider>
      <AuthProvider>
        <RpcClientContextProvider>
          <BackHandlerContextProvider>
            <UserContextProvider>
              <CustomerContextProvider>
                <FaqContextProvider>
                  <AdvancedExchangeContextProvider>
                    <PickupContextProvider>
                      <InStoreReturnContextProvider>
                        <SettlementContextProvider>
                          <CurrentItemBeingReturnedContextProvider>
                            <ItemsReadyForReturnContextProvider>
                              <DiscountNameContextProvider>
                                <ShippingContextProvider>
                                  <ExchangeTypeContextProvider>
                                    <FlowStepContextProvider>
                                      <StoreCreditContextProvider>
                                        <Outlet />
                                      </StoreCreditContextProvider>
                                    </FlowStepContextProvider>
                                  </ExchangeTypeContextProvider>
                                </ShippingContextProvider>
                              </DiscountNameContextProvider>
                            </ItemsReadyForReturnContextProvider>
                          </CurrentItemBeingReturnedContextProvider>
                        </SettlementContextProvider>
                      </InStoreReturnContextProvider>
                    </PickupContextProvider>
                  </AdvancedExchangeContextProvider>
                </FaqContextProvider>
              </CustomerContextProvider>
            </UserContextProvider>
          </BackHandlerContextProvider>
        </RpcClientContextProvider>
      </AuthProvider>
    </SettingsProvider>
  );
});
