export enum CustomerWalletTransactionTypeEnum {
  DEBIT_STORE_CREDIT = "DEBIT_STORE_CREDIT",
  CREDIT_STORE_CREDIT = "CREDIT_STORE_CREDIT",
}

export type StoreCreditCustomerWalletTransactionType =
  | CustomerWalletTransactionTypeEnum.CREDIT_STORE_CREDIT
  | CustomerWalletTransactionTypeEnum.DEBIT_STORE_CREDIT;
export function isStoreCreditCustomerWalletTransactionType(
  value: string,
): value is StoreCreditCustomerWalletTransactionType {
  return (
    value === CustomerWalletTransactionTypeEnum.CREDIT_STORE_CREDIT ||
    value === CustomerWalletTransactionTypeEnum.DEBIT_STORE_CREDIT
  );
}

export const CUSTOMER_WALLET_TRANSACTION_DISCRIMINATOR_KEY = "transactionType";
