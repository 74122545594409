import { RouteObject } from "react-router-dom";

export const listRoutes: RouteObject[] = [
  {
    lazy: async () => {
      const { Page } = await import("./page");
      return { Component: Page };
    },
    children: [
      {
        path: ":listId",
        lazy: async () => {
          const { SharedListWrapper } = await import("./shared-list");
          return { element: <SharedListWrapper /> };
        },
      },
    ],
  },
];
