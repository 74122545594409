import * as amplitude from "@amplitude/analytics-browser";
import { ScreenType, useScreenType } from "@redotech/react-util/screen";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { CurrencyContext } from "@redotech/redo-web/currency";
import TagIcon from "@redotech/redo-web/icon-old/tag-settlement.svg";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { memo, useContext, useEffect } from "react";
import { SettlementContext } from "../../../contexts/SettlementContext";
import * as review from "../review.module.css";

export const settlementForm = groupInput({
  accept: input<boolean>(),
  refund: input<number>(),
});

export type SettlementForm = InputProvider.Form<typeof settlementForm>;

const BannerUpsale = memo(function BannerUpsale({
  upsell,
}: {
  upsell?: number;
}) {
  const size = useScreenType();
  const { formatCurrency, formatCurrencyFromUSD } = useContext(CurrencyContext);
  const { input } = useContext(SettlementContext);

  useEffect(() => {
    amplitude.logEvent("settlement-available", {
      upsell: upsell,
    });
  }, []);

  return (
    <>
      <div
        className={
          size === ScreenType.MOBILE
            ? review.summaryCardItem
            : review.summaryCardItemHorizontal
        }
      >
        <div className={review.summaryHeader}>Want to hang onto your item?</div>
        <div className={review.summaryText}>
          {`If you would rather hang onto your item, we can still offer you ${formatCurrency(
            upsell,
          )} back. You skip the hassle of shipping it back, and can keep it for yourself or pass it to a friend. Win, win.`}
        </div>
      </div>
      <div className={review.summaryCardItem}>
        <Button
          className={review.pickupButton}
          onClick={() => {
            amplitude.logEvent("settlement-add");
            input.setValue({
              accept: true,
              refund: upsell,
            });
          }}
          theme={ButtonTheme.BRAND}
        >
          Accept offer
        </Button>
      </div>
    </>
  );
});

export const SettlementCard = memo(function SettlementCard({
  upsell,
}: {
  upsell?: number;
  setLoading: (loading: boolean) => void;
}) {
  const { input } = useContext(SettlementContext);

  const { accept, refund } = input.inputs;

  const size = useScreenType();

  if (!accept.value && refund.value) {
    return null;
  }

  return (
    <div
      className={
        size === ScreenType.MOBILE
          ? review.summaryCardPickup
          : review.summaryCardHorizonal
      }
    >
      <div className={review.summaryCardItem}>
        <TagIcon />
      </div>
      {accept.value && refund.value && (
        <div
          className={
            size === ScreenType.MOBILE
              ? review.summaryCardItem
              : review.summaryCardItemHorizontal
          }
        >
          <div className={review.summaryTextBold}>Offer accepted</div>
          <div className={review.summaryText}>
            Feel free to keep the item for yourself, or share it with a friend.
            We hope to see you again!
          </div>
        </div>
      )}
      {!accept.value && <BannerUpsale upsell={upsell} />}
    </div>
  );
});
