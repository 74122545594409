import { JsonFormat, stringJsonFormat } from "@redotech/json/format";

export enum TriggerType {
  // order tracking types
  // DEPRECATED - will be removed soon
  ORDER_CREATED = "order_created",
  ORDER_FULFILLED = "order_fulfilled",
  ORDER_PRE_TRANSIT = "pre_transit",
  ORDER_IN_TRANSIT = "in_transit",
  ORDER_OUT_FOR_DELIVERY = "out_for_delivery",
  ORDER_DELIVERED = "delivered",
  ORDER_PICKUP = "available_for_pickup",
  ORDER_STALLED = "stalled_in_transit",
  ORDER_DELAYED = "delayed",
  ORDER_ARRIVING_EARLY = "arriving_early",
  ORDER_RETURN_TO_SENDER = "return_to_sender", // this name is not confusing at all
  ORDER_DELIVERY_ATTEMPTED = "delivery_attempted",
  ORDER_FAILURE = "failure",
  ORDER_CANCELLED = "cancelled",
  ORDER_ERROR = "error",
  ORDER_FULFILLMENT_STALLED = "stalled_in_fulfillment",

  // return tracking types
  // they are just different enough from ORDER_ to warrant separate values.
  // (no FULLFILLED, no PICKUP, no ARRIVING_EARLY)
  // Expect the design of this system to change dramatically
  // with Email Flows 2.0 (tm) Now With Type Safety *coming soon*
  // RETURN_CREATED = "return_created",
  // RETURN_PRE_TRANSIT = "return_pre_transit",
  // RETURN_IN_TRANSIT = "return_in_transit",
  // RETURN_OUT_FOR_DELIVERY = "return_out_for_delivery",
  // RETURN_DELIVERED = "return_delivered",
  // RETURN_STALLED = "return_stalled_in_transit",
  // RETURN_DELAYED = "return_delayed",
  // RETURN_RETURN_TO_SENDER = "return_return_to_sender",
  // RETURN_DELIVERY_ATTEMPTED = "return_delivery_attempted",
  // RETURN_FAILURE = "return_failure",
  // RETURN_CANCELLED = "return_cancelled",
  // RETURN_ERROR = "return_error",
  // RETURN_FULFILLMENT_STALLED = "return_stalled_in_fulfillment",

  // support types
  CONVERSATION_CREATED = "ticket_created",
  CONVERSATION_MESSAGE_CREATED = "new_customer_message",
  CHAT_CONVERTED_TO_EMAIL = "chat_converted_to_email",
}

export const triggerTypeJsonFormat: JsonFormat<TriggerType> = <
  JsonFormat<TriggerType>
>stringJsonFormat;
