import { useLayoutEffect, useRef, useState } from "react";

export function useMounted() {
  const [mounted, setMounted] = useState(false);
  useLayoutEffect(() => setMounted(true), []);
  return mounted;
}

export function useValue<T>(value: T) {
  const ref = useRef(value);
  return ref.current;
}
