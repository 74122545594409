import { getWidgetId } from "@redotech/redo-customer-portal-app/api";
import { RouteObject } from "react-router-dom";

export const loginRoutes: RouteObject[] = [
  {
    lazy: async () => {
      const { Page } = await import("./page");
      return { Component: Page };
    },
    children: [
      {
        index: true,
        lazy: async () => {
          const { Login } = await import("./login");
          return { element: <Login teamId={getWidgetId()} /> };
        },
      },
    ],
  },
];
