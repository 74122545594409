export interface IProductListItem {
  productId: string;
  productUrl: string;
  productTitle: string;
  imageSrcUrl?: string | undefined;
  variantId: string;
  variantTitle?: string | undefined;
  priceAmount: number;
  priceCurrencyCode: string;
  createdAt: Date;
  updatedAt: Date;
}

export type IProductListItemInput = Omit<
  IProductListItem,
  "createdAt" | "updatedAt"
>;

/**
 * Omit createdAt and updatedAt fields from IProductListItem.
 */
export function getProductListItemInputFromProductListItem(
  productListItem: IProductListItem,
): IProductListItemInput {
  const { createdAt, updatedAt, ...productListItemInput } = productListItem;
  return productListItemInput;
}

export interface ICustomerProductList {
  _id: string;
  team: string;
  email: string;
  listType: CustomerProductListType;
  listName: string;
  items: IProductListItem[];
}

export enum CustomerProductListType {
  DEFAULT = "DEFAULT",
  CUSTOM = "CUSTOM",
}
export const CUSTOMER_PRODUCT_LIST_DEFAULT_NAME = "Saved items";
