import { RouteObject } from "react-router-dom";

export const faqRoutes: RouteObject[] = [
  {
    children: [
      {
        path: "",
        lazy: async () => {
          const { Home } = await import("./home");
          return { element: <Home /> };
        },
      },
      {
        path: "collection/:id",
        lazy: async () => {
          const { Collection } = await import("./collection");
          return { element: <Collection /> };
        },
      },
      {
        path: "article/:id",
        lazy: async () => {
          const { Article } = await import("./article");
          return { element: <Article /> };
        },
      },
      {
        path: "search",
        lazy: async () => {
          const { Search } = await import("./search");
          return { element: <Search /> };
        },
      },
    ],
  },
];
