/**
 * @file
 * @see {@link https://datatracker.ietf.org/doc/html/rfc1341 RFC 1341}
 */

import { asyncIterableMap } from "@redotech/util/async-iterator";
import { Bijection } from "@redotech/util/bijection";
import { latin1Encoding } from "@redotech/util/encoding";
import { nativeRandom, randomHexString } from "@redotech/util/random";
import { stringStreamSplit } from "@redotech/util/string-stream";
import { Attribute, Type } from ".";

/**
 * multipart
 * @see {@link https://www.rfc-editor.org/rfc/rfc2046#section-5.1 RFC 2046 §5.1 Multipart Media Type}
 */
export const MultipartType = new Type("multipart");

export const BoundaryAttribute = Attribute.fromString("boundary");

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc2046#section-5.1.3 RFC 2046 §5.1.3 Mixed Subtype}
 */
export const MultipartMixedType = MultipartType.subtype("mixed");

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc2046#section-5.1.4 RFC 2046 §5.1.4 Alternative Subtype}
 */
export const MultipartAlternativeType = MultipartType.subtype("alternative");

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc2046#section-5.1.5 RFC 2046 §5.1.5 Digest Subtype}
 */
export const MultipartDigestType = MultipartType.subtype("digest");

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc2046#section-5.1.6 RFC 2046 §5.1.6 Parallel Subtype}
 */
export const MultipartParallelType = MultipartType.subtype("parallel");

export type Multipart = Part[];

export interface Part {
  headers: Map<string, string>;
  content: ArrayBuffer;
}

export interface SerializedMultipart {
  boundary: string;
  data: ArrayBuffer;
}

export function parsePart(text: string): Part {
  const headers = new Map<string, string>();
  let index;
  for (index = 0; ; ) {
    const endOfLine = text.indexOf("\r\n", index);
    const line = text.slice(index, endOfLine);
    index = endOfLine + "\r\n".length;
    if (!line) {
      break;
    }
    const colonIndex = line.indexOf(": ");
    const name = line.slice(0, colonIndex);
    const value = line.slice(colonIndex + ": ".length).trim();
    headers.set(name.toLowerCase(), value);
  }
  return { headers, content: latin1Encoding.write(text.slice(index)) };
}

export function serializePart(part: Part): string {
  let result = "";
  for (const [name, value] of part.headers.entries()) {
    result += `${name}: ${value}\r\n`;
  }
  result += "\r\n";
  result += latin1Encoding.read(part.content);
  return result;
}

export const multipartFormat: Bijection<SerializedMultipart, Multipart> = {
  read({ boundary, data }: SerializedMultipart) {
    const partTexts = latin1Encoding.read(data).split(`\r\n--${boundary}`);
    return partTexts.slice(1, -1).map(parsePart);
  },
  write(multipart: Multipart) {
    const boundary = randomMultipartBoundary();
    let result = "";
    for (const part of multipart) {
      result += `\r\n--${boundary}\r\n`;
      result += serializePart(part);
    }
    result += `\r\n--${boundary}--`;
    return { boundary, data: latin1Encoding.write(result) };
  },
};

export async function* parseMultipart(
  boundary: string,
  stream: AsyncIterable<ArrayBuffer>,
): AsyncIterable<Part> {
  let first = true;
  for await (const chunk of stringStreamSplit(
    asyncIterableMap(stream, (item) => latin1Encoding.read(item)),
    `\r\n--${boundary}`,
  )) {
    if (first) {
      first = false;
      continue;
    }
    if (chunk.slice(0, 2) !== "\r\n") {
      break;
    }
    yield parsePart(chunk.slice(2));
  }
}

export async function* serializeMultipart(
  boundary: string,
  stream: AsyncIterable<Part>,
): AsyncIterable<ArrayBuffer> {
  yield latin1Encoding.write(`\r\n--${boundary}`);
  for await (const part of stream) {
    yield latin1Encoding.write(`\r\n${serializePart(part)}\r\n--${boundary}`);
  }
  yield latin1Encoding.write(`--`);
}

export function randomMultipartBoundary() {
  return randomHexString(nativeRandom, 24);
}
