import { useInput } from "@redotech/react-util/form";
import { InputProvider, input } from "@redotech/ui/form";
import { createContext } from "react";

const returnInStore = input<boolean>();

export const InStoreReturnContext =
  createContext<InputProvider.Form<typeof returnInStore>>(undefined);

export const InStoreReturnContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const returnInStoreInput = useInput(returnInStore, false);

  return (
    <InStoreReturnContext.Provider value={returnInStoreInput}>
      {children}
    </InStoreReturnContext.Provider>
  );
};
