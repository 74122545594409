import { Currency } from "@redotech/money/currencies";
import { z } from "zod";
import {
  CUSTOMER_WALLET_TRANSACTION_DISCRIMINATOR_KEY,
  CustomerWalletTransactionTypeEnum,
  isStoreCreditCustomerWalletTransactionType,
} from "./customer-wallet-transaction-definition";

const _CustomerWalletTransactionApiSchema = z.object({
  _id: z.string(),
  walletId: z.string(),
  transactionType: z
    .string()
    .refine((value) => isStoreCreditCustomerWalletTransactionType(value)),
  currency: z.nativeEnum(Currency),
  amount: z.number(),
  createdAt: z.string().transform((dateStr) => new Date(dateStr)),
  updatedAt: z.string().transform((dateStr) => new Date(dateStr)),
});

export const CreditStoreCreditCustomerWalletTransactionApiSchema =
  _CustomerWalletTransactionApiSchema.extend({
    transactionType: z.literal(
      CustomerWalletTransactionTypeEnum.CREDIT_STORE_CREDIT,
    ),
  });

export const DebitStoreCreditCustomerWalletTransactionApiSchema =
  _CustomerWalletTransactionApiSchema.extend({
    transactionType: z.literal(
      CustomerWalletTransactionTypeEnum.DEBIT_STORE_CREDIT,
    ),
    debitedStoreCreditTransactionIds: z.array(z.string()),
    shopifyGiftCardCode: z.string().nullable(),
  });

export const StoreCreditCustomerWalletTransactionApiSchema =
  z.discriminatedUnion(CUSTOMER_WALLET_TRANSACTION_DISCRIMINATOR_KEY, [
    CreditStoreCreditCustomerWalletTransactionApiSchema,
    DebitStoreCreditCustomerWalletTransactionApiSchema,
  ]);
export type StoreCreditCustomerWalletTransaction = z.infer<
  typeof StoreCreditCustomerWalletTransactionApiSchema
>;

export const getStoreCreditTransactionsSchema = {
  input: z.object({
    customerId: z.string(),
  }),
  output: z.object({
    storeCreditTransactionHistory: z.array(
      StoreCreditCustomerWalletTransactionApiSchema,
    ),
  }),
};
export type GetStoreCreditTransactionResponseBody = z.infer<
  typeof getStoreCreditTransactionsSchema.output
>;

export const getStoreCreditBalanceForCustomerSchema = {
  input: z.object({
    customerId: z.string(),
  }),
  output: z.object({
    balance: z.number(),
    currency: z.nativeEnum(Currency),
  }),
};

export const redeemStoreCreditSchema = {
  input: z.object({
    customerId: z.string(),
    amount: z.number(),
    currency: z.nativeEnum(Currency),
  }),
  output: z.object({
    shopifyGiftCardCode: z.string(),
  }),
};
