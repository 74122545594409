import * as amplitude from "@amplitude/analytics-browser";
import { AuthGuard } from "@redotech/redo-customer-portal-app/contexts/auth";
import { Outlet, RouteObject } from "react-router-dom";
import { Page } from "./page";

export const orderRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Home" },
    element: (
      <AuthGuard>
        <Outlet />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        path: "",
        lazy: async () => {
          const { Orders } = await import("./orders");
          return {
            element: (
              <Page>
                <Orders />
              </Page>
            ),
          };
        },
      },
      {
        path: ":id",
        lazy: async () => {
          const { Order } = await import("./order");
          return { Component: Order };
        },
        children: [
          {
            handle: { breadcrumb: "Order Details" },
            path: "",
            lazy: async () => {
              const { OrderDetails } = await import("./order-details");
              return { Component: OrderDetails };
            },
            children: ["track", "track/:fulfillmentIndex"].map((path) => ({
              handle: { breadcrumb: "Tracking" },
              path,
              lazy: async () => {
                const { Fulfillment } = await import(
                  "./order-tracking/fulfillment/fulfillment"
                );
                return { Component: Fulfillment };
              },
            })),
          },
          {
            // TODO (JACK): This route should probably be limited to known return types and not just eat all possible routes
            path: ":returnType",
            lazy: async () => {
              const { ReturnPortal } = await import("./return-portal");
              return { Component: ReturnPortal };
            },
          },
          {
            // TODO (JACK): This route should be included in the :returnType route
            path: "exchange",
            lazy: async () => {
              const { AdvancedExchangeWrapper } = await import(
                "./return-flow/card-contents/advanced-exchange-wrapper"
              );
              return { Component: AdvancedExchangeWrapper };
            },
          },
        ],
      },
      {
        path: "return/:id",
        lazy: async () => {
          const { ReturnStatus } = await import("./return-status");
          amplitude.logEvent("render-return-status", {
            location: "route",
          });
          return {
            element: (
              <Page>
                <ReturnStatus />
              </Page>
            ),
          };
        },
      },
    ],
  },
];
