import { CUSTOMER_WIDGET_ELEMENT_ID } from "@redotech/redo-web/utils/shared-conf";
import { isDevEnvironment } from "./utils";

export class CustomerWidget {
  private script: HTMLScriptElement | null = null;
  private devScriptUrl: string = "http://localhost:3008/widget.js";
  private prodScriptUrl: string = "https://chat-widget.getredo.com/widget.js";
  private widgetId: string;

  constructor(widgetId: string) {
    this.widgetId = widgetId;
  }

  private getScriptSrc() {
    return isDevEnvironment()
      ? `${this.devScriptUrl}?widgetId=${this.widgetId}`
      : `${this.prodScriptUrl}?widgetId=${this.widgetId}`;
  }

  public addScriptToDOM() {
    this.script = document.createElement("script");
    this.script.src = this.getScriptSrc();
    this.script.async = true;
    document.body.appendChild(this.script);
  }

  public removeScriptFromDOM() {
    this.script?.remove();
  }

  public removeWidgetFromDOM() {
    const widget = document.getElementById(CUSTOMER_WIDGET_ELEMENT_ID);
    widget?.remove();
  }
}
