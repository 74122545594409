import type { LineItem, Order, OriginItem } from "./order";
import { ReturnedProductStatus } from "./return";
import type { Settlement } from "./return-flow";
import type { Channel } from "./sales-channels";
import { SalesChannels } from "./sales-channels";
import { DiscountDistributionMethod } from "./team";

export const INSTANT_REFUND_FEE_PERCENTAGE = 0.05;

export type ReturnTotals = {
  productTotals: ProductTotals[];
  totalStoreCredit: number;
  totalRefund: number;
  /**
   * Includes taxes and adjustments
   *
   * totalReturnValue = totalRawReturnValue + totalAdjustmentValue + totalTaxes
   */
  totalReturnValue: number;
  // FIXME This value is off when there are adjustments (totalAdjustmentValue != 0)
  //
  // Example from testing:
  // * item price = 547.47
  // * totalTaxes = 39.69 (0.0725 tax rate)
  // * totalAdjustmentValue = 10,000.00
  // * returnValueTax = 715.65
  // *
  // * totalTaxes + taxes(totalAdjustmentValue) = 39.69 + 0.0725*10000 = 764.69
  // *
  // * 715.65 != 764.69 (diff = 49.04)
  /**
   * Taxes on items being returned and adjustments
   *
   * returnValueTax = totalTaxes + taxes(totalAdjustmentValue)
   * returnValueTax = taxes(totalRawReturnValue + totalAdjustmentValue)
   */
  returnValueTax: number;
  /**
   * Does not include taxes and adjustments
   *
   * totalRawReturnValue = sum(discount(price) of each item being returned)
   */
  totalRawReturnValue: number;
  /**
   * Sum total of all upsells and fees (not including merchant adjustments) on every item being returned.
   * Adjustment value is calculated on the discounted price of each item being refunded, NOT including any tax.
   *
   * totalAdjustmentValue = adjustment(totalRawReturnValue)
   */
  totalAdjustmentValue: number;
  /** Sum total of all merchant adjustments on every item being returned. */
  totalMerchantAdjustment: number;
  /** Does not include Redo discounts */
  totalDiscount: number;
  /**
   * Includes discounts and taxes
   *
   * newOrderValue = rawNewOrderValue + totalNewOrderTaxes (not newOrderTaxes)
   */
  newOrderValue: number;
  /**
   * Includes discounts, but does not include taxes or adjustments
   *
   * rawNewOrderValue = sum(discount(price) of each item on the new order)
   */
  rawNewOrderValue: number;
  newOrderDiscount: number;
  /**
   * Taxes on all items in the new order, including taxes on exchanged items (which we don't charge).
   * Don't use this for charging taxes on advanced exchanges - use newOrderTaxes instead.
   *
   * totalNewOrderTaxes = taxes(rawNewOrderValue))
   */
  totalNewOrderTaxes: number;
  /**
   * Taxes on advanced exchange items in the new order (not the whole new order).
   * Is included in totalNewOrderTaxes.
   *
   * We only charge taxes for advanced exchange items, so to the back-end, these are the only taxes
   * the new order has. But to the front-end, these are only a subset of the taxes.
   * Use totalNewOrderTaxes if you need all taxes, not just for advanced exchange items.
   *
   * newOrderTaxes = taxes(discount(price) of each advanced exchange item)
   */
  newOrderTaxes: number;
  /**
   * Taxes on all items being returned, not including adjustments
   *
   * totalTaxes = taxes(totalRawReturnValue)
   */
  totalTaxes: number;
  /**
   * Does not include taxes. Used for calculating the draft order price.
   * Taxes will be added on to the draft order by Shopify.
   */
  charge: number;
  /**
   * Difference between the values of the return and the new order
   *
   * displayCharge = totalReturnValue - newOrderValue
   */
  displayCharge: number;
  recoveryFee: number;
  shippingFee: number;
  shippingFeeDeducted: number;
  instantRefundFee?: number;
  /**
   * If the merchant has preDiscountPrice enabled, this is the difference between the original price and the discount price.
   * Can be used for "shop now" but doesn't roll over as excess store credit.
   */
  nonAccruableCredit: number;
  /** Accruable credit that was not used to cover the exchange */
  nonAccruableLeftover: number;
  /** Value of final sale items on the return which should be credited to the merchant once we issue refunds, store-credit, or an exchange */
  finalSaleCredit: number;
};

export type ProductTotals = {
  product: Product;
  /** Includes discounts (if any). Does not include tax or price adjustment. */
  discountPrice: number;
  /** Does not include discounts, tax, or price adjustment */
  originalPrice: number;
  /** Includes discounts, tax, price adjustment, and merchant adjustment (if any) */
  finalPrice: number;
  priceAdjustment: number;
  merchantAdjustment: number;
  tax: number;
  /** Does not include Redo discounts */
  totalDiscount: number;
};

export type Product = {
  _id?: string;
  line_item_id: string | number;
  price: string;
  price_adjustment?: string | undefined;
  merchant_adjustment?: string;
  strategy: string;
  status?: string;
  originalVariantOptions?: {
    strategy: string;
    price_adjustment?: string;
  };
  exchangeGroupItem?: unknown;
  isUnbundled?: boolean;
  isNewItem?: boolean;
  isFinalSaleReturn?: boolean;
  /** Optional override */
  tax?: string;
} & (
  | {
      new_item?: object;
    }
  | {
      exchange_for?: object;
    }
);

type DiscountAllocation = {
  discountedAmount: {
    amount: string;
  };
};

type Refund = {
  refund: {
    id: number;
    refund_line_items: {
      line_item_id: number;
      subtotal: number;
      total_tax: number;
      subtotal_set: {
        presentment_money: {
          currency_code: string;
        };
      };
    }[];
  };
};

type Return = {
  refunds?: Refund[];
  products: Product[];
  provision: string;
  stripe?: any;
  totals?: {
    fee: number;
  };
  newOrderPriceAdjustment?: {
    type: "percentage" | "amount";
    value: number;
  };
  new_order_taxes?: string;
  discount_allocations?: DiscountAllocation[];
  advancedExchangeItems: {
    price?: string;
    quantity?: number;
    tax?: string;
    variantId?: string;
  }[];
  shippingFeeToDeduct?: number;
  draftOrderId?: number;
  labelDeductedFromCredit?: boolean;
  originItems?: OriginItem[];
  originalReturnOptions?: {
    advancedExchangeItems?: {
      price?: string;
      quantity?: number;
      tax?: string;
      variantId?: string;
    }[];
    new_order_taxes?: string;
    discount_allocations: DiscountAllocation[];
  };
  usePreDiscountPrice?: boolean;
  settlement?: Settlement;
};

type Totals = {
  amount: number;
  tax: number;
};

export type ReturnType = "refund" | "store_credit" | "exchange";

type Team = {
  settings: {
    deductLabelFromCredit?: boolean;
    deductLabelFromRefund?: boolean;
    discountDistributionMethod?: DiscountDistributionMethod;
    exchanges: {
      usePreDiscountPrice?: boolean;
      excessExchangeValue: Exclude<ReturnType, "exchange">;
    };
  };
};

export class ReturnTotalsCalculator {
  private return_: Return;
  private productTotals: ProductTotals[];
  private orders?: Order[];
  private order: Order;
  private team: Team;
  private isReturnPortal: boolean;
  /**
   * Merchants can edit the return after submission. If enabled, we will calculate
   * the totals for the original return.
   */
  private useOriginalReturn: boolean;
  private isMerchantCreated: boolean;

  constructor({
    return_,
    orders,
    order,
    team,
    useOriginalReturn = false,
    isMerchantCreated = false,
  }: {
    return_: Return;
    orders?: Order[];
    order: Order;
    team: Team;
    useOriginalReturn?: boolean;
    isMerchantCreated?: boolean;
  }) {
    this.return_ = return_;
    this.productTotals = [];
    this.orders = orders;
    this.order = order;
    this.team = team;
    this.useOriginalReturn = useOriginalReturn || false;
    this.calculateTotalsForProducts();
    // If a product has an _id, it means we are not on the return portal
    // (i.e. the return has already been created)
    this.isReturnPortal = !this.return_.products[0]?._id;
    this.isMerchantCreated = isMerchantCreated;
  }

  static getTaxForLineItem(lineItem: LineItem | undefined) {
    let taxValue = 0.0;
    const taxLines = lineItem?.tax_lines || [];
    for (const taxLine of taxLines) {
      taxValue += parseFloat(taxLine.price);
    }
    taxValue /= lineItem?.quantity || 1;

    return Math.round(taxValue * 100) / 100;
  }

  static getTaxForBundledPart(bundleLineItem: any, percentOfBundle: number) {
    return (
      ReturnTotalsCalculator.getTaxForLineItem(bundleLineItem) * percentOfBundle
    );
  }

  getProductOrder(product: Product): Order {
    const order = (this.orders || []).find((order) => {
      return order.shopify.line_items.some(
        (lineItem) =>
          lineItem.id.toString() === product.line_item_id.toString(),
      );
    });

    if (!order) {
      return this.order;
    }

    return order;
  }

  /**
   * Get taxes for a specific product in return
   */
  productTax(product: Product): number {
    const order = this.getProductOrder(product);

    if (order.shopify.taxes_included) {
      return 0;
    }
    if (product.tax) {
      return parseFloat(product.tax);
    }
    const shopifyLineItem = ReturnTotalsCalculator.findShopifyLineItem(
      product.line_item_id,
      order,
    );
    if (product.isUnbundled) {
      return parseFloat(product.tax || "0");
    }
    const originTax = ReturnTotalsCalculator.getOriginItemTax(
      shopifyLineItem,
      order,
    );

    return originTax
      ? parseFloat(originTax)
      : ReturnTotalsCalculator.getTaxForLineItem(shopifyLineItem);
  }

  /**
   * Get taxes for entire return
   */
  returnTax(return_: Return): number {
    let taxValue = 0.0;
    const products = return_.products || [];
    for (const product of products) {
      taxValue += this.productTax(product);
    }
    return parseFloat(taxValue.toFixed(2));
  }

  /**
   * Get the taxes from a array of product totals
   */
  getProductTotalsTaxes(productsToProcess: ProductTotals[]): number {
    let taxValue = 0.0;
    for (const productTotals of productsToProcess) {
      taxValue += this.productTax(productTotals.product);
    }
    return parseFloat(taxValue.toFixed(2));
  }

  private static getSimpleBundleStackID(
    lineItem: LineItem,
    order: Order,
  ): number | null {
    if (!lineItem.discount_allocations) {
      return null;
    }

    let bundleLineItemID = null;
    const re = new RegExp(/^Simple Bundles:/);
    const line_id_regex = new RegExp(/ID: \d+$/);

    for (const allocation of lineItem.discount_allocations) {
      const application =
        order.shopify.discount_applications[
          allocation.discount_application_index
        ];

      if (re.test(application.title)) {
        bundleLineItemID = application?.title
          ?.match(line_id_regex)?.[0]
          .match(/\d+/)?.[0];
      }
    }

    return +bundleLineItemID;
  }

  private static getSimpleBundleItemDiscount(
    lineItem: LineItem,
    order: Order,
    originBundleID: number,
  ): number {
    let bundledItemsTotalPriceValue = 0;
    const itemPrice = +lineItem.price * lineItem.quantity;

    for (const item of order.shopify.line_items) {
      const bundleID = this.getSimpleBundleStackID(item, order);

      if (originBundleID === bundleID) {
        bundledItemsTotalPriceValue += item.price * item.quantity;
      }
    }

    const itemProportion = itemPrice / bundledItemsTotalPriceValue;
    const bundleItem = order.shopify.line_items.find(
      (item) => item.id === originBundleID,
    );
    if (!bundleItem) {
      return 0;
    }

    const bundleDiscounts =
      bundleItem.discount_allocations?.reduce(
        (acc: number, discount: any) => (acc += Number(discount.amount)),
        0,
      ) || 0;
    const bundlePrice =
      Number(bundleItem.price) * Number(bundleItem.quantity) - bundleDiscounts;

    const proportionalPrice = bundlePrice * itemProportion;
    const finalDiscount = itemPrice - proportionalPrice;

    return finalDiscount;
  }

  private static isLineItemCanceled(lineItem: LineItem) {
    return (
      lineItem.fulfillment_status === null &&
      lineItem.fulfillable_quantity === 0
    );
  }

  /**
   * Get the discount for a specific line item. This may not be the same as the discount
   * that was applied to the line item in the original order, depending on the
   * distributionMethod parameter.
   * @param lineItem
   * @param order
   * @param distributionMethod
   * @returns
   */
  private static getDiscountAllocations(
    lineItem: LineItem | undefined,
    order: Order,
    distributionMethod: DiscountDistributionMethod = DiscountDistributionMethod.LINE_ITEM,
  ) {
    if (!lineItem) {
      return 0;
    }

    const discountAllocations = [...lineItem.discount_allocations];

    // Check to see if item is part of a 'simple bundle'
    // If so calculate its discount
    const originBundleID = this.getSimpleBundleStackID(lineItem, order);
    if (originBundleID) {
      return this.getSimpleBundleItemDiscount(lineItem, order, originBundleID);
    }

    for (let i = 0; i < order.shopify.line_items.length; i++) {
      const item = order.shopify.line_items[i];
      if (this.isLineItemCanceled(item)) {
        continue;
      }
      for (let i = 0; i < item.discount_allocations.length; i++) {
        const discount = item.discount_allocations[i];
        if (
          !this.isRedoItem(item) &&
          discountAllocations.find(
            (allocation: any) =>
              allocation.discount_application_index ===
              discount.discount_application_index,
          ) === undefined &&
          (distributionMethod === DiscountDistributionMethod.ORDER ||
            discount.discount_application_index == undefined) &&
          ["entitled", "explicit"].includes(
            order.shopify.discount_applications[
              discount.discount_application_index
            ].target_selection,
          )
        ) {
          discountAllocations.push(discount);
        }
      }
    }

    if (!discountAllocations?.length) {
      return 0;
    }

    let discountAmount = 0;
    discountAllocations.forEach((discount: any) => {
      // Ignore redo discounts as we want the return value to include
      // discounts from previous returns (i.e. customers should be
      // able to return items that were previously returned)

      // Discounts are distributed differently according to team settings
      let totalAmountDiscountedFromOrder = 0;
      let totalValueOfDiscountedItems = 0;

      if (distributionMethod === DiscountDistributionMethod.LINE_ITEM) {
        totalAmountDiscountedFromOrder = +discount.amount;
        totalValueOfDiscountedItems = +lineItem?.price * lineItem.quantity;
      } else {
        const acrossOrder =
          distributionMethod === DiscountDistributionMethod.ORDER;
        for (let i = 0; i < order.shopify.line_items.length; i++) {
          const item = order.shopify.line_items[i];
          if (this.isLineItemCanceled(item)) {
            continue;
          }
          if (!this.isRedoItem(item)) {
            totalValueOfDiscountedItems += acrossOrder
              ? +item.price * item.quantity
              : 0;
            for (let i = 0; i < item.discount_allocations.length; i++) {
              if (
                item.discount_allocations[i].discount_application_index ===
                discount.discount_application_index
              ) {
                totalAmountDiscountedFromOrder +=
                  +item.discount_allocations[i].amount;
                totalValueOfDiscountedItems += acrossOrder
                  ? 0
                  : +item.price * item.quantity;
              }
            }
          }
        }
      }

      const discount_code =
        order.shopify.discount_applications[discount.discount_application_index]
          ?.code;
      if (!discount_code?.includes("REDO") && totalValueOfDiscountedItems > 0) {
        discountAmount +=
          totalAmountDiscountedFromOrder *
          ((+lineItem?.price * lineItem.quantity) /
            totalValueOfDiscountedItems);
      }
    });

    return discountAmount;
  }

  static isRedoItem(lineItem: LineItem | undefined) {
    if (
      /^redo$|^re:do$/i.test(lineItem?.vendor) || // milwaukeemotorcycleclothing changed the vendor :/
      /route-/i.test(lineItem?.sku || "") ||
      /routeins/i.test(lineItem?.sku || "") ||
      /insurance|package\s+protection|order\s+protection/i.test(lineItem?.name)
    ) {
      return true;
    }
    return false;
  }

  static getTaxRateForLineItem(lineItem: LineItem) {
    return (lineItem?.tax_lines || []).reduce((total, taxLine) => {
      return total + (taxLine?.rate ?? 0);
    }, 0);
  }

  /** Goes through all products, adds up the price and the discount price and returns the percentage */
  static getTotalDiscountPercentage(
    products: { line_item_id: string }[],
    order: Order,
  ) {
    let totalOrderPrice = 0;
    let totalOrderDiscountPrice = 0;
    const lineItems = products.map((product) => {
      return ReturnTotalsCalculator.findShopifyLineItem(
        product.line_item_id,
        order,
      );
    });

    lineItems.forEach((lineItem) => {
      totalOrderPrice += parseFloat(lineItem?.price);
      totalOrderDiscountPrice += parseFloat(
        ReturnTotalsCalculator.getDiscountPrice(lineItem, order),
      );
    });

    return (totalOrderPrice - totalOrderDiscountPrice) / totalOrderPrice || 0;
  }

  private static getOriginItem(
    lineItem: LineItem | undefined,
    order: Order | undefined,
  ) {
    let originItem: {
      unitPrice?: string;
      preDiscountPrice?: string;
      tax?: string;
    } | null = null;

    if (!order?.isExchangeOrder) {
      return null;
    }

    originItem =
      order?.originItems?.find((originItem) => {
        return (
          originItem.newVariantId === lineItem?.variant_id &&
          originItem.applicableShopifyOrder === order?.shopify.id
        );
      }) ||
      order?.originItems?.find(
        (originItem) => originItem.newVariantId === lineItem?.variant_id,
      ) ||
      null;
    if (!originItem) {
      const originItemJson = lineItem?.properties?.find(
        ({ name }: { name: string }) => name === "_redo_origin_item",
      );
      if (originItemJson) {
        originItem = JSON.parse(originItemJson.value);
      }
    }
    return originItem;
  }

  static getOriginItemPrice(
    lineItem: LineItem | undefined,
    order: Order | undefined,
  ) {
    const originItem = ReturnTotalsCalculator.getOriginItem(lineItem, order);

    // We had a bug were the pre discount price wasn't being set on origin items
    if (
      !originItem?.preDiscountPrice ||
      parseInt(originItem.preDiscountPrice) <
        parseInt(originItem.unitPrice || "0")
    ) {
      return originItem?.unitPrice;
    }
    return originItem?.preDiscountPrice;
  }

  static getOriginItemDiscountPrice(
    lineItem: LineItem | undefined,
    order: Order | undefined,
  ) {
    const originItem = ReturnTotalsCalculator.getOriginItem(lineItem, order);

    return originItem?.unitPrice;
  }

  static getOriginItemTax(
    lineItem: LineItem | undefined,
    order: Order | undefined,
  ) {
    const originItem = ReturnTotalsCalculator.getOriginItem(lineItem, order);

    return originItem?.tax;
  }

  static getDiscountPrice(
    lineItem: LineItem | undefined,
    order: Order,
    distributionMethod?: DiscountDistributionMethod,
    product?: any,
  ) {
    if (lineItem && product?.isUnbundled) {
      const originPrice = ReturnTotalsCalculator.getOriginItemDiscountPrice(
        lineItem,
        order,
      );
      if (originPrice) {
        return (Number(originPrice) * product.percentOfBundle ?? 1).toFixed(2);
      }

      const totalLineItemDiscount =
        ReturnTotalsCalculator.getDiscountAllocations(
          lineItem,
          order,
          distributionMethod,
        ) / (lineItem.originalQuantity || lineItem.quantity);
      const percentOfBundle =
        parseFloat(product.price) / parseFloat(lineItem.price);

      const proportionalPrice = product.percentOfBundle
        ? parseFloat(lineItem.price) * product.percentOfBundle
        : parseFloat(product.price);

      return (
        proportionalPrice -
        totalLineItemDiscount * (product.percentOfBundle || percentOfBundle)
      ).toFixed(2);
    }
    const originPrice = ReturnTotalsCalculator.getOriginItemDiscountPrice(
      lineItem,
      order,
    );
    if (originPrice) {
      return originPrice;
    }

    const discountAmount = ReturnTotalsCalculator.getDiscountAllocations(
      lineItem,
      order,
      distributionMethod,
    );

    return (
      (lineItem?.price || 0) -
      discountAmount / (lineItem?.quantity || 1)
    ).toFixed(2);
  }

  /**
   * Calculate totals for each product, including discount price, price adjustments, and tax
   */
  private calculateTotalsForProducts() {
    for (const product of this.return_.products) {
      this.productTotals.push(this.calculateTotalsForProduct(product));
    }
  }

  /**
   * Get the refund totals for a product
   */
  private getProductRefundTotals(product: Product) {
    let product_subtotal = 0;
    let tax_subtotal = 0;
    let refund_currency = "";
    for (const refund of this.return_.refunds || []) {
      if (!refund.refund) {
        continue;
      }
      for (const refundLineItem of refund.refund.refund_line_items) {
        if (refundLineItem.line_item_id === Number(product.line_item_id)) {
          product_subtotal += refundLineItem.subtotal;
          tax_subtotal += refundLineItem.total_tax;
          refund_currency =
            refundLineItem.subtotal_set.presentment_money.currency_code;
        }
      }
    }
    return {
      refund_item: product_subtotal,
      refund_tax: tax_subtotal,
      total_refund: product_subtotal + tax_subtotal,
      refund_currency,
    };
  }

  /**
   * Calculate totals for a specific product, including discount price, price adjustments, and tax
   */
  calculateTotalsForProduct(product: Product) {
    const order = this.getProductOrder(product);

    const shopifyLineItem = ReturnTotalsCalculator.findShopifyLineItem(
      product.line_item_id,
      order,
    );

    const tax = this.productTax(product);
    const discountPrice = product.isUnbundled
      ? parseFloat(product.price)
      : parseFloat(
          ReturnTotalsCalculator.getDiscountPrice(
            shopifyLineItem,
            order,
            this.team.settings.discountDistributionMethod,
          ),
        );

    const originPrice = ReturnTotalsCalculator.getOriginItemPrice(
      shopifyLineItem,
      order,
    );
    const originalPrice = originPrice
      ? parseFloat(originPrice)
      : product.isUnbundled
        ? parseFloat(product.price)
        : parseFloat(shopifyLineItem?.price);

    const { refund_item, refund_tax, total_refund, refund_currency } =
      this.getProductRefundTotals(product);

    return {
      product,
      discountPrice,
      originalPrice,
      priceAdjustment: parseFloat(
        this.getProductPriceAdjustment(product) || "0",
      ),
      merchantAdjustment: parseFloat(
        this.getProductMerchantAdjustment(product),
      ),
      finalPrice:
        discountPrice +
        tax +
        parseFloat(this.getProductPriceAdjustment(product) || "0") +
        parseFloat(this.getProductMerchantAdjustment(product)),
      tax,
      totalDiscount:
        ReturnTotalsCalculator.getDiscountAllocations(
          shopifyLineItem,
          order,
          this.team.settings.discountDistributionMethod,
        ) / (shopifyLineItem?.quantity || 1),
      refund_item,
      refund_tax,
      total_refund,
      refund_currency,
    };
  }

  /**
   * Calculate totals for all products of return
   */
  getTotalsForNonRejectedProducts(instantRefund: boolean = false) {
    return this.getTotalsForProducts(
      this.return_.products.filter(
        (product) => product.status !== ReturnedProductStatus.REJECTED,
      ),
      instantRefund,
    );
  }

  /**
   * Calculate totals for all products of return
   */
  getTotalsForAllProducts(instantRefund: boolean = false) {
    return this.getTotalsForProducts(this.return_.products, instantRefund);
  }

  /**
   * Calculate totals for specific products
   */
  getTotalsForProducts(
    products: any[],
    instantRefund: boolean = false,
    nonZeroValueExchange: boolean = false,
  ) {
    // This accommodates calculating totals for products both before and after the return has been
    // created. If the return has been created, we really on the _id of the product. If not,
    // We rely on the line_item_id and quantities
    const productsToProcess: ProductTotals[] = [];
    this.productTotals.forEach((productTotals) => {
      // Ensure there aren't more productTotals with the same line item than are in lineItemIds
      const quantityRequested = products.filter(
        (product) =>
          product.line_item_id === productTotals.product.line_item_id,
      ).length;
      const quantityAdded = productsToProcess.filter(
        (product) =>
          product.product.line_item_id === productTotals.product.line_item_id,
      ).length;
      if (
        quantityAdded >= quantityRequested &&
        !products.some((product) => product._id !== null)
      ) {
        return;
      }
      if (
        products.some(
          (product) =>
            product._id === productTotals.product._id ||
            (!product._id &&
              product.line_item_id === productTotals.product.line_item_id),
        )
      ) {
        productsToProcess.push(productTotals);
      }
    });

    const storeCreditTotals = this.getStoreCreditAmount(productsToProcess);
    const refundTotals = this.getRefundAmount(productsToProcess);
    const finalSaleCredit = this.getFinalSaleCredit(productsToProcess);
    const rawNewOrderValueTotals = this.getRawNewOrderValue(productsToProcess);
    const newOrderValueTotals = this.getTotalNewOrderValue(productsToProcess);
    const newOrderValue = newOrderValueTotals.amount + newOrderValueTotals.tax;

    const chargeTotals = {
      amount: newOrderValueTotals.amount,
      tax: newOrderValueTotals.tax,
    };

    // If there is new order value (or new order value is 0 but there is negative
    //  store credit due to fees), apply any existing store credit
    if (chargeTotals.amount || storeCreditTotals.amount < 0) {
      this.applyCreditToCharge(chargeTotals, storeCreditTotals);
    }

    // If there is new order value (or new order value is 0 but there is negative
    //  refund due to fees), apply any existing store credit
    if (chargeTotals.amount || refundTotals.amount < 0) {
      this.applyCreditToCharge(chargeTotals, refundTotals);
    }

    // Don't issue refund or store credit for a non-zero value exchange
    if (nonZeroValueExchange) {
      storeCreditTotals.amount = 0;
      storeCreditTotals.tax = 0;

      refundTotals.amount = 0;
      refundTotals.tax = 0;
    }

    // For merchants like Wild Oak who use pre-discount prices
    // E.g. original price = $100, discount price = $60
    // The difference ($40) can be applied for shop now but cannot rollover as additional store credit
    const nonAccruableCredit = this.getNonAccruableCredit(productsToProcess);
    let nonAccruableLeftover = nonAccruableCredit.amount - chargeTotals.amount;
    if (nonAccruableLeftover < 0) {
      nonAccruableLeftover = 0;
    }
    chargeTotals.amount -= nonAccruableCredit.amount;
    chargeTotals.tax -= nonAccruableCredit.tax;
    if (chargeTotals.amount < 0) {
      chargeTotals.amount = 0;
    }
    if (chargeTotals.tax < 0) {
      chargeTotals.tax = 0;
    }

    const returnValueTotals = this.getTotalReturnValue(productsToProcess);
    const rawReturnValueTotals = this.getRawReturnValue(productsToProcess);
    const totalAdjustmentValue =
      this.getTotalAdjustmentValue(productsToProcess);
    const totalMerchantAdjustment =
      this.getTotalMerchantAdjustment(productsToProcess);
    const totalTaxes = this.getProductTotalsTaxes(productsToProcess);

    const totalReturnValue = returnValueTotals.amount + returnValueTotals.tax;
    let totalStoreCredit = storeCreditTotals.amount + storeCreditTotals.tax;
    let totalRefund = refundTotals.amount + refundTotals.tax;
    let totalFinalSaleCredit = finalSaleCredit.amount + finalSaleCredit.tax;
    let displayCharge =
      newOrderValue -
      (totalReturnValue + nonAccruableCredit.amount + nonAccruableCredit.tax);
    if (displayCharge < 0) {
      displayCharge = 0;
    }

    if (this.return_.settlement?.accepted) {
      // Customer settled for a lower refund, so reduce total refund by the settlement
      totalRefund = totalReturnValue * this.return_.settlement.refund;
      totalFinalSaleCredit =
        finalSaleCredit.amount * this.return_.settlement.refund;
    }

    let shippingFee =
      this.return_.shippingFeeToDeduct || this.return_.totals?.fee || 0;
    const salesChannelIdsToExclude = ["facebook"];
    const channelsToExclude = SalesChannels.filter((channel: Channel) =>
      salesChannelIdsToExclude.includes(channel.id),
    );
    let exclude = false;
    channelsToExclude.forEach((channel: Channel) => {
      channel.sourceNames.forEach((sourceName: string) => {
        if (
          (this?.orders || []).some((order) =>
            order.shopify.source_name?.startsWith(sourceName),
          ) ||
          this.order.shopify.source_name?.startsWith(sourceName)
        ) {
          exclude = true;
        }
      });
    });
    // If we are not on the return portal (e.g. we're on the merchant dashboard), we should only deduct
    // the shipping fee if it was deducted in the return portal (i.e. labelDeductedFromCredit = true)
    if (this.return_.labelDeductedFromCredit || this.isReturnPortal) {
      // Deduct shipping fee from final sale merchant credit if it would be deducted from store credit or refund
      if (
        !exclude &&
        shippingFee > 0 &&
        ((totalStoreCredit > 0 && this.team.settings.deductLabelFromCredit) ||
          (totalRefund > 0 && this.team.settings.deductLabelFromRefund))
      ) {
        if (totalFinalSaleCredit >= shippingFee) {
          totalFinalSaleCredit = totalFinalSaleCredit - shippingFee;
        }
      }

      if (
        !exclude &&
        totalRefund > 0 &&
        shippingFee > 0 &&
        this.team.settings.deductLabelFromRefund
      ) {
        if (totalRefund >= shippingFee) {
          // Deduct the shipping cost from refund value, but only when it can cover the whole cost
          totalRefund = totalRefund - shippingFee;
          shippingFee = 0;
        }
      }

      if (
        this.team.settings.deductLabelFromCredit &&
        totalStoreCredit > 0 &&
        shippingFee > 0
      ) {
        if (totalStoreCredit > shippingFee) {
          // Deduct the shipping cost from store credit, but only when it can cover the whole cost
          totalStoreCredit = totalStoreCredit - shippingFee;
          shippingFee = 0;
        }
      }

      if (this.return_.shippingFeeToDeduct && !exclude) {
        // The shipping cost needs to be reducted from the refund and/or store credit value,
        // but the return is being partially processed, so deduct some now and the rest later
        shippingFee -= totalRefund;
        totalRefund = shippingFee * -1;
        if (totalRefund < 0) {
          totalRefund = 0;
        }

        if (shippingFee > 0) {
          shippingFee -= totalStoreCredit;
          totalStoreCredit = shippingFee * -1;
          if (totalStoreCredit < 0) {
            totalStoreCredit = 0;
          }
        }

        if (shippingFee < 0) {
          shippingFee = 0;
        }
      }
    }

    let recoveryFee = 0;
    if (this.return_.provision === "instant") {
      if (this.return_.stripe) {
        recoveryFee = this.getRecoveryFee(
          productsToProcess,
          displayCharge,
          totalReturnValue,
          newOrderValue,
        );
      }
      // Rare case where there is an instant exchange and the new order value is more than the return value
      // If there are positive merchant adjustments, we need to add them to the store credit/refund
      // amounts instead of deducting them from the charge, since the draft order was already created
      // and it is no longer possible to change the price
      if (this.return_.draftOrderId) {
        chargeTotals.amount = 0;

        if (
          newOrderValue >
          rawReturnValueTotals.amount + rawReturnValueTotals.tax
        ) {
          const storeCreditMerchantAdjustment = this.productTotals
            .filter(
              (product) =>
                this.getProductStrategy(product.product) === "store_credit",
            )
            .reduce((total, product) => {
              return total + product.merchantAdjustment;
            }, 0);

          const refundMerchantAdjustment = this.productTotals
            .filter(
              (product) =>
                this.getProductStrategy(product.product) === "refund",
            )
            .reduce((total, product) => {
              return total + product.merchantAdjustment;
            }, 0);

          totalStoreCredit = storeCreditMerchantAdjustment;
          if (totalStoreCredit < 0) {
            totalStoreCredit = 0;
          }

          totalRefund = refundMerchantAdjustment;
          if (totalRefund < 0) {
            totalRefund = 0;
          }

          totalFinalSaleCredit = this.productTotals
            .filter((product) => product.product.isFinalSaleReturn)
            .reduce((total, product) => {
              return total + product.merchantAdjustment;
            }, 0);
        }
      }
    }

    const newOrderDiscount = this.getReturnDiscountAllocations()
      ? this.getTotalNewOrderDiscountAllocations()
      : 0;
    const newOrderTaxes = parseFloat(this.getReturnNewOrderTaxes() || "0");

    const totalDiscount = this.getTotalDiscount(productsToProcess);

    let instantRefundFee = undefined;
    if (instantRefund) {
      instantRefundFee = totalRefund * INSTANT_REFUND_FEE_PERCENTAGE;
      totalRefund -= instantRefundFee;
      totalRefund = Math.max(totalRefund, 0);
      totalFinalSaleCredit -= instantRefundFee;
    }

    const totals: ReturnTotals = {
      productTotals: this.productTotals,
      totalStoreCredit,
      totalRefund,
      totalReturnValue,
      returnValueTax: returnValueTotals.tax,
      totalRawReturnValue: rawReturnValueTotals.amount,
      totalAdjustmentValue,
      totalMerchantAdjustment,
      totalDiscount,
      newOrderValue,
      rawNewOrderValue: rawNewOrderValueTotals.amount,
      newOrderDiscount,
      totalNewOrderTaxes: newOrderValueTotals.tax,
      newOrderTaxes,
      totalTaxes,
      charge: chargeTotals.amount,
      displayCharge,
      recoveryFee: recoveryFee,
      shippingFee: shippingFee,
      instantRefundFee,
      shippingFeeDeducted: (this.return_.totals?.fee || 0) - shippingFee,
      nonAccruableCredit: nonAccruableCredit.amount,
      nonAccruableLeftover,
      finalSaleCredit: Math.max(totalFinalSaleCredit, 0),
    };
    totals.productTotals = totals.productTotals.map((product) => {
      return {
        ...product,
        product: {
          _id: product.product._id || "",
          line_item_id: product.product.line_item_id,
          price: product.product.price,
          price_adjustment: product.product.price_adjustment,
          merchant_adjustment: product.product.merchant_adjustment || "",
          strategy: product.product.strategy,
          exchangeGroupItem: product.product.exchangeGroupItem || undefined,
        },
      };
    });
    return totals;
  }

  private applyCreditToCharge(chargeTotals: Totals, creditTotals: Totals) {
    const totalCharge = chargeTotals.amount + chargeTotals.tax;
    const totalCredit = creditTotals.amount + creditTotals.tax;

    if (totalCharge > totalCredit) {
      chargeTotals.amount -= creditTotals.amount;
      chargeTotals.tax -= creditTotals.tax;

      creditTotals.amount = 0;
      creditTotals.tax = 0;
    } else {
      creditTotals.amount -= chargeTotals.amount;
      creditTotals.tax -= chargeTotals.tax;

      chargeTotals.amount = 0;
      chargeTotals.tax = 0;
    }

    if (creditTotals.amount < 0 || creditTotals.tax < 0) {
      creditTotals.amount += creditTotals.tax;
      creditTotals.tax = 0;
    }

    if (chargeTotals.amount < 0 || chargeTotals.tax < 0) {
      chargeTotals.amount += chargeTotals.tax;
      chargeTotals.tax = 0;
    }
  }

  /**
   * Get the total price of all items being returned for a specific return type (credit, refund, or exchange)
   *
   * Note: the merchant adjustment (and price adjustment) is split between the product price and the product tax.
   *  Example: productPrice = $10, tax = $0.72, merchantAdjustment = -$5
   *  merchantAdjustmentPercentage = ($10 + $0.72 - $5) / ($10 + $0.72) = 0.534
   *  productPrice = $10 * 0.534 = $5.34
   *  tax = $0.72 * 0.534 = $0.38
   *  total = $5.34 + $0.38 = $5.72 (which is the same as $10 + $0.72 - $5)
   *
   * This is important when applying exchange discounts to draft orders, we apply it without taxes since
   *  taxes are calculated after discounts.
   */
  private getTotalValueForType(
    strategy: "refund" | "store_credit",
    productsToProcess: ProductTotals[],
    includeAdjustments = true,
  ) {
    let amount = 0;
    let tax = 0;
    for (const productTotals of productsToProcess) {
      if (productTotals.product.isNewItem) continue;
      if (this.getProductStrategy(productTotals.product) === strategy) {
        if (includeAdjustments) {
          const adjustments =
            productTotals.priceAdjustment + productTotals.merchantAdjustment;
          const productPrice = productTotals.discountPrice + productTotals.tax;
          if (productPrice === 0) {
            amount += adjustments;
          } else {
            const adjustmentPercentage =
              (productPrice + adjustments) / productPrice;
            amount += productTotals.discountPrice * adjustmentPercentage;
            tax += productTotals.tax * adjustmentPercentage;
          }
        } else {
          amount += productTotals.discountPrice;
          tax += productTotals.tax;
        }
      }
    }

    return { amount, tax };
  }

  getTotalAdjustmentValue(productsToProcess: ProductTotals[]) {
    return productsToProcess.reduce((total, product) => {
      return total + product.priceAdjustment;
    }, 0.0);
  }

  getTotalMerchantAdjustment(productsToProcess: ProductTotals[]) {
    let totalAdjustment = productsToProcess.reduce((total, product) => {
      return total + product.merchantAdjustment;
    }, 0.0);
    const newOrderPriceAdjustment = this.return_.newOrderPriceAdjustment;
    if (newOrderPriceAdjustment) {
      if (newOrderPriceAdjustment.type === "amount") {
        totalAdjustment += newOrderPriceAdjustment.value || 0;
      } else if (newOrderPriceAdjustment.type === "percentage") {
        const rawNewOrderValue = this.getRawNewOrderValue(productsToProcess);
        totalAdjustment +=
          (newOrderPriceAdjustment.value / 100) *
          (rawNewOrderValue.amount + rawNewOrderValue.tax);
      }
    }
    return totalAdjustment;
  }

  getTotalDiscount(productTotals: ProductTotals[]) {
    return productTotals.reduce((total, product) => {
      return total + product.totalDiscount;
    }, 0);
  }

  /**
   * Total price of all items being returned for store credit
   */
  getStoreCreditAmount(
    productsToProcess: ProductTotals[],
    includeAdjustments = true,
  ) {
    return this.getTotalValueForType(
      "store_credit",
      productsToProcess,
      includeAdjustments,
    );
  }

  /**
   * Total price of all items being returned for a refund
   */
  getRefundAmount(
    productsToProcess: ProductTotals[],
    includeAdjustments = true,
  ) {
    return this.getTotalValueForType(
      "refund",
      productsToProcess,
      includeAdjustments,
    );
  }

  /**
   * Total price of all items being returned for a variant exchange
   */
  getVariantExchangeValue(
    productsToProcess: ProductTotals[],
    includeAdjustments = true,
  ) {
    const variantExchangeProducts = productsToProcess.filter((productTotals) =>
      this.isVariantExchange(productTotals.product),
    );
    const storeCreditType = this.getTotalValueForType(
      "store_credit",
      variantExchangeProducts,
      includeAdjustments,
    );
    const refundType = this.getTotalValueForType(
      "refund",
      variantExchangeProducts,
      includeAdjustments,
    );

    return {
      amount: storeCreditType.amount + refundType.amount,
      tax: storeCreditType.tax + refundType.tax,
    };
  }

  /**
   * Total price of all new exchange items as part of the advanced exchange
   */
  getAdvancedExchangeItemsFee() {
    let exchangeFee = 0;
    for (const advancedExchangeItem of this.getReturnAdvancedExchangeItems()) {
      exchangeFee +=
        parseFloat(advancedExchangeItem.price || "0") *
        (advancedExchangeItem.quantity || 0);
    }
    exchangeFee -= this.getTotalNewOrderDiscountAllocations();

    return {
      amount: exchangeFee,
      tax: parseFloat(this.getReturnNewOrderTaxes() || "0"),
    };
  }

  /**
   * Total price of all items being returned
   */
  getTotalReturnValue(productsToProcess: ProductTotals[]) {
    if (this.isMerchantCreated) {
      let amount = 0;
      let tax = 0;
      for (const productTotals of productsToProcess) {
        if (!productTotals.product.isNewItem) {
          const adjustments =
            productTotals.priceAdjustment + productTotals.merchantAdjustment;
          const productPrice = productTotals.discountPrice + productTotals.tax;
          if (productPrice === 0) {
            amount += adjustments;
          } else {
            const adjustmentPercentage =
              (productPrice + adjustments) / productPrice;
            amount += productTotals.discountPrice * adjustmentPercentage;
            tax += productTotals.tax * adjustmentPercentage;
          }
        }
      }

      return { amount, tax };
    } else {
      const storeCredit = this.getStoreCreditAmount(productsToProcess);
      const refund = this.getRefundAmount(productsToProcess);
      return {
        tax: storeCredit.tax + refund.tax,
        amount: storeCredit.amount + refund.amount,
      };
    }
  }

  /**
   * Total price of all items being returned not including adjustments
   */
  getRawReturnValue(productsToProcess: ProductTotals[]) {
    const storeCredit = this.getStoreCreditAmount(productsToProcess, false);
    const refund = this.getRefundAmount(productsToProcess, false);
    return {
      tax: storeCredit.tax + refund.tax,
      amount: storeCredit.amount + refund.amount,
    };
  }

  /**
   * Total price of all items in the new order (including variant and advanced exchange items)
   */
  getTotalNewOrderValue(productsToProcess: ProductTotals[]) {
    const variantExchangeValue = this.getVariantExchangeValue(
      productsToProcess,
      false,
    );
    const advancedExchangeItemsFee = this.getAdvancedExchangeItemsFee();
    let totalTax = variantExchangeValue.tax + advancedExchangeItemsFee.tax;
    let totalAmount =
      variantExchangeValue.amount + advancedExchangeItemsFee.amount;
    const newOrderPriceAdjustment = this.return_.newOrderPriceAdjustment;
    if (newOrderPriceAdjustment) {
      if (newOrderPriceAdjustment.type === "amount") {
        if (totalAmount === 0) {
          totalAmount += newOrderPriceAdjustment.value;
        } else {
          const adjustmentPercentage =
            (totalAmount + totalTax + (newOrderPriceAdjustment.value || 0)) /
            (totalAmount + totalTax);
          totalTax *= adjustmentPercentage;
          totalAmount *= adjustmentPercentage;
        }
      } else if (newOrderPriceAdjustment.type === "percentage") {
        totalTax *= 1 - newOrderPriceAdjustment.value / 100;
        totalAmount *= 1 - newOrderPriceAdjustment.value / 100;
      }
    }
    return {
      tax: totalTax,
      amount: totalAmount,
    };
  }

  /**
   * Total price of an advanced exchange item. Takes into account the price adjustment in "newOrderPriceAdjustment" if applicable
   */
  getAdvancedExchangeItemPrice(variantId: string) {
    const advancedExchangeItem = this.getReturnAdvancedExchangeItems().find(
      (item) => item.variantId === variantId,
    );
    if (
      !advancedExchangeItem ||
      !advancedExchangeItem.price ||
      !advancedExchangeItem.tax
    ) {
      return;
    }

    let priceAdjustment = 0;
    let taxAdjustment = 0;
    const totalAdjustment =
      (this.return_.newOrderPriceAdjustment?.value || 0) *
      (+advancedExchangeItem.price /
        this.getRawNewOrderValue(this.productTotals).amount);
    const itemPricePlusTax =
      +advancedExchangeItem.price + +advancedExchangeItem.tax;
    switch (this.return_.newOrderPriceAdjustment?.type) {
      case "percentage":
        priceAdjustment =
          this.return_.newOrderPriceAdjustment.value *
          0.01 *
          +advancedExchangeItem.price;
        taxAdjustment =
          this.return_.newOrderPriceAdjustment.value *
          0.01 *
          +advancedExchangeItem.tax;
        break;
      case "amount":
        priceAdjustment =
          totalAdjustment * (+advancedExchangeItem.price / itemPricePlusTax);
        taxAdjustment =
          totalAdjustment * (+advancedExchangeItem.tax / itemPricePlusTax);
        break;
    }

    return {
      price: +advancedExchangeItem.price - priceAdjustment,
      tax: +advancedExchangeItem.tax - taxAdjustment,
    };
  }

  getRawNewOrderValue(productsToProcess: ProductTotals[]) {
    const variantExchangeValue = this.getVariantExchangeValue(
      productsToProcess,
      false,
    );
    const advancedExchangeItemsFee = this.getAdvancedExchangeItemsFee();
    return {
      tax: variantExchangeValue.tax + advancedExchangeItemsFee.tax,
      amount: variantExchangeValue.amount + advancedExchangeItemsFee.amount,
    };
  }

  getRecoveryFee(
    productsToProcess: ProductTotals[],
    charge: number,
    returnValue: number,
    newOrderValue: number,
  ) {
    const totalMerchantAdjustments = productsToProcess.reduce(
      (total, product) => total + product.merchantAdjustment,
      0,
    );
    if (returnValue - totalMerchantAdjustments > newOrderValue) {
      return charge;
    }

    return totalMerchantAdjustments > 0 ? 0 : totalMerchantAdjustments * -1;
  }

  getTotalNewOrderDiscountAllocations() {
    const discountAllocations = this.getReturnDiscountAllocations();
    if (!discountAllocations) {
      return 0;
    }

    return discountAllocations.reduce(
      (total: number, discount: DiscountAllocation) =>
        total + parseFloat(discount.discountedAmount.amount),
      0,
    );
  }

  isVariantExchange(product: Product) {
    return (
      product.exchangeGroupItem ||
      ("exchange_for" in product && product.exchange_for) ||
      ("new_item" in product && product.new_item)
    );
  }

  getFinalSaleCredit(productsToProcess: ProductTotals[]) {
    const finalSaleProducts = productsToProcess.filter(
      (product) => product.product.isFinalSaleReturn,
    );
    const storeCreditTotals = this.getStoreCreditAmount(finalSaleProducts);
    const refundTotals = this.getRefundAmount(finalSaleProducts);
    // FIXME: Is it ok add tax like this?
    return {
      amount: storeCreditTotals.amount + refundTotals.amount,
      tax: storeCreditTotals.tax + refundTotals.tax,
    };
  }

  private static findShopifyLineItem(
    lineItemId: string | number,
    order: Order,
  ) {
    return order.shopify.line_items.find(
      (lineItem) => String(lineItem.id) === String(lineItemId),
    );
  }

  private getUsePreDiscountPrice() {
    return (
      (this.isReturnPortal &&
        this.team.settings.exchanges.usePreDiscountPrice) ||
      this.return_.usePreDiscountPrice
    );
  }

  private getNonAccruableCredit(productsToProcess: ProductTotals[]) {
    let tax = 0;
    let amount = 0;
    if (this.getUsePreDiscountPrice()) {
      for (const product of productsToProcess) {
        const order = this.getProductOrder(product.product);

        if (this.isVariantExchange(product.product)) {
          continue;
        }
        const nonAccruableAmount =
          product.originalPrice - product.discountPrice;

        amount += nonAccruableAmount;
        const shopifyLineItem = ReturnTotalsCalculator.findShopifyLineItem(
          product.product.line_item_id,
          order,
        );

        tax +=
          nonAccruableAmount *
          ReturnTotalsCalculator.getTaxRateForLineItem(shopifyLineItem!);
      }
    }

    return { amount, tax };
  }

  private getProductStrategy(product: Product) {
    if (this.useOriginalReturn && product.originalVariantOptions) {
      return product.originalVariantOptions.strategy;
    }
    return product.strategy;
  }

  private getProductPriceAdjustment(product: Product) {
    if (this.useOriginalReturn && product.originalVariantOptions) {
      return product.originalVariantOptions.price_adjustment;
    }
    return product.price_adjustment;
  }

  private getProductMerchantAdjustment(product: Product) {
    // Merchant adjustments happen after the return is created, so if we are
    // calculating for the original return, we shouldn't use merchant adjustments
    // (different than price_adjustment which is calculated in the return portal)
    if (this.useOriginalReturn || !product.merchant_adjustment) {
      return "0";
    }
    return product.merchant_adjustment;
  }

  private getReturnAdvancedExchangeItems() {
    if (this.useOriginalReturn && this.return_.originalReturnOptions) {
      return this.return_.originalReturnOptions.advancedExchangeItems || [];
    }
    return this.return_.advancedExchangeItems;
  }

  /** Only includes taxes on the advanced exchange items, not items being exchanged for the same item */
  private getReturnNewOrderTaxes() {
    if (this.useOriginalReturn && this.return_.originalReturnOptions) {
      return this.return_.originalReturnOptions.new_order_taxes;
    }
    return this.return_.new_order_taxes;
  }

  private getReturnDiscountAllocations() {
    if (this.useOriginalReturn && this.return_.originalReturnOptions) {
      return this.return_.originalReturnOptions.discount_allocations;
    }
    return this.return_.discount_allocations;
  }
}
