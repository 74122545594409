import { assertNever } from "@redotech/util/type";
import * as z from "zod";
import { ConversationPlatform } from "../conversation";
export enum ConversationEventType {
  MENTIONED_IN_CONVERSATION = 1,
  ASSIGNED_TO_CONVERSATION = 2,
  NEW_MESSAGE_ASSIGNED_CONVERSATION = 3,
  NEW_MESSAGE_UNASSIGNED_CONVERSATION = 4,
}

export enum NotificationChannelType {
  PUSH = 1,
  EMAIL = 2,
  DESKTOP = 3,
}

export interface NotificationChannelSettings {
  desktop: boolean;
  email: boolean;
  push: boolean;
}

export interface ConversationEventNotificationSettings {
  mentionedInConversation: NotificationChannelSettings;
  assignedToConversation: NotificationChannelSettings;
  newMessageInAssignedConversation: NotificationChannelSettings;
  newMessageInUnassignedConversation: NotificationChannelSettings;
}

export interface PlatformConversationEventNotificationSettings {
  chat: ConversationEventNotificationSettings;
  email: ConversationEventNotificationSettings;
  facebookComments: ConversationEventNotificationSettings;
  facebookChat: ConversationEventNotificationSettings;
  instagramComments: ConversationEventNotificationSettings;
  instagramChat: ConversationEventNotificationSettings;
  attentive: ConversationEventNotificationSettings;
  postscript: ConversationEventNotificationSettings;
}

export const UserNotificationSettingsZodSchema = z.any(); //TODO: type this

export interface UserNotificationSettings {
  playSoundOnDesktopNotification: boolean;
  supportNotificationSettings: PlatformConversationEventNotificationSettings;
}

export interface OldUserNotificationSettings {
  playSoundOnDesktopNotification: boolean;
  mentionedInConversation: NotificationChannelSettings;
  assignedToConversation: NotificationChannelSettings;
  newMessageInAssignedConversation: NotificationChannelSettings;
  newMessageInUnassignedConversation: NotificationChannelSettings;
}

function settingIsNewSetting(x: unknown): x is UserNotificationSettings {
  return (
    (x as UserNotificationSettings).supportNotificationSettings !== undefined
  );
}

export function ensureFullNotificationsTypeDefined(
  startingItem: OldUserNotificationSettings | UserNotificationSettings,
): OldUserNotificationSettings & UserNotificationSettings {
  if (settingIsNewSetting(startingItem)) {
    return {
      playSoundOnDesktopNotification:
        startingItem.playSoundOnDesktopNotification,
      supportNotificationSettings: startingItem.supportNotificationSettings,
      mentionedInConversation:
        startingItem.supportNotificationSettings.chat.mentionedInConversation,
      assignedToConversation:
        startingItem.supportNotificationSettings.chat.assignedToConversation,
      newMessageInAssignedConversation:
        startingItem.supportNotificationSettings.chat
          .newMessageInAssignedConversation,
      newMessageInUnassignedConversation:
        startingItem.supportNotificationSettings.chat
          .newMessageInUnassignedConversation,
    };
  } else {
    const supportSettings: PlatformConversationEventNotificationSettings = {
      chat: {
        mentionedInConversation: startingItem.mentionedInConversation,
        assignedToConversation: startingItem.assignedToConversation,
        newMessageInAssignedConversation:
          startingItem.newMessageInAssignedConversation,
        newMessageInUnassignedConversation:
          startingItem.newMessageInUnassignedConversation,
      },
      email: {
        mentionedInConversation: startingItem.mentionedInConversation,
        assignedToConversation: startingItem.assignedToConversation,
        newMessageInAssignedConversation:
          startingItem.newMessageInAssignedConversation,
        newMessageInUnassignedConversation:
          startingItem.newMessageInUnassignedConversation,
      },
      facebookComments: {
        mentionedInConversation: startingItem.mentionedInConversation,
        assignedToConversation: startingItem.assignedToConversation,
        newMessageInAssignedConversation:
          startingItem.newMessageInAssignedConversation,
        newMessageInUnassignedConversation:
          startingItem.newMessageInUnassignedConversation,
      },
      facebookChat: {
        mentionedInConversation: startingItem.mentionedInConversation,
        assignedToConversation: startingItem.assignedToConversation,
        newMessageInAssignedConversation:
          startingItem.newMessageInAssignedConversation,
        newMessageInUnassignedConversation:
          startingItem.newMessageInUnassignedConversation,
      },
      instagramComments: {
        mentionedInConversation: startingItem.mentionedInConversation,
        assignedToConversation: startingItem.assignedToConversation,
        newMessageInAssignedConversation:
          startingItem.newMessageInAssignedConversation,
        newMessageInUnassignedConversation:
          startingItem.newMessageInUnassignedConversation,
      },
      instagramChat: {
        mentionedInConversation: startingItem.mentionedInConversation,
        assignedToConversation: startingItem.assignedToConversation,
        newMessageInAssignedConversation:
          startingItem.newMessageInAssignedConversation,
        newMessageInUnassignedConversation:
          startingItem.newMessageInUnassignedConversation,
      },
      attentive: {
        mentionedInConversation: startingItem.mentionedInConversation,
        assignedToConversation: startingItem.assignedToConversation,
        newMessageInAssignedConversation:
          startingItem.newMessageInAssignedConversation,
        newMessageInUnassignedConversation:
          startingItem.newMessageInUnassignedConversation,
      },
      postscript: {
        mentionedInConversation: startingItem.mentionedInConversation,
        assignedToConversation: startingItem.assignedToConversation,
        newMessageInAssignedConversation:
          startingItem.newMessageInAssignedConversation,
        newMessageInUnassignedConversation:
          startingItem.newMessageInUnassignedConversation,
      },
    };
    return {
      playSoundOnDesktopNotification:
        startingItem.playSoundOnDesktopNotification,
      supportNotificationSettings: supportSettings,
      mentionedInConversation: startingItem.mentionedInConversation,
      assignedToConversation: startingItem.assignedToConversation,
      newMessageInAssignedConversation:
        startingItem.newMessageInAssignedConversation,
      newMessageInUnassignedConversation:
        startingItem.newMessageInUnassignedConversation,
    };
  }
}

/**
 * Keep this function here. If we ever add a platform with which
 * to start conversations, the code in this file and the places this code is used need to be considered
 */
function fakeFunctionThatWillFailToCompileWhenEnumWeCareAboutIsUpdated(
  t: ConversationPlatform,
) {
  switch (t) {
    case ConversationPlatform.EMAIL:
    case ConversationPlatform.REDO_CHAT:
    case ConversationPlatform.FACEBOOK:
    case ConversationPlatform.FACEBOOK_COMMENTS:
    case ConversationPlatform.INSTAGRAM:
    case ConversationPlatform.INSTAGRAM_COMMENTS:
    case ConversationPlatform.SMS:
    case ConversationPlatform.ATTENTIVE:
    case ConversationPlatform.POSTSCRIPT:
      return "";
    default:
      assertNever(t);
  }
}
fakeFunctionThatWillFailToCompileWhenEnumWeCareAboutIsUpdated(
  ConversationPlatform.EMAIL,
);
