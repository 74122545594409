// extracted by mini-css-extract-plugin
export var container = "_redotech-redo-web-src-labeled-input-container";
export var error = "_redotech-redo-web-src-labeled-input-error";
export var label = "_redotech-redo-web-src-labeled-input-label";
export var light = "_redotech-redo-web-src-labeled-input-light";
export var md = "_redotech-redo-web-src-labeled-input-md";
export var sm = "_redotech-redo-web-src-labeled-input-sm";
export var thin = "_redotech-redo-web-src-labeled-input-thin";
export var thinBold = "_redotech-redo-web-src-labeled-input-thin-bold";
export var vertical = "_redotech-redo-web-src-labeled-input-vertical";
export var xs = "_redotech-redo-web-src-labeled-input-xs";