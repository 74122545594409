import { Status } from "@redotech/http/semantics";
import { ZodError } from "zod";

export class RpcError extends Error {
  constructor(
    readonly status: Status,
    override readonly message: string,
  ) {
    super(message);
  }
}

export class ClientError extends Error {
  constructor(
    readonly statusCode: number,
    override readonly message: string,
  ) {
    super(message);
  }
}

export function serializeError(error: unknown): string {
  if (error instanceof ZodError) {
    return JSON.stringify(error.format());
  } else if (error instanceof Error) {
    return error.message;
  } else {
    return JSON.stringify(error, null, 2);
  }
}
