import { assertNever } from "@redotech/util/type";

export enum ReturnStatus {
  OPEN = "open",
  IN_TRANSIT = "in_transit",
  DELIVERED = "delivered",
  NEEDS_REVIEW = "needs_review",
  COMPLETE = "complete",
  REJECTED = "rejected",
  FLAGGED = "flagged",
  PRE_SHIPMENT = "pre-shipment",
}

export function returnStatusToDisplayText(returnStatus: ReturnStatus): string {
  switch (returnStatus) {
    case ReturnStatus.COMPLETE:
      return "Complete";
    case ReturnStatus.DELIVERED:
      return "Delivered";
    case ReturnStatus.IN_TRANSIT:
      return "In transit";
    case ReturnStatus.NEEDS_REVIEW:
      return "Needs review";
    case ReturnStatus.OPEN:
      return "Open";
    case ReturnStatus.REJECTED:
      return "Rejected";
    case ReturnStatus.FLAGGED:
      return "Flagged";
    case ReturnStatus.PRE_SHIPMENT:
      return "Pre-shipment";
    default:
      assertNever(returnStatus);
  }
}
