import stripe from "stripe";
import * as z from "zod";
type PaymentIntentType = "label" | "recovery" | "hold" | "upsell" | "repair";

export const PaymentIntentItemZodSchema = z.object({
  id: z.string(),
  type: z.enum(["label", "recovery", "hold", "upsell", "repair"]),
  payment_intent: z.any(),
  refund: z.any().optional(),
  amount_USD: z.number().optional(),
  amount_received_USD: z.number().optional(),
});
export interface PaymentIntentItem {
  id: string;
  type: PaymentIntentType;
  payment_intent: stripe.PaymentIntent;
  refund?: any;
  amount_USD?: number;
  amount_received_USD?: number;
}
