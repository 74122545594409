import { memo } from "react";
import * as loadingRedoAnimationCss from "./loading-redo-animation.module.css";
import { Spinner, SpinnerStyle } from "./spinner";

export const LoadingRedoAnimation = memo(function LoadingRedoAnimation({
  style,
}: {
  style?: SpinnerStyle;
}) {
  return (
    <div className={loadingRedoAnimationCss.loadingSpinner}>
      <div className={loadingRedoAnimationCss.spinnerContainer}>
        <Spinner style={style} />
      </div>
    </div>
  );
});
