/**
 * @link https://shopify.dev/docs/api/admin-graphql/2024-10/objects/AppSubscription
 */

const ShopifyAppSubscriptionStatus = [
  "ACTIVE",
  "CANCELLED",
  "DECLINED",
  "EXPIRED",
  "FROZEN",
  "PENDING",
  "ACCEPTED",
] as const;
export type ShopifyAppSubscriptionStatusType =
  (typeof ShopifyAppSubscriptionStatus)[number];

interface LineItem {
  id: string;
  plan: { pricingDetails: RecurringPlanPricing | UsagePlanPricing };
}

interface RecurringPlanPricing {
  __typename: "AppRecurringPricing";
  interval: string;
  price: {
    amount: string;
    currencyCode: string;
  };
}

interface UsagePlanPricing {
  __typename: "AppUsagePricing";
  interval: string;
  terms: string;
  balanceUsed: {
    amount: string;
    currencyCode: string;
  };
  cappedAmount: {
    amount: string;
    currencyCode: string;
  };
}

export interface ShopifySubscription {
  id: string;
  status: ShopifyAppSubscriptionStatusType;
  createdAt: string;
  currentPeriodEnd: string;
  lineItems: LineItem[];
  trialDays?: number;
  name?: string;
}

export function getUsagePlanPricing(
  subscription: ShopifySubscription | undefined,
): UsagePlanPricing | undefined {
  return subscription?.lineItems
    .map((item) => item.plan.pricingDetails)
    .find((pricingDetails) => pricingDetails.__typename === "AppUsagePricing");
}

export function getRecurringPlanPricing(
  subscription: ShopifySubscription | undefined,
): RecurringPlanPricing | undefined {
  return subscription?.lineItems
    .map((item) => item.plan.pricingDetails)
    .find(
      (pricingDetails) => pricingDetails.__typename === "AppRecurringPricing",
    );
}
