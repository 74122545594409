import {
  ClientResponse,
  ReturnData,
  StorefrontApiClient,
  StorefrontOperations,
  createStorefrontApiClient,
} from "@shopify/storefront-api-client";

export class ShopifyStorefrontClient {
  private client: StorefrontApiClient;
  private tries: number = 3;

  constructor(storeUrl: string, publicAccessToken: string) {
    const isBrowser = typeof window !== "undefined";
    this.client = createStorefrontApiClient({
      storeDomain: `https://${storeUrl}`,
      apiVersion: "2024-04",
      publicAccessToken,
      retries: 3,
      customFetchApi: isBrowser ? window.fetch : fetch,
    });
  }

  public async request<T, V extends Record<string, any>>(
    operation: string,
    variables?: V,
  ): Promise<
    ClientResponse<
      T extends undefined ? ReturnData<string, StorefrontOperations> : T
    >
  > {
    return await this.client.request<T>(operation, {
      variables,
      retries: this.tries,
    });
  }
}

export function handleErrors(errors: any) {
  if (errors) {
    if ((errors as any).graphQLErrors) {
      (errors as any).graphQLErrors.filter((error: Error) => {
        error.message.includes("totalInventory"); // this is a recoverable error
      });
    } else {
      console.error("GraphQL request failed:", errors.status, errors.message);
      throw new Error("GraphQL request failed. See console for details.");
    }
  }
}
