import { Bijection } from "./bijection";

const HEX_BASE = 16;
const hex: string[] = [];
for (let i = 0; i < HEX_BASE * HEX_BASE; i++) {
  hex[i] = i.toString(HEX_BASE).padStart(2, "0");
}

export const base64StringEncoding: Bijection<string, string> = {
  read(string) {
    return atob(string);
  },
  write(value) {
    return btoa(value);
  },
};

export const base64Encoding: Bijection<string, ArrayBuffer> = {
  read(string) {
    const base64 = atob(string);
    return new TextEncoder().encode(base64);
  },
  write(value) {
    const base64 = new TextDecoder().decode(value);
    return btoa(base64);
  },
};

export const hexEncoding: Bijection<string, ArrayBuffer> = {
  read(string) {
    if (typeof Buffer !== "undefined") {
      return Buffer.from(string, "hex");
    }
    const data = new Uint8Array(string.length / 2);
    for (let i = 0; i < string.length; i += 2) {
      data[i] = parseInt(string.slice(i, i + 2), HEX_BASE);
    }
    return data;
  },
  write(data) {
    if (typeof Buffer !== "undefined") {
      return Buffer.from(data).toString("hex");
    }
    const ints = new Uint8Array(data);
    let result = "";
    for (const int of ints) {
      result += hex[int];
    }
    return result;
  },
};

export const latin1Encoding: Bijection<ArrayBuffer, string> = {
  read(data) {
    return new TextDecoder("latin1").decode(data);
  },
  write(string) {
    if (typeof Buffer !== "undefined") {
      return Buffer.from(string, "binary");
    }
    const data = new Uint8Array(string.length);
    for (let index = 0; index < string.length; index++) {
      data[index] = string.charCodeAt(index);
    }
    return data;
  },
};

export const utf8Encoding: Bijection<ArrayBuffer, string> = {
  read(data) {
    return new TextDecoder().decode(data);
  },
  write(string) {
    return new TextEncoder().encode(string);
  },
};
