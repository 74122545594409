// extracted by mini-css-extract-plugin
export var addressCard = "_redotech-redo-return-app-src-order-return-flow-review-address-card";
export var addressFields = "_redotech-redo-return-app-src-order-return-flow-review-address-fields";
export var addressText = "_redotech-redo-return-app-src-order-return-flow-review-address-text";
export var adjustment = "_redotech-redo-return-app-src-order-return-flow-review-adjustment";
export var adjustmentBonus = "_redotech-redo-return-app-src-order-return-flow-review-adjustment-bonus";
export var adjustmentFee = "_redotech-redo-return-app-src-order-return-flow-review-adjustment-fee";
export var bonus = "_redotech-redo-return-app-src-order-return-flow-review-bonus";
export var brand = "_redotech-redo-return-app-src-order-return-flow-review-brand";
export var button = "_redotech-redo-return-app-src-order-return-flow-review-button";
export var card = "_redotech-redo-return-app-src-order-return-flow-review-card";
export var cardImage = "_redotech-redo-return-app-src-order-return-flow-review-card-image";
export var cardInformation = "_redotech-redo-return-app-src-order-return-flow-review-card-information";
export var cardItemTitle = "_redotech-redo-return-app-src-order-return-flow-review-card-item-title";
export var cardItems = "_redotech-redo-return-app-src-order-return-flow-review-card-items";
export var cards = "_redotech-redo-return-app-src-order-return-flow-review-cards";
export var center = "_redotech-redo-return-app-src-order-return-flow-review-center";
export var code = "_redotech-redo-return-app-src-order-return-flow-review-code";
export var contentWrapper = "_redotech-redo-return-app-src-order-return-flow-review-content-wrapper";
export var covered = "_redotech-redo-return-app-src-order-return-flow-review-covered";
export var edit = "_redotech-redo-return-app-src-order-return-flow-review-edit";
export var error = "_redotech-redo-return-app-src-order-return-flow-review-error";
export var exchangeBox = "_redotech-redo-return-app-src-order-return-flow-review-exchange-box";
export var exchangeBoxSelected = "_redotech-redo-return-app-src-order-return-flow-review-exchange-box-selected";
export var exchangeBoxes = "_redotech-redo-return-app-src-order-return-flow-review-exchange-boxes";
export var fee = "_redotech-redo-return-app-src-order-return-flow-review-fee";
export var flex = "_redotech-redo-return-app-src-order-return-flow-review-flex";
export var greenReturnAdjustment = "_redotech-redo-return-app-src-order-return-flow-review-green-return-adjustment";
export var infoIcon = "_redotech-redo-return-app-src-order-return-flow-review-info-icon";
export var input = "_redotech-redo-return-app-src-order-return-flow-review-input";
export var inputRow = "_redotech-redo-return-app-src-order-return-flow-review-input-row";
export var left = "_redotech-redo-return-app-src-order-return-flow-review-left";
export var leftContent = "_redotech-redo-return-app-src-order-return-flow-review-left-content";
export var line = "_redotech-redo-return-app-src-order-return-flow-review-line";
export var logo = "_redotech-redo-return-app-src-order-return-flow-review-logo";
export var modal = "_redotech-redo-return-app-src-order-return-flow-review-modal";
export var modalContainer = "_redotech-redo-return-app-src-order-return-flow-review-modal-container";
export var modalTitle = "_redotech-redo-return-app-src-order-return-flow-review-modal-title";
export var orderMethodText = "_redotech-redo-return-app-src-order-return-flow-review-order-method-text";
export var page = "_redotech-redo-return-app-src-order-return-flow-review-page";
export var pickupButton = "_redotech-redo-return-app-src-order-return-flow-review-pickup-button";
export var pickupForm = "_redotech-redo-return-app-src-order-return-flow-review-pickup-form";
export var pickupInputDescription = "_redotech-redo-return-app-src-order-return-flow-review-pickup-input-description";
export var pickupLocationDropdown = "_redotech-redo-return-app-src-order-return-flow-review-pickup-location-dropdown";
export var pickupPrice = "_redotech-redo-return-app-src-order-return-flow-review-pickup-price";
export var price = "_redotech-redo-return-app-src-order-return-flow-review-price";
export var reminderTextItem = "_redotech-redo-return-app-src-order-return-flow-review-reminder-text-item";
export var returningDisclaimer = "_redotech-redo-return-app-src-order-return-flow-review-returning-disclaimer";
export var subheader = "_redotech-redo-return-app-src-order-return-flow-review-subheader";
export var summaryCard = "_redotech-redo-return-app-src-order-return-flow-review-summary-card";
export var summaryCardContent = "_redotech-redo-return-app-src-order-return-flow-review-summary-card-content";
export var summaryCardHorizonal = "_redotech-redo-return-app-src-order-return-flow-review-summary-card-horizonal";
export var summaryCardItem = "_redotech-redo-return-app-src-order-return-flow-review-summary-card-item";
export var summaryCardItemHorizontal = "_redotech-redo-return-app-src-order-return-flow-review-summary-card-item-horizontal";
export var summaryCardItemPayment = "_redotech-redo-return-app-src-order-return-flow-review-summary-card-item-payment";
export var summaryCardPickup = "_redotech-redo-return-app-src-order-return-flow-review-summary-card-pickup";
export var summaryCards = "_redotech-redo-return-app-src-order-return-flow-review-summary-cards";
export var summaryDisclaimer = "_redotech-redo-return-app-src-order-return-flow-review-summary-disclaimer";
export var summaryHeader = "_redotech-redo-return-app-src-order-return-flow-review-summary-header";
export var summarySubheader = "_redotech-redo-return-app-src-order-return-flow-review-summary-subheader";
export var summarySubtext = "_redotech-redo-return-app-src-order-return-flow-review-summary-subtext";
export var summaryText = "_redotech-redo-return-app-src-order-return-flow-review-summary-text";
export var summaryTextBold = "_redotech-redo-return-app-src-order-return-flow-review-summary-text-bold";
export var summaryTextLight = "_redotech-redo-return-app-src-order-return-flow-review-summary-text-light";
export var title = "_redotech-redo-return-app-src-order-return-flow-review-title";
export var variantTitle = "_redotech-redo-return-app-src-order-return-flow-review-variant-title";
export var voided = "_redotech-redo-return-app-src-order-return-flow-review-voided";