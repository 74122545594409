import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { Provider } from "./order";
import { ShopifyOrderSchema } from "./shopify-order";
export const PermissionActionSchema = z.enum(["allow", "deny"]);

export const PermissionEntrySchema = z.object({
  name: z.string(),
  action: PermissionActionSchema,
});

export const NotificationChannelSettingsSchema = z.object({
  desktop: z.boolean(),
  email: z.boolean(),
  push: z.boolean(),
});

export const UserNotificationsSchema = z.object({
  playSoundOnDesktopNotification: z.boolean(),
  mentionedInConversation: NotificationChannelSettingsSchema,
  assignedToConversation: NotificationChannelSettingsSchema,
  newMessageInAssignedConversation: NotificationChannelSettingsSchema,
  newMessageInUnassignedConversation: NotificationChannelSettingsSchema,
});

export const CustomViewOrderingSchema = z.object({
  viewName: z.string().nullish(),
  view: z.string().nullish(),
});

export const GetUserSchema = z.object({
  roles: z.array(z.string()),
  _id: z.record(z.any()),
  email: z.string(),
  createdAt: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  name: z.string(),
  updatedAt: z.string(),
  image: z.array(z.any()),
  team: zExt.objectId(),
  permissions: z.array(PermissionEntrySchema),
  notifications: UserNotificationsSchema,
  customViewOrdering: z.array(CustomViewOrderingSchema).nullish(),
});

// Update the TimelineEventSchema to use the newly created GetUserSchema
export const TimelineEventSchema = z.object({
  customer: z
    .object({
      firstName: z.string().nullish(),
      lastName: z.string().nullish(),
      email: z.string().nullish(),
      gravatarUrl: z.string().nullish(),
    })
    .nullish(),
  user: z.union([z.string(), GetUserSchema]).nullish(),
  image: z.string().nullish(),
  isShipment: z.boolean(),
  _id: z.record(z.any()).nullish(),
  message: z.string().nullish().nullish(),
  link: z
    .object({
      message: z.string().nullish(),
      orderNumber: z.string().nullish(),
      url: z.string().nullish(),
    })
    .nullish(),
  subMessage: z.string().nullish(),
  isSystem: z.boolean(),
  updatedAt: z.date().nullish(),
  createdAt: z.date().nullish(),
});

export const OutboundLabelShippingAddressSchema = z.object({
  name: z.string().nullish(),
  address1: z.string(),
  address2: z.string().nullish(),
  city: z.string(),
  country: z.string().nullish(),
  country_code: z.string().nullish(),
  province: z.string().nullish(),
  province_code: z.string().nullish(),
  zip: z.string().nullish(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  company: z.string().nullish(),
  email: z.string().nullish(),
  phone: z.string(),
});

export const ShipmentParcelSchema = z.object({
  id: z.string(),
  length: z.number().nullish().nullish(),
  width: z.number().nullish().nullish(),
  height: z.number().nullish().nullish(),
  weight: z.number(),
});

export const AddressSchema = z.object({
  company: z.string().nullish().nullish(),
  name: z.string().nullish().nullish(),
  phone: z.string().nullish().nullish(),
  email: z.string().nullish().nullish(),
  street1: z.string().nullish().nullish(),
  street2: z.string().nullish().nullish(),
  city: z.string().nullish().nullish(),
  state: z.string().nullish().nullish(),
  zip: z.string(),
  country: z.string(),
  residential: z.boolean(),
});

export const RateSchema = z.object({
  id: z.string(),
  shipment_id: z.string(),
  rate: z.string(),
  currency: z.string(),
  carrier: z.string(),
  service: z.string(),
  delivery_date: z.string(),
  delivery_days: z.number(),
  delivery_date_guaranteed: z.boolean(),
  upcharge: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
});

export const LabelSchema = z.object({
  id: z.string(),
  label_url: z.string(),
});

export const ShipmentRatesSchema = z.object({
  shipmentId: z.string().nullish(),
  toAddress: AddressSchema,
  fromAddress: AddressSchema,
  parcel: ShipmentParcelSchema,
  mode: z.string(),
  rates: z.array(RateSchema),
  totalWeight: z.number(),
  weightUnit: z.union([
    z.literal("oz"),
    z.literal("lb"),
    z.literal("g"),
    z.literal("kg"),
  ]),
});

export const ServiceLevelSchema = z.object({
  carrier: z.string(),
  service: z.string(),
  upcharge: z
    .object({
      type: z.union([z.literal("amount"), z.literal("percentage")]),
      amount: z.number().nullish(),
      percentage: z.number().nullish(),
    })
    .nullish(),
});

export const ParcelTypeSchema = z.union([
  z.literal("box"),
  z.literal("envelope"),
  z.literal("soft_pack"),
]);

export const LengthUnitSchema = z.union([z.literal("in"), z.literal("cm")]);

export const WeightUnitSchema = z.union([
  z.literal("oz"),
  z.literal("lb"),
  z.literal("g"),
  z.literal("kg"),
]);

export const BaseParcelSchema = z.object({
  name: z.string(),
});

export const CustomParcelSchema = BaseParcelSchema.extend({
  type: ParcelTypeSchema,
  length: z.number(),
  width: z.number(),
  height: z.number(),
  lengthUnit: LengthUnitSchema,
  weight: z.number(),
  weightUnit: WeightUnitSchema,
});

export const CarrierParcelSchema = BaseParcelSchema.extend({
  carrier: z.string(),
  description: z.string(),
  dimensions: z.array(z.string()),
  human_readable: z.string(),
  max_weight: z.number().nullish(),
});

export const ParcelSchema = z.union([CustomParcelSchema, CarrierParcelSchema]);

export enum PaymentModel {
  CUSTOMER_PAID = "customer_paid",
  MERCHANT_PAID = "merchant_paid",
}

export const PaymentModelSchema = z.nativeEnum(PaymentModel);

export const CoverageSchema = z.object({
  exchange: z.boolean(),
  storeCredit: z.boolean(),
  refund: z.boolean(),
});

export const AttachmentStrategySchema = z.union([
  z.literal("check-out"),
  z.literal("checkbox"),
  z.literal("double-check-out"),
  z.literal("double-toggle"),
  z.literal("drop-down"),
  z.literal("single-toggle"),
  z.literal("cart-card"),
]);

export const MoneySchema = z.object({
  amount: z.string(),
  currency: z.string(),
});

const OriginItemSchema = z.object({
  unitPrice: z.string(),
  preDiscountPrice: z.string().nullish(),
  currency: z.string(),
  tax: z.string(),
  originVariantId: z.number(),
  newVariantId: z.number(),
  applicableShopifyOrder: z.number().nullish(),
});

const TrackerSchema = z.object({
  id: z.string(),
  object: z.string(),
  mode: z.string(),
  status_detail: z.string(),
  signed_by: z.string().nullish(),
  weight: z.string().nullish(),
  shipment_id: z.string().nullish(),
  carrier_detail: z.any().nullish(),
  finalized: z.boolean(),
  is_return: z.boolean(),
  tracking_code: z.string(),
  carrier: z.string(),
  status: z.string(),
  est_delivery_date: z.string().nullish(),
  public_url: z.string().nullish(),
  created_at: z.string(),
  updated_at: z.string(),
  tracking_details: z.array(
    z.object({
      message: z.string(),
      status: z.string(),
      datetime: z.string(),
    }),
  ),
});

const SesEventSchema = z.union([
  z.object({
    eventType: z.literal("Bounce"),
    bounce: z.object({
      bounceType: z.string(),
      bounceSubType: z.string(),
      bouncedRecipients: z.array(
        z.object({
          emailAddress: z.string(),
          status: z.string(),
          action: z.string(),
          diagnosticCode: z.string(),
        }),
      ),
      timestamp: z.string(),
      feedbackId: z.string(),
    }),
  }),
  z.object({
    eventType: z.literal("Complaint"),
    complaint: z.object({
      complainedRecipients: z.array(
        z.object({
          emailAddress: z.string(),
        }),
      ),
      timestamp: z.string(),
      feedbackId: z.string(),
      userAgent: z.string(),
      complaintFeedbackType: z.string(),
    }),
  }),
  z.object({
    eventType: z.literal("Delivery"),
    delivery: z.object({
      timestamp: z.string(),
      recipients: z.array(z.string()),
    }),
  }),
  z.object({
    eventType: z.literal("Send"),
    mail: z.object({
      triggerType: z.string(),
      timestamp: z.string(),
      source: z.string(),
      messageId: z.string(),
      destination: z.array(z.string()),
    }),
  }),
  z.object({
    eventType: z.literal("Open"),
    open: z.object({
      timestamp: z.string(),
      ipAddress: z.string(),
      userAgent: z.string(),
    }),
  }),
  z.object({
    eventType: z.literal("Click"),
    click: z.object({
      timestamp: z.string(),
      link: z.string(),
      userAgent: z.string(),
      ipAddress: z.string(),
    }),
  }),
  z.object({
    eventType: z.literal("Subscription"),
    subscription: z.object({
      contactList: z.string(),
      timestamp: z.string(),
      source: z.string(),
      newTopicPreferences: z.object({
        unsubscribeAll: z.boolean(),
        topicSubscriptionStatus: z.array(
          z.object({
            topicName: z.string(),
            subscriptionStatus: z.string(),
          }),
        ),
      }),
      oldTopicPreferences: z.object({
        unsubscribeAll: z.boolean(),
        topicSubscriptionStatus: z.array(
          z.object({
            topicName: z.string(),
            subscriptionStatus: z.string(),
          }),
        ),
      }),
    }),
  }),
]);

const LineItemFulfillmentSchema = z.object({
  shopify_line_item_id: z.string().nullish(),
  fulfilled_at: z.date().nullish(),
  fulfillment_status: z.string().nullish(),
  shipment_status: z.string().nullish(),
  delivery_date: z.date().nullish(),
  delivered_at: z.date().nullish(),
});

export const OrderSchema = z.object({
  coverage: CoverageSchema.nullish(),
  protected: z.boolean(),
  packageProtected: z.boolean().nullish(),
  credits: z.number(),
  isExchangeOrder: z.boolean(),
  coverageEnabled: z.boolean().nullish(),
  _id: zExt.objectId(),
  shopify_id: z.string(),
  team: zExt.objectId(),
  createdAt: z.date(),
  customer_name: z.string().nullish(),
  lineItemsFulfillment: z.array(LineItemFulfillmentSchema).nullish(),
  stalledInFullfillment: z.boolean().nullish(),
  forceProtected: z.boolean().nullish(),
  lineItems: z
    .array(
      z.object({
        id: z.string(),
        _id: z.record(z.any()),
      }),
    )
    .nullish(),
  discount: z
    .object({
      id: z.string().nullish(),
      description: z.string().nullish(),
      code: z.string().nullish(),
      expirationDateTime: z.string().nullish(),
    })
    .nullish(),
  shopify: ShopifyOrderSchema,
  shopifyCreatedAt: z.date(),
  shopifyUpdatedAt: z.date().nullish(),
  timeline: z.array(TimelineEventSchema),
  updatedAt: z.date(),
  __v: z.number(),
  normalized_postal: z.array(z.string()).nullish(),
  redoEligible: z.boolean().nullish(),
  originItems: z.array(OriginItemSchema).nullish(),
  currentEmailFlows: z
    .array(
      z.object({
        emailFlowId: z.string(),
        currentStep: z.number(),
        continueDate: z.string(),
      }),
    )
    .nullish(),
  trackers: z
    .array(
      z.object({
        _tracker: TrackerSchema,
        fulfillmentID: z.string().nullish(),
      }),
    )
    .nullish(),
  trackingAnalytics: z
    .object({
      email: z.array(SesEventSchema),
      page: z.array(
        z.object({
          url: z.string(),
          eventType: z.enum(["ad", "upsell"]),
          image: z.string().nullish(),
          createdAt: z.date(),
        }),
      ),
      adViews: z.array(z.object({})).nullish(),
      adClicks: z.number().nullish(),
      pageViews: z.number().nullish(),
      upsell: z.array(z.object({})).nullish(),
    })
    .nullish(),
  preProtected: z.boolean().nullish(),
  prePackageProtected: z.boolean().nullish(),
  trackingTextsSent: z
    .array(
      z.object({
        sid: z.string(),
        mms: z.boolean(),
        sentAt: z.string(),
      }),
    )
    .nullish(),
  trackingEmailsSent: z
    .array(
      z.object({
        emailId: z.string(),
        status: z.string(),
        sentAt: z.string(),
        s3URL: z.string().optional(),
        trigger: z.string().optional(),
        trackingCode: z.string().optional(),
      }),
    )
    .nullish(),
  trackingBillingStatus: z.enum(["billed", "free"]).nullish(),
  yofiScores: z
    .object({
      return_abuse_score: z
        .object({
          prediction_name: z.string(),
          prediction_value: z.enum(["low", "medium", "high"]),
          justification: z.string(),
          indicators: z.array(
            z.object({
              name: z.string(),
              is_risk: z.boolean(),
            }),
          ),
        })
        .nullish(),
    })
    .nullish(),
  packages: z.array(z.any()).nullish(),
  trackingTimeline: z
    .array(
      z.object({
        status: z.string(),
        statusDetail: z.string(),
        trackerId: z.string(),
        estDeliveryDate: z.string(),
        createdAt: z.string(),
        updatedAt: z.string(),
        carrier: z.string().nullish(),
      }),
    )
    .nullish(),
  forceReplicationAt: z.date().nullish(),
  customerYofiScores: z
    .object({
      return_abuse_score: z
        .object({
          prediction_name: z.string(),
          prediction_value: z.enum(["low", "medium", "high"]),
          justification: z.string(),
          indicators: z.array(
            z.object({
              name: z.string(),
              is_risk: z.boolean(),
            }),
          ),
        })
        .nullish(),
    })
    .nullish(),
  shipmentRates: ShipmentRatesSchema.nullish(),
  parcels: z.array(ParcelSchema).nullish(),
  labels: z
    .array(
      z.object({
        label: LabelSchema,
        rate: RateSchema,
        printed: z.boolean(),
      }),
    )
    .nullish(),
  provider: z.nativeEnum(Provider),
});

export type Order = z.infer<typeof OrderSchema>;
