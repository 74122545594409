import { memo, useMemo } from "react";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";

export interface TimeOfDay {
  hours: number;
  minutes: number;
}

/**
 * This component is currently trash.
 * We are planning on using it however with an immutable interface so that when we give
 * it the love it needs we get the updates everywhere else.
 */
export const RedoTimePicker = memo(function RedoTimePicker({
  value,
  setValue,
}: {
  value: TimeOfDay | null;
  setValue: (value: TimeOfDay | null) => void;
}) {
  function handleTimeChange(timeString: string | null) {
    if (!timeString) {
      setValue(null);
    } else {
      const [hours, minutes] = timeString
        .split(":")
        .map((str) => parseInt(str, 10));
      setValue({ hours, minutes });
    }
  }

  const valueStr = useMemo(() => {
    if (!value) {
      return null;
    }
    return `${value.hours.toString().padStart(2, "0")}:${value.minutes.toString().padStart(2, "0")}`;
  }, [value]);

  return (
    <TimePicker disableClock onChange={handleTimeChange} value={valueStr} />
  );
});
