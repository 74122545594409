import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { OriginAddressSchema } from "./shopify-order";
import type { ExchangeGroup } from "./team";

export const exchangeVariantSchema = z.object({
  id: z.string(),
  sku: z.string().nullish(),
  productId: z.string().nullish(),
  title: z.string(),
  displayName: z.string(),
  price: z.string(),
  image: z.string().nullish(),
  inventoryQuantity: z.number(),
  inventoryTracked: z.boolean(),
  inventoryPolicy: z.enum(["CONTINUE", "DENY"]),
  selectedOptions: z.array(
    z.object({
      name: z.string(),
      value: z.string(),
    }),
  ),
});

export type ExchangeVariant = z.infer<typeof exchangeVariantSchema>;

export const NewExchangeItemDiscountSchema = z.object({
  value: z.number(),
  valueType: z.enum(["PERCENTAGE", "FIXED_AMOUNT"]),
});
export type NewExchangeItemDiscount = z.infer<
  typeof NewExchangeItemDiscountSchema
>;

export const AdvancedExchangeItemSchema = z.object({
  variantId: z.string(),
  title: z.string(),
  variantTitle: z.string(),
  price: z.string(),
  itemValue: z.string().nullish(),
  quantity: z.number(),
  images: z.array(z.string()),
  currencyCode: z.string(),
  attributes: z.record(z.string(), z.any()).nullish(),
  tax: z.string().nullish(),
  appliedDiscount: NewExchangeItemDiscountSchema.nullish(),
});

export type AdvancedExchangeItem = z.infer<typeof AdvancedExchangeItemSchema>;

export const NewExchangeItemSchema = z.object({
  variantId: z.string(),
  quantity: z.number(),
  properties: z.record(z.string(), z.any()).nullish(),
  appliedDiscount: NewExchangeItemDiscountSchema.nullish(),
});

export type NewExchangeItem = z.infer<typeof NewExchangeItemSchema>;

const baseExchangeGroupSchema = z.object({
  type: z.enum([
    "tag:tag",
    "tag:collection",
    "name:collection",
    "collection:collection",
  ]),
  groupName: z.string(),
  priceDifference: z.enum(["ignore", "apply"]),
});

const collectionInfoSchema = z.object({
  id: z.string(),
  name: z.string(),
  kind: z.enum(["smart", "custom"]),
});

export const exchangeGroupSchema = z.union([
  baseExchangeGroupSchema.extend({
    type: z.literal("tag:tag"),
    sourceTags: z.array(z.string()),
    targetTags: z.array(z.string()),
  }),
  baseExchangeGroupSchema.extend({
    type: z.literal("tag:collection"),
    sourceTags: z.array(z.string()),
    targetCollection: collectionInfoSchema,
  }),
  baseExchangeGroupSchema.extend({
    type: z.literal("name:collection"),
    sourceName: z.string(),
    targetCollection: collectionInfoSchema,
  }),
  baseExchangeGroupSchema.extend({
    type: z.literal("collection:collection"),
    sourceCollection: collectionInfoSchema,
    targetCollection: collectionInfoSchema,
  }),
]);

export const _: z.infer<typeof exchangeGroupSchema> = {} as ExchangeGroup;

const ResetSchema = z.object({
  step: z.number(),
  returnFlow: z.any(),
});

export const discountNameSchema = z.object({
  title: z.string(),
  code: z.string(),
});

export const ReturnProductSchema = z.object({
  id: z.string(),
  title: z.string(),
  variantTitle: z.string(),
  variantId: z.string(),
  reset: ResetSchema.nullish(),
  image: z.string(),
  price: z.string(),
  tax: z.string(),
  currency: z.string(),
  productTags: z.array(z.string()),
  returnValue: z.string(),
  sku: z.string(),
  deliveredOn: z.date(),
  fulfilledOn: z.date(),
  grams: z.number(),
  lastFulfillmentUpdateOn: z.date(),
  shipmentLocationId: z.string().nullish(),
  productId: z.string(),
  collections: z.array(z.string()),
  productDiscounts: z.array(discountNameSchema),
  productProperties: z.record(z.string(), z.any().nullable()).nullish(),
  productType: z.string(),
  productVariantMetafields: z.record(z.string(), z.any()).nullish(),
  variantData: z.object({
    productTitle: z.string(),
    tags: z.array(z.string()),
    options: z.array(
      z.object({
        position: z.number(),
        name: z.string(),
        values: z.array(z.string()),
      }),
    ),
    variants: z.array(exchangeVariantSchema),
  }),
  fulfillmentOrigin: OriginAddressSchema.nullish(),
  isUnbundled: z.boolean().nullish(),
  isExpandedPrimaryProduct: z.boolean().nullish(),
  originalPrice: z.string().nullish(),
  taxRate: z.number().nullish(),
  quantity: z.number().nullish(),
});
export type ReturnProduct = z.infer<typeof ReturnProductSchema>;

export const ReturnableItemSchema = z.object({
  id: z.string(),
  primaryProductId: z.string(),
  value: z.string().nullish(),
  products: z.array(ReturnProductSchema),
  isExpanded: z.boolean(),
  rules: z.any().nullish(), // todo
  orderId: z.string(),
  selected: z.optional(z.boolean().nullish()),
});
export type ReturnableItem = z.infer<typeof ReturnableItemSchema>;

const VariantExchangeItemSchema = z.object({
  variantId: z.string().nullish(),
  productId: z.string().nullish(),
  preserveProperties: z.boolean(),
  priceDifference: z.enum(["ignore", "apply"]).nullish(),
  title: z.string(),
  image: z.string().nullish(),
  productTitle: z.string().nullish(),
});
export type VariantExchangeItem = z.infer<typeof VariantExchangeItemSchema>;

export const PendingReturnItemSchema = z.object({
  id: zExt.objectId(),
  returnableItemId: z.string(),
  expandedProductVariantExchanges: z.array(VariantExchangeItemSchema).nullish(),
  flow: z.any(),
  flowPath: z.array(z.number()).readonly(),
  multipleChoiceAnswers: z
    .array(
      z.object({
        step: z.number(),
        answer: z.number(),
      }),
    )
    .readonly(),
  inputAnswers: z
    .array(
      z.object({
        type: z.enum(["images", "text_area", "videos", "address"]),
        value: z.union([z.string(), z.array(z.string())]),
        message: z.string(),
      }),
    )
    .readonly()
    .nullish(),

  exchangeGroup: exchangeGroupSchema.nullish(),
  variantExchangeItem: VariantExchangeItemSchema.nullish(),
  advancedExchangeItems: z.array(NewExchangeItemSchema).nullish(),
  manualReview: z.boolean(),
  manualReviewReason: z.string().nullish(),
  flagged: z.boolean(),
  flaggedReason: z.string().nullish(),
  returnReason: z.string().nullish(),
  returnOptions: z.array(z.any()),
  flatRateShippingFee: z.string().nullish(),
  selectedReturnOption: z.any().nullish(),
  quantity: z.number().nullish(),
});
export type PendingReturnItem = z.infer<typeof PendingReturnItemSchema>;
