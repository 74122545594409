import {
  CartLinesAddMutation,
  CartLinesAddMutationVariables,
} from "./shopify-storefront.graphql";
import { ShopifyStorefrontClient } from "./storefront-client";

const cartLinesAddMutation = /* GraphQL */ `
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        id
      }
    }
  }
`;

export async function addLineItemToCart(
  client: ShopifyStorefrontClient,
  cartId: CartLinesAddMutationVariables["cartId"],
  lines: CartLinesAddMutationVariables["lines"],
): Promise<CartLinesAddMutation> {
  const { data, errors } = await client.request<
    CartLinesAddMutation,
    CartLinesAddMutationVariables
  >(cartLinesAddMutation, {
    cartId,
    lines,
  });

  if (errors) {
    throw errors;
  }

  if (!data?.cartLinesAdd?.cart) {
    throw new Error("Error adding line item to cart");
  }

  return data;
}
