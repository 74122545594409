import {
  CreateCartMutation,
  CreateCartMutationVariables,
  SetBuyerIdentityMutation,
  SetBuyerIdentityMutationVariables,
} from "./shopify-storefront.graphql";
import { ShopifyStorefrontClient } from "./storefront-client";

const createCartMutation = /* GraphQL */ `
  mutation CreateCart {
    cartCreate {
      cart {
        id
        checkoutUrl
      }
    }
  }
`;

const setBuyerIdentityMutation = /* GraphQL */ `
  mutation SetBuyerIdentity(
    $cartId: ID!
    $buyerIdentity: CartBuyerIdentityInput!
  ) {
    cartBuyerIdentityUpdate(cartId: $cartId, buyerIdentity: $buyerIdentity) {
      userErrors {
        field
        message
      }
    }
  }
`;

export async function createCart(
  client: ShopifyStorefrontClient,
  buyerIdentity: SetBuyerIdentityMutationVariables["buyerIdentity"],
): Promise<string> {
  const { data: cartCreateData, errors: cartCreateErrors } =
    await client.request<CreateCartMutation, CreateCartMutationVariables>(
      createCartMutation,
      {},
    );

  if (cartCreateErrors) {
    throw cartCreateErrors;
  }

  if (!cartCreateData?.cartCreate?.cart) {
    throw new Error("Cart not created");
  }
  const cartID = cartCreateData.cartCreate.cart.id;

  const { data, errors } = await client.request<
    SetBuyerIdentityMutation,
    SetBuyerIdentityMutationVariables
  >(setBuyerIdentityMutation, {
    cartId: cartID,
    buyerIdentity,
  });
  const userErrors = data?.cartBuyerIdentityUpdate?.userErrors;
  if (userErrors && userErrors.length > 0) {
    console.error("Error setting buyer identity", errors);
  }
  return cartID;
}

export async function createCartSimple(
  client: ShopifyStorefrontClient,
): Promise<{
  id: string;
  checkoutUrl: string;
}> {
  const { data, errors } = await client.request<
    CreateCartMutation,
    CreateCartMutationVariables
  >(createCartMutation, {});

  if (errors) {
    throw errors;
  }

  if (!data?.cartCreate?.cart) {
    throw new Error("Cart not created");
  }
  const cart = data.cartCreate.cart;

  return cart;
}
