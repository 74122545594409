import { z } from "zod";
import { customerProductListBodySchema } from "./customer-product-list-schema";

export const getSharedListRpcSchema = {
  getSharedList: {
    input: z.object({
      listId: z.string(),
      token: z.string(),
    }),
    output: z.object({
      sharedList: customerProductListBodySchema.optional(),
    }),
  },
};
