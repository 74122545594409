export interface Channel {
  id: string;
  sourceNames: string[];
  displayName: string;
}

// This is a mapping of all commonly used sales channels by our merchants,
// I expect that we will add to this as we continue to grow.
// The ID will be what we store on our side for selecting desired sales channels and the sourceNames list for each is
// what can appear in the source_name field in the shopify object for an order.
// Some source names have an id/order number associated with them, when placing them in sourceNames leave out the order number and be
// sure to use a starts with method for match checking.
// i.e. some are "Amazon.com ###-#######-#######" so we only use "Amazon.com"
export const SalesChannels: Channel[] = [
  {
    id: "onlineStore",
    sourceNames: ["web"],
    displayName: "Online Store",
  },
  {
    id: "tiktok",
    sourceNames: ["4555605", "tiktok", "TikTok Shop"],
    displayName: "TikTok",
  },
  {
    id: "facebook",
    sourceNames: ["2329312"],
    displayName: "Facebook or Instagram",
  },
  {
    id: "commentSold",
    sourceNames: ["CommentSold"],
    displayName: "CommentSold",
  },
  {
    id: "draftOrder",
    sourceNames: ["shopify_draft_order"],
    displayName: "Shopify Draft Order",
  },
  {
    id: "amazon",
    sourceNames: ["205641", "Amazon", "amazon", "amazon-us", "Amazon.com"],
    displayName: "Amazon",
  },
  {
    id: "tts",
    sourceNames: ["TTS"],
    displayName: "TTS",
  },
  {
    id: "etsy",
    sourceNames: ["Etsy", "1782255"],
    displayName: "Etsy",
  },
  {
    id: "iphone",
    sourceNames: ["iphone"],
    displayName: "iphone",
  },
  {
    id: "express",
    sourceNames: ["EXPRESS"],
    displayName: "Express",
  },
  {
    id: "walmart",
    sourceNames: ["walmart", "Walmart.com", "1456995", "Walmart"],
    displayName: "Walmart",
  },
  {
    id: "macys",
    sourceNames: ["Macy's Inc.", "Macys"],
    displayName: "Macys",
  },
  {
    id: "faire",
    sourceNames: ["Faire"],
    displayName: "Faire",
  },
  {
    id: "anthropologie",
    sourceNames: ["Anthropologie"],
    displayName: "Anthropologie",
  },
  {
    id: "bloomingdales",
    sourceNames: ["Bloomingdale's"],
    displayName: "Bloomingdale's",
  },
  {
    id: "fordays",
    sourceNames: ["For Days"],
    displayName: "For Days",
  },
  {
    id: "loop",
    displayName: "Loop Returns & Exchanges",
    sourceNames: ["1662707"],
  },
  {
    id: "madewell",
    sourceNames: ["Madewell"],
    displayName: "Madewell",
  },
  {
    id: "garmentory",
    sourceNames: ["Garmentory"],
    displayName: "Garmentory",
  },
  {
    id: "ebay",
    sourceNames: ["ebay"],
    displayName: "eBay",
  },
  {
    id: "diaAndCo",
    sourceNames: ["Dia & Co"],
    displayName: "Dia & Co",
  },
  {
    id: "pos",
    sourceNames: ["pos"],
    displayName: "Point of sale",
  },
  {
    id: "tapcart",
    displayName: "Tapcart",
    sourceNames: ["1520611"],
  },
  {
    id: "target",
    sourceNames: ["Target"],
    displayName: "Target",
  },
  {
    id: "belk",
    sourceNames: ["Belk"],
    displayName: "Belk",
  },
  {
    id: "fullCircleSales",
    sourceNames: ["4137415"],
    displayName: "Full Circle Sales",
  },
  {
    id: "shopifyCollective",
    sourceNames: ["shopify-collective"],
    displayName: "Shopify Collective",
  },
  {
    id: "shopApp",
    sourceNames: ["3890849"],
    displayName: "Shop App",
  },
  {
    id: "aftershipFeed",
    sourceNames: ["AfterShip Feed"],
    displayName: "AfterShip Feed ",
  },
];
