// extracted by mini-css-extract-plugin
export var accent = "_redotech-redo-web-src-text-input-accent";
export var add = "_redotech-redo-web-src-text-input-add";
export var addIcon = "_redotech-redo-web-src-text-input-add-icon";
export var deleteIcon = "_redotech-redo-web-src-text-input-delete-icon";
export var disabled = "_redotech-redo-web-src-text-input-disabled";
export var ellipsis = "_redotech-redo-web-src-text-input-ellipsis";
export var error = "_redotech-redo-web-src-text-input-error";
export var form = "_redotech-redo-web-src-text-input-form";
export var hideFocus = "_redotech-redo-web-src-text-input-hide-focus";
export var icon = "_redotech-redo-web-src-text-input-icon";
export var input = "_redotech-redo-web-src-text-input-input";
export var listItem = "_redotech-redo-web-src-text-input-list-item";
export var mediumHeight = "_redotech-redo-web-src-text-input-mediumHeight";
export var noBorder = "_redotech-redo-web-src-text-input-no-border";
export var shortHeight = "_redotech-redo-web-src-text-input-shortHeight";
export var tallHeight = "_redotech-redo-web-src-text-input-tallHeight";
export var textInput = "_redotech-redo-web-src-text-input-text-input";
export var vertical = "_redotech-redo-web-src-text-input-vertical";
export var widget = "_redotech-redo-web-src-text-input-widget";