import { useLoad } from "@redotech/react-util/load";
import { CustomerPortalClient } from "@redotech/redo-customer-portal-server/rpc/schema";
import { createContext, useContext } from "react";
import { RpcClientContext } from "./RpcClientContext";
import { SettingsContext } from "./settings";

export const FaqContext = createContext<
  Awaited<ReturnType<CustomerPortalClient["getFaq"]>> | undefined
>(undefined);

export function FaqContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const client = useContext(RpcClientContext);
  const settings = useContext(SettingsContext);

  const faqLoad = useLoad(async () => {
    if (!client || !settings?.support) {
      return null;
    }

    const response = await client.getFaq({});

    return response;
  }, [client, settings]);

  return (
    <FaqContext.Provider value={faqLoad.value ?? undefined}>
      {children}
    </FaqContext.Provider>
  );
}
