import { RouteObject } from "react-router-dom";

export const csatRoutes: RouteObject[] = [
  {
    lazy: async () => {
      const { Page } = await import("./page");
      return { Component: Page };
    },
    children: [
      {
        path: ":id",
        lazy: async () => {
          const { Survey } = await import("./survey");
          return { element: <Survey /> };
        },
      },
    ],
  },
];
