import { Currency } from "@redotech/money/currencies";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { Provider } from "./order";

import {
  AdvancedExchangeItemSchema,
  exchangeVariantSchema,
  NewExchangeItemSchema,
  PendingReturnItemSchema,
  ReturnableItemSchema,
} from "./draft-return-items";
import { Payer, PickupLocation, ProvisionType } from "./return-flow";

export enum CompensationMethodType {
  EXCHANGE = "exchange",
  STORE_CREDIT = "storeCredit",
  REFUND = "refund",
  REPAIR = "repair",
}

export const COMPENSATION_TYPES = Object.values(CompensationMethodType);

export const CompensationMethodSchema = z.object({
  type: z.nativeEnum(CompensationMethodType),
  // keys are pendingItemIds
  itemValues: z.record(
    z.object({
      strategy: z.enum(["refund", "storeCredit", "repair"]),
      value: zExt.big(),
      exchangeCredit: zExt.big(),
      isVariantExchange: z.boolean(),
      tax: zExt.big(),
    }),
  ),
  totalReturnableValue: zExt.big(),
  // keys are pendingItemIds
  adjustments: z.record(zExt.big()),
  shippingRequired: z.record(z.boolean()),
  manualReview: z.boolean(),
  manualReviewReason: z.string().nullish(),
  flagged: z.boolean(),
  flaggedReason: z.string().nullish(),
  payer: z.nativeEnum(Payer),
  returnMethodRequired: z.boolean(),
  exchangeShippingName: z.string().nullish(),
  chargeNewOrderShipping: z.boolean(),
  discountCodeName: z
    .object({
      prefix: z.string().nullish(),
      suffix: z.string().nullish(),
    })
    .nullish(),
  advancedExchangeAvailable: z.boolean(),
});

export type CompensationMethod = z.infer<typeof CompensationMethodSchema>;
export type CompensationType = CompensationMethod["type"];

export const InteractiveMapLocationSchema = z.object({
  name: z.string(),
  address: z.object({
    address1: z.string(),
    address2: z.string().optional(),
    city: z.string(),
    state: z.string(),
    zip: z.string(),
    country: z.string(),
  }),
  geo: z.object({
    latitude: z.number(),
    longitude: z.number(),
  }),
  distance: z.string().optional(),
});

export type InteractiveMapLocation = z.infer<
  typeof InteractiveMapLocationSchema
>;

// Enum definitions
export const ExchangeTypeContext = z.enum(["VARIANT", "ADVANCED"]);
export const Provision = z.nativeEnum(ProvisionType);
export const PickupLocationEnum = z.nativeEnum(PickupLocation);
export const AdjustmentType = z.enum(["percentage", "amount"]);
export const ReturnOrClaimType = z.enum(["return", "claim"]);
export const ReturnRequestStatus = z.enum(["PENDING", "REJECTED"]);

// Schema definitions
export const AddressSchema = z.object({
  name: z.string(),
  email: z.string(),
  street1: z.string(),
  street2: z.string().nullish(),
  state: z.string().nullish(),
  city: z.string(),
  country: z.string(),
  zip: z.string(),
  phone: z.string().nullish(),
  returnerName: z.string().nullish(),
  returnerEmail: z.string().nullish(),
  _id: z.string().nullish(),
});
export type Address = z.infer<typeof AddressSchema>;

const PickupDataSchema = z.object({
  email: z.string().nullish(),
  phone: z.string().nullish(),
  pickupDate: z.string(),
  pickupLocation: z.object({
    packageLocation: PickupLocationEnum,
    specialInstructions: z.string().nullish(),
  }),
  textReminder: z.boolean(),
});
export type PickupData = z.infer<typeof PickupDataSchema>;

export enum ShipmentMethodName {
  PICKUP = "PICKUP",
  IN_STORE = "IN_STORE",
  SETTLEMENT = "SETTLEMENT",
  SELF_SHIP = "SELF_SHIP",
  HAPPY_RETURNS = "HAPPY_RETURNS",
}
export const ShipmentMethodSchema = z.union([
  z.object({
    method: z.literal(ShipmentMethodName.PICKUP),
    pickupData: PickupDataSchema,
  }),
  z.object({
    method: z.literal(ShipmentMethodName.IN_STORE),
  }),
  z.object({
    method: z.literal(ShipmentMethodName.SETTLEMENT),
  }),
  z.object({
    method: z.literal(ShipmentMethodName.HAPPY_RETURNS),
  }),
  z.object({
    method: z.literal(ShipmentMethodName.SELF_SHIP),
  }),
]);
export type ShipmentMethod = z.infer<typeof ShipmentMethodSchema>;

export const SettlementSchema = z.object({
  accepted: z.boolean(),
  refund: z.number(),
});

export const discountNameSchema = z.object({
  title: z.string(),
  code: z.string(),
});

const ShipmentAddressSchema = z.object({
  name: z.string().nullish(),
  street1: z.string().nullish(),
  street2: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  zip: z.string().nullish(),
  country: z.string().nullish(),
  country_name: z.string().nullish(),
  phone: z.string().nullish(),
  id: z.string().nullish(),
  externalLocationId: z.string().nullish(),
  _id: zExt.objectId().nullish(),
});
export type ShipmentAddress = z.infer<typeof ShipmentAddressSchema>;

export const HappyReturnsLocationSchema = z.object({
  name: z.string(),
  address: z.object({
    address: z.string(),
    city: z.string(),
    state: z.string(),
    zipcode: z.string(),
  }),
  distance: z.number(),
  lat: z.number(),
  lng: z.number(),
});

export type HappyReturnsLocation = z.infer<typeof HappyReturnsLocationSchema>;

export const HappyReturnsDetailsSchema = z.object({
  price: zExt.big(),
  locations: z.array(HappyReturnsLocationSchema),
  zipCode: z.string(),
  retailerId: z.string(),
});

export type HappyReturnsDetails = z.infer<typeof HappyReturnsDetailsSchema>;

export const DraftReturnShipmentSchema = z.object({
  carrier: z.string(),
  fee: z.string(),
  flatRateFee: z.string().nullish(),
  pickupFee: z.string().nullish(),
  pickupIneligibility: z.string().nullish(),
  address: ShipmentAddressSchema,
  pendingReturnItems: z.array(z.string()),
  happyReturnsDetails: HappyReturnsDetailsSchema.nullish(),
});

export type DraftReturnShipment = z.infer<typeof DraftReturnShipmentSchema>;

export enum ReturnTypeName {
  CLAIM = "claim",
  RETURN = "return",
  WARRANTY = "warranty",
  MANAGED_CLAIM = "managed_claim",
}

const ReturnTotalCalculationsSchema = z.object({
  pickupAccept: z.boolean(),
  pickupFee: z.number().nullish(),
  inStoreAccept: z.boolean(),
  settlementAccept: z.boolean(),
  settlementRefund: z.number(),
  shippingFee: z.number(),
  totalShippingFee: z.number(),
  labelDeducted: z.boolean(),
  returnValue: z.number(),
  totalTaxes: z.number(),
  newOrderValue: z.number(),
  totalNewOrderTaxes: z.number(),
  totalAdjustmentValue: z.number(),
  totalReturnValue: z.number(),
  totalStoreCredit: z.number(),
  totalRefund: z.number(),
  totalRepairFee: z.number(),
  hasGreenReturnItems: z.boolean(),
  hasNonGreenReturnItems: z.boolean(),
  showNewItems: z.boolean(),
  paymentOwed: z.number(),
  skipPayment: z.boolean(),
});
export type ReturnTotalCalculations = z.infer<
  typeof ReturnTotalCalculationsSchema
>;

export const ReturnTotalCalculationsOutputSchema = z.object({
  instant: ReturnTotalCalculationsSchema,
  processed: ReturnTotalCalculationsSchema,
});
export type ReturnTotalCalculationsOutput = z.infer<
  typeof ReturnTotalCalculationsOutputSchema
>;

const OrderSchema = z.object({
  id: zExt.objectId(),
  warrantyId: zExt.objectId().nullish(),
  name: z.string(),
  tags: z.array(z.string()),
  orderDate: z.date(),
  totalPrice: z.string(),
  currency: z.string(),
  protected: z.boolean(),
  packageProtected: z.boolean(),
  finalSaleReturnsProtected: z.boolean(),
  productsCoveredByFinalSale: z.array(z.string()),
  paymentMethods: z.array(z.string()),
  salesChannel: z.string(),
  exchangeCount: z.number(),
  discounts: z.array(discountNameSchema),
  platform: z.nativeEnum(Provider),
  coverage: z.object({
    exchange: z.boolean(),
    storeCredit: z.boolean(),
    refund: z.boolean(),
    repair: z.boolean(),
  }),
});
export type Order = z.infer<typeof OrderSchema>;

export const NewOrderDataSchema = z.object({
  items: z.array(AdvancedExchangeItemSchema),
  totalPrice: z.string(), // without tax
  totalTax: z.string(),
  discountCodes: z.array(z.string()),
  discountAllocations: z.array(
    z.object({
      discountedAmount: z.object({
        amount: z.string(),
        currencyCode: z.string(),
      }),
    }),
  ),
});
export type NewOrderData = z.infer<typeof NewOrderDataSchema>;

// Main ReturnRequest schema
export const DraftReturnSchema = z.object({
  _id: zExt.objectId(),
  teamId: zExt.objectId(),
  treatmentHash: z.string(),
  flow: z.any(),
  finalizeFlow: z.any(),
  returnType: z.nativeEnum(ReturnTypeName),
  orders: z.array(OrderSchema), // I wish this was a Record<string, OrderSchema>. I wish mongoose supported this in a way that wasn't a map. plz lmk if you know how to do this
  customer: z.object({
    id: z.string().nullish(),
    shippingAddress: AddressSchema.nullish(),
    name: z.string(),
    tags: z.array(z.string()),
    numCustomerClaims: z.number(),
    numCustomerReturns: z.number(),
    numOrders: z.number(),
    returnRate: z.number(),
    newOrderAddress: AddressSchema.nullish(),
  }),
  newOrderItems: z.array(NewExchangeItemSchema),
  newOrderData: NewOrderDataSchema.nullish(),
  returnableItems: z.array(ReturnableItemSchema),
  pendingReturnItems: z.array(PendingReturnItemSchema),
  compensationMethods: z.array(CompensationMethodSchema),
  selectedMethod: z.nativeEnum(CompensationMethodType).nullish(),
  shipmentMethod: ShipmentMethodSchema.nullish(),
  shipments: z.array(DraftReturnShipmentSchema).nullish(),
  submitted: z.boolean().nullish(),
  provisionType: Provision.nullish(),
  currency: z.nativeEnum(Currency).nullish(),
  presentmentCurrency: z.nativeEnum(Currency).nullish(),
  pickupVariant: z.string().nullish(),
});

export const returnItemDataSchema = z.object({
  productId: z.string(),
  variantId: z.string(),
  collections: z.array(z.string()),
  deliveredOn: zExt.instant(),
  fulfilledOn: zExt.instant(),
  lastFulfillmentUpdateOn: zExt.instant(),
  productDiscounts: z.array(discountNameSchema),
  productProperties: z.record(z.string()),
  productTags: z.array(z.string()),
  productType: z.string(),
  productVariantMetafields: z.record(z.string()),
  sku: z.string(),
  variantData: z.object({
    productTitle: z.string(),
    tags: z.array(z.string()),
    options: z.array(
      z.object({
        position: z.number(),
        name: z.string(),
        values: z.array(z.string()),
      }),
    ),
    variants: z.array(exchangeVariantSchema),
  }),
});

export type ReturnItemData = z.infer<typeof returnItemDataSchema>;
export type ReturnRequest = z.infer<typeof DraftReturnSchema>;
export type DraftReturn = z.infer<typeof DraftReturnSchema>;
