import { Currency } from "@redotech/money/currencies";
import { createContext } from "react";

export const CURRENCY_FORMAT = (currencyCode?: string | null) => {
  if (!currencyCode) {
    currencyCode = Currency.USD;
  }
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currencyCode,
    currencyDisplay: "narrowSymbol",
  });
};

export interface CurrencyEnv {
  currency: Currency;
  formatCurrency(amount: number | string): string;
  setCurrency(currency: Currency): void;
  formatCurrencyFromUSD(amount: number | string): string;
}

export const default_format_Currency = (amount: string | number) =>
  CURRENCY_FORMAT(DEFAULT_CURRENCY).format(
    typeof amount === "string" ? parseFloat(amount) : amount,
  );

export const DEFAULT_CURRENCY = Currency.USD;

export const CurrencyContext = createContext<CurrencyEnv>({
  currency: DEFAULT_CURRENCY,
  formatCurrency: (amount) =>
    CURRENCY_FORMAT(DEFAULT_CURRENCY).format(
      typeof amount === "string" ? parseFloat(amount) : amount,
    ),
  setCurrency() {},
  formatCurrencyFromUSD: (amount) =>
    CURRENCY_FORMAT(DEFAULT_CURRENCY).format(
      typeof amount === "string" ? parseFloat(amount) : amount,
    ),
});
