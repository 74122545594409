// extracted by mini-css-extract-plugin
export var alignBaseline = "_redotech-redo-web-src-flex-align-baseline";
export var alignCenter = "_redotech-redo-web-src-flex-align-center";
export var alignFlexEnd = "_redotech-redo-web-src-flex-align-flex-end";
export var alignFlexStart = "_redotech-redo-web-src-flex-align-flex-start";
export var alignSelfBaseline = "_redotech-redo-web-src-flex-align-self-baseline";
export var alignSelfCenter = "_redotech-redo-web-src-flex-align-self-center";
export var alignSelfFlexEnd = "_redotech-redo-web-src-flex-align-self-flex-end";
export var alignSelfFlexStart = "_redotech-redo-web-src-flex-align-self-flex-start";
export var alignSelfStretch = "_redotech-redo-web-src-flex-align-self-stretch";
export var alignStretch = "_redotech-redo-web-src-flex-align-stretch";
export var column = "_redotech-redo-web-src-flex-column";
export var flex = "_redotech-redo-web-src-flex-flex";
export var justifyCenter = "_redotech-redo-web-src-flex-justify-center";
export var justifyFlexEnd = "_redotech-redo-web-src-flex-justify-flex-end";
export var justifyFlexStart = "_redotech-redo-web-src-flex-justify-flex-start";
export var justifySpaceAround = "_redotech-redo-web-src-flex-justify-space-around";
export var justifySpaceBetween = "_redotech-redo-web-src-flex-justify-space-between";
export var justifySpaceEvenly = "_redotech-redo-web-src-flex-justify-space-evenly";
export var reverse = "_redotech-redo-web-src-flex-reverse";
export var row = "_redotech-redo-web-src-flex-row";
export var wrapNowrap = "_redotech-redo-web-src-flex-wrap-nowrap";
export var wrapWrap = "_redotech-redo-web-src-flex-wrap-wrap";
export var wrapWrapReverse = "_redotech-redo-web-src-flex-wrap-wrap-reverse";