import { createContext, useState } from "react";
import { DiscountCodeNameResults } from "../order/return-flow/question-utils";

const DiscountNameContext = createContext({
  discountName: {
    prefix: undefined,
    suffix: undefined,
  } as DiscountCodeNameResults,
  setDiscountName: (discountName: DiscountCodeNameResults) => {},
});

const DiscountNameContextProvider = ({ children }) => {
  const [discountName, setDiscountName] = useState<DiscountCodeNameResults>({
    prefix: undefined,
    suffix: undefined,
  });

  return (
    <DiscountNameContext.Provider value={{ discountName, setDiscountName }}>
      {children}
    </DiscountNameContext.Provider>
  );
};

export { DiscountNameContext, DiscountNameContextProvider };
